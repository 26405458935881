// 动物治疗计划页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">动物治疗计划</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="planDate" class="form-label">日期</label>
                            <input type="datetime-local" class="form-control" id="planDate">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label class="form-label">治疗规划情况</label>
                            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
                                <label class="btn btn-outline-primary" for="btnradio1">已计划</label>
                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                                <label class="btn btn-outline-primary" for="btnradio2">未计划</label>
                            </div>
                        </div>
                        <div class="col d-flex justify-content-end align-items-end mb-3">
                            <button type="button" class="btn btn-outline-info">
                                <i class="bi bi-printer"></i> 打印治疗计划
                            </button>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-md-12">
                            <h5 class="text-info">自动治疗计划</h5>
                            <p>预处理计划 治疗计划已通过。</p>
                        </div>
                        <div class="col-md-12">
                            <h5 class="text-info">一般治疗板</h5>
                        </div>
                    </div>
                </form>
                <tableList class="mb-2"></tableList>
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>