// 产品代码管理页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">产品代码管理</div>
            <div class="card-body">
                <tableList class="mb-2"></tableList>
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>