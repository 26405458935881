<!-- 带导航和底部栏的母版页面 -->
<style scoped>
    
</style>
<template>
    <div class="d-flex h-100 container-fluid py-2">
        <!-- <navbar :user="user"></navbar>
        <div class="container flex-grow-1">
            <router-view :user="user" :pagesize="pagesize"/>
        </div> -->
        <navbar :user="user"></navbar>
        <div class="flex-grow-1 ps-3 d-flex flex-column col">

            <!-- 工具栏 -->
            <headers class="shadow mb-3" :user="user"></headers>

            <!-- 二级菜单 -->
            <div v-show="secondMenuList.length>0" class="mb-3 text-dark">
                <span v-for="item in secondMenuList" :key="item.name" :class="{'border-bottom border-3 border-primary text-primary':item.name==currSecondMenu.name}" class="pb-1 me-4" @click="$root.linkTo(item.name)" role="button">{{item.meta["title"]}}</span>
            </div>

            <!-- 页面内容 -->
            <div class="flex-grow-1">
                <router-view :user="user" :pagesize="pagesize"/>
            </div>

        </div>
    </div>
    
</template>
<script>
import navbar from "@/components/public/navbar.vue";
import foot from "@/components/public/footer.vue";
import headers from "@/components/public/headers.vue";
import { ROUTE_TYPE } from "@/assets/js/config";
export default {
    components:{
        navbar,
        headers,
        foot
    },
    data() {
		return {
            /**
             * 用户对象
             */
			user: {
				name:"",//用户名
				guid:"",
			},
            pagesize:20,//每页显示数据条数
            currSecondMenu:null,//当前选中的二级菜单
            secondMenuList:[],//二级菜单列表
            routerList:this.$router.getRoutes(),//路由列表
		};
	},
    created(){
        this.user=this.$route.params;
    },
    mounted() {
        this.setChildMenuList();
    },
    methods: {
        /**
         * 设置子菜单列表
         */
        setChildMenuList(){
            let router = this.routerList.find(v=>v.name==this.$route.name);//路由列表中找当前路由页面的对象
            //迭代找到当前路由页面的二级菜单
            while (router.meta["parent"]&&router.meta["type"]!=ROUTE_TYPE.secondMenu) {//当前路由页面存在父菜单(即不为一级菜单)，并且不为二级菜单
                router=this.routerList.find(v=>v.name==router.meta["parent"]);
            }
            //设置
            this.currSecondMenu=router;
            this.secondMenuList=this.routerList.filter(v=>v.meta["parent"]==router.meta["parent"]&&v.meta["type"]==ROUTE_TYPE.secondMenu);
        },
        
    },
    watch: {
        $route(to) {//监听路由变化
            this.setChildMenuList();
        },
    },
}
</script>