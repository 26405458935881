<!-- 物料管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100">
        <div class="card h-100">
            <div class="card-header">
                    仓库管理
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <ul class="nav nav-tabs mb-2">
                        <li class="nav-item">
                            <span :class="{'active':currTab==tabName.ware}" class="nav-link" @click="currTab=tabName.ware" role="button">药品/物料</span>
                        </li>
                        <li class="nav-item">
                            <span :class="{'active':currTab==tabName.stock}" class="nav-link" @click="currTab=tabName.stock" role="button">采购</span>
                        </li>
                    </ul>
                    <div :class="[currTab==tabName.ware?'d-flex':'d-none']" class="flex-grow-1">
                        <div class="flex-fill pe-2">
                            <div class="card h-100">
                                <div class="card-header bg-primary text-light">
                                    <div class="d-flex justify-content-between">
                                        <span>药品/物料管理</span>
                                        <div @click="$root.linkTo('wareinfo')" role="button">
                                            <span>新增药品/物料</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>编号</th>
                                                        <th>物料名称</th>
                                                        <th>品牌名</th>
                                                        <th>价格</th>
                                                        <th>库存</th>
                                                        <th>提交采购</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in wareList" :key="item">
                                                        <td>{{ item.ProductID }}</td>
                                                        <td>
                                                            <span class="btn-link" @click="$root.linkTo('wareinfo',{productId:item.ProductID})" role="button"> {{ item.ProductName }}</span>
                                                        </td>
                                                        <td>{{ item.BrandName }}</td>
                                                        <td>{{ item.Price }}</td>
                                                        <td>{{ item.Stock }}</td>
                                                        <td>
                                                            <span class="btn-link" @click="$root.linkTo('stock',{productId:item.ProductID,stockId:0})" role="button">
                                                                <i class="bi bi-box-arrow-in-right"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="[currTab==tabName.stock?'d-flex':'d-none']" class="flex-grow-1">
                        <div class="flex-fill pe-2">
                            <div class="card h-100">
                                <div class="card-header bg-primary text-light">
                                    <div class="d-flex justify-content-between">
                                        <span>采购管理</span>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>编号</th>
                                                        <th>产品名称</th>
                                                        <th>品牌名</th>
                                                        <th>价格</th>
                                                        <th>入库数量</th>
                                                        <th>状态</th>
                                                        <th>校验人</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in stockList" :key="item">
                                                        <td>{{ item.ID }}</td>
                                                        <td>
                                                            <span class="btn-link" @click="$root.linkTo('stock',{productId:item.ProductID,stockId:item.ID})" role="button"> {{ item.ProductName }}</span>
                                                        </td>
                                                        <td>{{ item.BrandName }}</td>
                                                        <td>{{ item.UnitPrice }}</td>
                                                        <td>{{ item.PutinQuantity }}</td>
                                                        <td>{{ item.Status==0?"待入库":"已入库" }}</td>
                                                        <td>{{ item.Verifier }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="userName" class="form-label">物料名称</label>
                                <input class="form-control" id="userName" placeholder="物料名称">
                            </div>
                        </div>
                    </form> -->
                    
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import pagination from "@/components/public/pagination.vue";
import { LAYER_TYPE,STATE_TYPE,DATA_TYPE,OPERATE_TYPE,STATE_NAME } from "@/assets/js/config";
export default {
    components:{
        pagination
    },
    data() {
        return {
            operateType:OPERATE_TYPE,//操作类型
            stateType:STATE_TYPE,//状态类型
            state_name:STATE_NAME,//状态类型名称配置
            tabName:{
                ware:1,//物料
                stock:2,//出入库
            },//选项卡名称
            currTab: "",//当前选中的选项卡
            wareList:[],//物料列表
            Ware:{//物料字段

            },
            stockList:[],//入库列表
            Stock:{//入库字段

            }

        };
    },
    created() {
        this.currTab=this.tabName.ware;
    },
    mounted(){
        if(this.$route.params.result){
            this.showLayer({str:"保存成功"});
        }
        this.getWareList();
        this.getStockList();
    },
    methods:{
        getWareList(){
            //获取物料列表
            this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                pagesize:9999,
                isreserve:0,
                isstock:1,
            },(data)=>{
                console.log(data.list)
                this.wareList=data.list;
            })
        },
        getStockList(){
            //获取出入库列表
            this.$root.requestData(DATA_TYPE.getstock,{
                pageindex:1,
                pagesize:9999
            },(data)=>{
                this.stockList=data.list;
            })
        },
    }
}
</script>