/**
 * 获取日期
 */
function getDate(){
    let date=new Date();
    let month=date.getMonth()+1;
    let day=date.getDate();
    let hour=date.getHours();
    let minutes=date.getMinutes();
    let seconds=date.getSeconds();
    return date.getFullYear()+"/"+(month<10?"0"+month:month)+"/"+(day<10?"0"+day:day)+" "+
        (hour<10?"0"+hour:hour)+":"+(minutes<10?"0"+minutes:minutes)+":"+(seconds<10?"0"+seconds:seconds);
}
/**
 * 生成GUID
 */
function guid() {
    var str = '';
    var i = 32;
    while (i--) {
        str += Math.floor(Math.random() * 16.0).toString(16);
    }
    return str;
}
/**
 * 格式化日期
 */
function formatDate(date=new Date()) {
    var a = new Date(date);
    var y = a.getFullYear();
    var m = a.getMonth() + 1;
    var d = a.getDate();
    var h = a.getHours();
    var mm = a.getMinutes();
    var s = a.getSeconds();

    if (m < 10) m = '0' + m;
    if (d < 10) d = '0' + d;
    if (h < 10) h = '0' + h;
    if (mm < 10) mm = '0' + mm;
    if (s < 10) s = '0' + s;
    return y + '-' + m + '-' + d + " " + h + ":" + mm + ":" + s;
}
export{
    getDate,guid,formatDate
}
