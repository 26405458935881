<!-- 医护服务页 -->
<style scoped>
    
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">医护服务</div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="userName" class="form-label">客户账号</label>
                                <input class="form-control" id="userName" v-model="searchUserID" placeholder="客户姓名">
                            </div>
                        </div>
                    </form>
                    <div class="flex-grow-1 h-0 overflow-scroll">
                        <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                            <thead>
                                <tr class="text-light">
                                    <th class="bg-primary bg-gradient">预约号</th>
                                    <th class="bg-primary bg-gradient">客户账号</th>
                                    <th class="bg-primary bg-gradient">宠物编号</th>
                                    <th class="bg-primary bg-gradient">状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in appointList" :key="item">
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('docdruyInfo',{reserveID:item.ReserveID})" role="button">{{item.ReserveCode}}</span>
                                    </td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('userinfo')" role="button">{{item.UserID}}</span>
                                    </td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('petinfo')" role="button">{{item.PetID}}</span>
                                    </td>
                                    <td>
                                        <select class="form-select form-select-sm" :name="'appointState'+item.ReserveID" :value="item.Status" >
                                            <option :value="appoint_state.store">已到店</option>
                                            <option :value="appoint_state.complete">完成</option>
                                            <option :value="appoint_state.cancel">取消</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import pagination from "@/components/public/pagination.vue";
import { DATA_TYPE,STATE_TYPE,OPERATE_TYPE,APPOINT_STATE,LAYER_TYPE } from "@/assets/js/config";
export default {
    components:{
        pagination
    },
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        },
        pagesize:0,//每页显示数据条数
    },
    data() {
        return {
            operate_type:OPERATE_TYPE,//操作类型
            stateType:STATE_TYPE,//状态类型
            appoint_state:APPOINT_STATE,//预约状态
            //预约
            appointList:[],//预约列表
            searchUserID:"",//用户ID
            isSearching:false,//是否正在搜索
        };
    },
    mounted() {
        if(this.$route.params.result){
            this.showLayer({str:"保存成功！"});
        }
        this.getAppoint();
    },
    methods: {
        /**
         * 获取预约列表
         */
        getAppoint() {
            if(!this.isSearching){
                this.isSearching=true;
                let params={//参数
                    fun:"getreserve",
                    guid:this.user.guid,
                    // encrystr:"dd",
                    pageindex:"1",
                    pagesize:this.pagesize,
                    status:APPOINT_STATE.store,
                }
                this.searchUserID!=""&&(params.userid=this.searchUserID);
                this.$root.requestData(DATA_TYPE.getreserve,params,(data)=>{
                    this.appointList=data.list;
                    this.isSearching=false;
                    console.log(this.appointList)
                })
            }
        },
    },
    watch:{
        /**
         * 监听搜索条件改变
         */
         searchUserID() {
            this.getAppoint();
        }
    }
}
</script>