<style scoped>
    .nav-menu{
        background-image: url(@/assets/images/common/footprint.png);
        background-color: #0d6efd;
        background-repeat: no-repeat;
        background-position: center center;
    }
</style>
<template>
    <!-- 左侧菜单栏 -->
    <div class="d-flex flex-column rounded-10 text-light overflow-hidden">

        <!-- logo -->
        <div class="d-flex flex-column align-items-center py-4 bg-primary rounded-start-0" :class="{'rounded-10':firstMenuList.findIndex(v=>v.name==$route.name)==0}">
            <img class="img-fluid" src="@/assets/images/common/logo.png" alt="">
            <span class="fs-5 fw-bold pt-2">宠物管理系统</span>
        </div>

        <!-- 一级菜单 -->
        <div v-for="(item,i) in firstMenuList" :key="item.name" 
            :class="[currFirstMenu.index==i?'text-primary':'text-light',i-1==currFirstMenu.index?'rounded-start-0 rounded-bottom-0 rounded-10':'',i+1==currFirstMenu.index?'rounded-start-0 rounded-top-0 rounded-10':'']" 
            class="text-center fw-bold bg-primary text-primary" @click="$root.linkTo(this.getRouterName(item))" role="button">
            <div class="ms-3 rounded-pill rounded-end" :class="{'bg-white':currFirstMenu.index==i}">
                <div class="py-3 d-flex ps-5" :class="{'bg-secondary bg-opacity-10 rounded-20 rounded-end-0':currFirstMenu.index==i}">
                    <i :class="item.meta['ico']" class="bi me-2 fs-5 d-flex flex-column justify-content-center"></i>
                    <span>{{item.meta['title']}}</span>
                </div>
            </div>
        </div>

        <!-- 技术支持 -->
        <div class="flex-grow-1 d-flex flex-column justify-content-end p-3 nav-menu">
            <label class="small fw-bold">技术支持：</label>
            <label class="small">深圳市致远鸿图科技有限公司</label>
        </div>

    </div>
</template>
<script>
    import { ROUTE_TYPE } from "@/assets/js/config";
    export default {
        directives:{
            /**鼠标移到导航项显示子项的指令 */
            // hover:{
            //     mounted(el){
            //         el.onmouseover=()=>{
            //             for (let elem of el.children) {
            //                 if(el.className.indexOf('dropend')!=-1){//向右展示的导航
            //                     elem.className.indexOf('dropdown-menu')!=-1&&elem.setAttribute('data-bs-popper','none');//加上这个玩意才能向右展示
            //                 }
            //                 elem.classList.add('show');//导航现身
            //             }
            //         }
            //         el.onmouseout=()=>{
            //             for (let elem of el.children) {
            //                 elem.classList.remove('show');//导航隐身
            //             }
            //         }
            //     }
            // }
        },
        props: {
            /**
             * 用户对象
             */
			user: {
				name:"",//用户名
				guid:"",
			},
        },
        data(){
            return {
                routeType:ROUTE_TYPE,//获取路由类型配置
                routerList:this.$router.getRoutes(),//路由列表
                firstMenuList:[],//一级菜单列表
                currFirstMenu:{
                    menu:null,
                    index:-1
                },//当前选中的一级菜单
                // menuList:this.getBreadcrumb(),
            }
        },
        created() {
            this.firstMenuList = this.routerList.filter(v=>v.meta['type']==ROUTE_TYPE.firstMenu&&!v.meta['parent']);
            this.currRouterMenu();
        },
        mounted(){

        },
        methods:{
            /**
             * 获取面包屑导航
             */
            // getBreadcrumb(){
            //     let arr=[];
            //     let routerList=this.$router.getRoutes();//获取路由配置列表
            //     let item = routerList.find(v=>v.name==this.$route.name);
            //     while (true) {
            //         arr.unshift({title:item.meta["title"],name:item.name});
            //         if(!item.meta["parent"]){//没有父级菜单则中止
            //             break;
            //         }
            //         item=routerList.find(v=>v.name==item.meta["parent"]);//获取父级菜单
            //     }
            //     return arr;
            // },
            /**
             * 当前路由页面对应的父菜单下标
             */
            currRouterMenu() {
                let parent=this.routerList.filter(v=>v.meta['type']==ROUTE_TYPE.firstMenu);//所有一级菜单
                let router= this.routerList.find(v=>v.name==this.$route.name);//路由列表中找当前路由页面的对象
                //迭代找到根父菜单
                while (router.meta["parent"]) {//当前路由页面有父菜单
                    router=this.routerList.find(v=>v.name==router.meta["parent"]);
                }
                this.currFirstMenu.index=parent.findIndex(v=>v.name==router.name);
                this.currFirstMenu.menu=router;
            },
            /**
             * 获取要跳转到的路由名称
             */
            getRouterName(router){
                let child = this.routerList.find(v=>v.meta["parent"]==router.name);//找找该路由有没有二级菜单
                if(child){//有
                    return child.name;//返回子元素的路由名称
                }
                return router.name;//返回它本身的路由名称
            },
        },
        watch: {
            $route(to) {//监听路由变化
                // this.menuList=this.getBreadcrumb();
                this.currRouterMenu();
            }
        },
    }
</script>