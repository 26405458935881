<style scoped>
    
</style>
<template>
    <!-- 顶部工具栏 -->
    <header class="bg-light px-3 py-2 rounded-10 d-flex align-items-center justify-content-between" >
        <!-- 头像 -->
        <div class="d-flex align-items-center">
            <img class="rounded-circle img-thumbnail img-fluid border-info w-3rem" src="@/assets/images/navbar/photo.jpg" alt="">
            <span class="ps-2 text-dark fw-bold">{{user.name}}</span>
        </div>
        <!-- 搜索 -->
        <div class="d-flex border border-info border-1 rounded-pill align-items-center px-2">
            <i class="bi bi-search text-info"></i>
            <input type="text" class="form-control border-0 bg-transparent shadow-none" placeholder="搜索订单/产品/用户等" id="demo" name="email">
        </div>
        <!-- 退出 -->
        <button type="button" class="btn btn-primary bg-gradient rounded-pill px-4">
            <i class="bi bi-box-arrow-right me-1"></i>
            <span>退出</span>
        </button>
    </header>
</template>
<script>
    import { ROUTE_TYPE } from "@/assets/js/config";
    export default {
        props: {
            /**
             * 用户对象
             */
			user: {
				name:"",//用户名
				guid:"",
			},
        },
        data(){
            return {
                routeType:ROUTE_TYPE,//获取路由类型配置
                routerList:this.$router.getRoutes(),//路由列表
                pageName:this.$route.name,//当前所在页面
            }
        },
        mounted(){
            
        },
        methods:{
            linkTo(href){
                this.$router.push(href);
            },
            
        },
        watch: {
            $route(to) {//监听路由变化
                this.pageName=to.name;
            }
        },
    }
</script>