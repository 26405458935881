// 产品进口收据管理页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">产品进口收据</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="fileNum" class="form-label">文件编号</label>
                            <input class="form-control" id="fileNum" placeholder="文件编号">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="seller" class="form-label">供货商</label>
                            <input class="form-control" id="seller" placeholder="供货商">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="billNum" class="form-label">税务发票编号</label>
                            <input class="form-control" id="billNum" placeholder="税务发票编号">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="receiptType" class="form-label">收据类型</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>从分部进货的收据</option>
                                <option>购买产品的收据</option>
                                <option>其他收据</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="doctor" class="form-label">收据状态</label>
                            <select class="form-select">
                                <option>全部</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end align-items-end mb-3">
                            <button type="button" class="btn btn-info text-white">
                                <i class="bi bi-plus-lg"></i> 开收据
                            </button>
                        </div>
                    </div>
                </form>
                <tableList></tableList>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>