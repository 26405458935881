//预提费用页面
<style scoped></style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">添加预提费用单</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">日期</label>
                            <input class="form-control" type="datetime-local" id="opdDate" placeholder="日期">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">项目</label>
                            <input class="form-control" id="roleId" placeholder="项目">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">预支人员</label>
                            <input class="form-control" id="roleId" placeholder="预支人员">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">所属部门</label>
                            <input class="form-control" id="roleId" placeholder="所属部门">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">预支费用</label>
                            <input class="form-control" id="roleId" placeholder="预支费用">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">备注</label>
                            <input class="form-control" id="roleId" placeholder="备注">
                        </div>
                    </div>
                </form>
                <tableList></tableList>
            </div>
        </div>
    </section>
</template>
<script>
    import tableList from "@/components/public/table.vue";
    export default{
        components:{
        tableList
    }
    }
</script>