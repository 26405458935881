// 库存货物管理页
<style scoped>
    .accordion-button.noico::after{
        content:none;
    }
</style>
<template>
    <section class="h-100">
        <div class="card h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>采购信息</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="h-100">
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="warehouse" class="form-label">产品ID</label>
                                <input class="form-control" id="productName"  disabled :placeholder="productId">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="product" class="form-label">产品名称</label>
                                <input class="form-control" id="createTime" disabled :placeholder="productName">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="product" class="form-label">品牌名</label>
                                <input class="form-control" id="createTime" disabled :placeholder="brandName">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="unitName" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>单位名称
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('unitName')}" class="form-control" id="unitName" placeholder="单位名称" v-model="unitName" :disabled="$route.params.stockId!='0'">
                                <div class="invalid-tooltip">{{ userDataValid("unitName") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="unitPrice" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>单价
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('unitPrice')}" class="form-control" id="unitPrice" placeholder="单价" v-model="unitPrice" :disabled="$route.params.stockId!='0'">
                                <div class="invalid-tooltip">{{ userDataValid("unitPrice") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="quantity" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>数量
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('quantity')}" class="form-control" id="quantity" placeholder="数量" v-model="quantity" :disabled="$route.params.stockId!='0'">
                                <div class="invalid-tooltip">{{ userDataValid("quantity") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="amount" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>金额
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('amount')}" class="form-control" id="amount" placeholder="金额" v-model="amount" :disabled="$route.params.stockId!='0'">
                                <div class="invalid-tooltip">{{ userDataValid("amount") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="status" class="form-label">状态</label>
                                <select class="form-select" id="status" name="status" v-model="status" disabled>
                                    <option :value="1">已入库</option>
                                    <option :value="0">待入库</option>
                                </select>
                            </div>
                             <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative" :class="{'d-none':$route.params.stockId=='0'}" >
                                <label for="putinQuantity" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>实际入库数量
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('putinQuantity')}" class="form-control" id="putinQuantity" placeholder="实际入库数量" v-model="putinQuantity" :disabled="this.status==1">
                                <div class="invalid-tooltip">{{ userDataValid("putinQuantity") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="memo" class="form-label">备注</label>
                                <input class="form-control" id="memo" placeholder="备注" v-model="memo">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="creater" class="form-label">创建人</label>
                                <input class="form-control" id="creater" disabled :placeholder="creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="createTime" class="form-label">创建时间</label>
                                <input class="form-control" id="createTime" disabled :placeholder="createTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2" :class="{'d-none':$route.params.stockId=='0'}">
                                <label for="putinTime" class="form-label">入库时间</label>
                                <input class="form-control" id="createTime" disabled :placeholder="putinTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2" :class="{'d-none':$route.params.stockId=='0'}">
                                <label for="verifier" class="form-label">校验人</label>
                                <input class="form-control" id="createTime" disabled :placeholder="verifier">
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div> 
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import { LAYER_TYPE,RESULT_TYPE,IS_DEBUG,DATA_TYPE,APP_DATA_TYPE,OPERATE_TYPE,STATE_TYPE,PRODUCT_TYPE } from "@/assets/js/config";
export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        }
    },
    data(){
        return{
            state_type:STATE_TYPE,//获取状态配置
            funParams:"",
            isVerifyEmpty:false,//是否进行非空验证
            stockId:0,
            productId:0,
            productName:"",
            brandName:"",
            unitName:"",
            unitPrice:"",
            quantity:"",
            amount:"",
            status:0,
            putinQuantity:0,//入库数量
            creater:"",
            createTime:new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
            putinTime:new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),//入库时间
            verifier:"",
            memo:"",
            stockNum:"",//物料当前库存
            editModel:OPERATE_TYPE.insert,//当前编辑模式，默认为新增
        }
    },
    created(){
        let productid=this.$route.params.productId;
        let stockid=this.$route.params.stockId;
        console.log(productid)
        console.log(stockid)
        this.creater=this.user.name;
        if(productid!=0&&stockid==0){//非空代表要产品采购
            this.productId=productid;
            this.editModel=OPERATE_TYPE.insert;
            this.getWareInfoById(productid);
        }else if(productid!=0&&stockid!=0){//非空代表修改实际入库数量
            this.productId=productid;
            this.stockId=stockid;
            this.editModel=OPERATE_TYPE.update;
            this.getStockInfoById(stockid);
            this.getWareInfoById(productid);
        }
    },
    mounted(){},
    methods:{
        /**
         * 用户数据有效性验证
         */
         userDataValid(dataName){
            switch (dataName) {
                case "unitName"://单价名称校验
                    if(!this.unitName){
                        return "单价名称为必填项！";
                    }
                    break;
                case "unitPrice"://单价校验
                    if(!this.unitPrice){
                        return "单价为必填项！";
                    }
                    break;
                case "quantity"://数量校验
                    if(!this.quantity){
                        return "数量为必填项！";
                    }
                    break;
                case "amount"://金额校验
                    if(!this.amount){
                        return "金额为必填项！";
                    }
                    break;
                case "putinQuantity"://金额校验
                    if(this.stockId!=0){
                        if(!this.putinQuantity||this.putinQuantity=="0"){
                            return "实际入库数量为必填项！";
                        }
                    }
                    break;
                default:
                    break;
            }
            return null;
        },
        save(){
             //验证未通过
             if(this.userDataValid("unitName")||this.userDataValid("unitPrice")||this.userDataValid("quantity")||this.userDataValid("amount")){
                return;
            }
            if(this.editModel==OPERATE_TYPE.update){
                if(this.userDataValid("putinQuantity")){
                    return;
                }
            }
            if(this.status==1){
                this.showLayer({str:"该数据已入库，不能二次入库"});
                return;
            }
            this.$root.requestData(this.editModel==OPERATE_TYPE.update?DATA_TYPE.updstock:DATA_TYPE.addstock,{
                productid:this.productId,
                unitname:this.unitName,
                unitprice:this.unitPrice,
                quantity:this.quantity,
                amount:this.amount,
                memo:this.memo,
                //修改参数
                id:this.stockId,
                status:this.editModel==OPERATE_TYPE.update?1:0,
                putinquantity:this.putinQuantity,
            },async (data)=>{
                    this.$root.requestData(this.editModel==OPERATE_TYPE.update?DATA_TYPE.updproduct:'',{
                    productid:this.productId,
                    productname:this.productName,
                    serviceid:PRODUCT_TYPE.drug.id,
                    brandname:this.brandName,
                    unitname:this.unitName,
                    price:this.unitPrice,
                    stock:parseInt(this.stockNum)+parseInt(this.putinQuantity),
                    isenable:1,//是否启用
                    isreserve:0,//是否可预约,0代表否
                    isstock:1,//是否实物，1代表是
                    memo:this.memo,
                },async (data)=>{
                    this.$root.linkTo("warelist",{result:true});
                })
            })
        },
        /**获取物料信息 */
        getWareInfoById(productId){
            this.$root.requestData(DATA_TYPE.getproductbyid,{
                productid:productId
            },(data)=>{
                this.productId=data.list[0].ProductID;
                this.productName=data.list[0].ProductName;
                this.brandName=data.list[0].BrandName;
                this.stockNum=data.list[0].Stock;
            })
        },
        /**获取出入库存信息 */
        getStockInfoById(stockId){
            this.$root.requestData(DATA_TYPE.getstockbyid,{
                id:stockId
            },(data)=>{
                this.productId=data.list[0].ProductID;
                this.productName=data.list[0].ProductName;
                this.brandName=data.list[0].BrandName;
                this.unitName=data.list[0].UnitName;
                this.unitPrice=data.list[0].UnitPrice;
                this.quantity=data.list[0].Quantity;
                this.amount=data.list[0].Amount;
                this.putinQuantity=data.list[0].PutinQuantity;
                this.creater=data.list[0].Creater;
                this.createTime=data.list[0].CreateTime;
                this.status=data.list[0].Status;
                this.memo=data.list[0].Memo;
                this.putinTime=data.list[0].PutinTime;
                this.verifier=data.list[0].Verifier;
            })
        }
    }

}
</script>