// 登录页
<style scoped>
    .login-container{
        background: url("@/assets/images/login/login_bg.jpg") no-repeat center center;
        background-size: cover;
    }
</style>
<template>
    <div class="login-container min-h d-flex flex-column justify-content-center overflow-scroll">
        <div class="d-flex justify-content-center py-3">
            <div class="d-flex flex-column mx-3">
                <div class="fs-2 fw-bold text-white mb-3 text-center">欢迎{{(currLoginType==loginType.register?'注册':'登录')}}</div>
                <div class="card bg-white bg-opacity-50">
                    <div class="card-header bg-info bg-opacity-50 bg-gradient fw-bold">用户{{(currLoginType==loginType.register?'注册':'登录')}}</div>
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-3">
                            <div class="me-1 me-sm-3">
                                <label class="col-form-label" for="username">{{(currLoginType==loginType.code?'手机':'账号')}}:</label>
                            </div>
                            <div class="flex-grow-1">
                                <input type="text" class="form-control" :class="[verifyClass('userName')]" id="username" :placeholder="currLoginType==loginType.code?'请输入手机号':'请输入账号/手机号'" v-model.trim="userName" name="username">
                                <div class="invalid-feedback">{{userNameVerifyTip}}</div>
                            </div>
                        </div>
                        <div v-if="(currLoginType==loginType.pwd||currLoginType==loginType.register)" class="d-flex justify-content-between mb-3">
                            <div class="me-1 me-sm-3">
                                <label class="col-form-label" for="pwd">密码:</label>
                            </div>
                            <div class="flex-grow-1">
                                <input type="password" v-model.trim="pwd" :class="[verifyClass('pwd')]" class="form-control" id="pwd" placeholder="请输入密码" name="pwd">
                                <div class="invalid-feedback">{{pwdVerifyTip}}</div>
                            </div>
                        </div>
                        <div v-if="(currLoginType==loginType.register)" class="d-flex justify-content-between mb-3">
                            <div class="me-1 me-sm-3">
                                <label class="col-form-label" for="pwdAgain">确认密码:</label>
                            </div>
                            <div class="flex-grow-1">
                                <input type="password" v-model.trim="pwdAgain" :class="[verifyClass('pwdAgain')]" class="form-control" id="pwd" placeholder="确认密码" name="pwdAgain">
                                <div class="invalid-feedback">{{pwdAgainVerifyTip}}</div>
                            </div>
                        </div>
                        <div v-if="(currLoginType==loginType.code||currLoginType==loginType.register)" class="d-flex" :class="[currLoginType==loginType.register?'mb-3':'mb-2']">
                            <div class="me-3 flex-grow-1">
                                <input type="text" class="form-control" :class="[verifyClass('code')]" id="code" placeholder="验证码" v-model.trim="code" name="code">
                                <div class="invalid-feedback">{{codeVerifyTip}}</div>
                            </div>
                            <div>
                                <button class="btn btn-success text-nowrap" @click="getCode">获取验证码</button>
                            </div>
                        </div>
                        <div v-if="(currLoginType!=loginType.register)" class="d-flex justify-content-between small text-muted mb-2">
                            <span class="link-secondary" v-show="currLoginType==loginType.code" @click="currLoginType=loginType.pwd" role="button">密码登录</span>
                            <span class="link-secondary" v-show="currLoginType==loginType.pwd" @click="currLoginType=loginType.code" role="button">验证码登录</span>
                            <span class="link-secondary" @click="currLoginType=loginType.code" role="button">忘记密码？</span>
                        </div>
                        <div class="text-end">
                            <div class="d-grid">
                                <button v-show="(currLoginType!=loginType.register)" class="btn btn-block btn-info" @click="login">登 录</button>
                                <button v-show="currLoginType==loginType.register" class="btn btn-block btn-info" @click="register">注 册</button>
                                <button v-show="(currLoginType!=loginType.register)" type="button" class="btn btn-link" @click="(currLoginType=loginType.register)">注册账号</button>
                                <button v-show="currLoginType==loginType.register" type="button" class="btn btn-link" @click="(currLoginType=loginType.pwd)">已有账号？立即登录>></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { LOGIN_TYPE,LAYER_TYPE,IS_DEBUG,APP_DATA_TYPE,DATA_TYPE } from "@/assets/js/config";
export default {
    data() {
        return {
            loginType:LOGIN_TYPE,//获取登录类型配置
            currLoginType:LOGIN_TYPE.pwd,//当前登录方式
            userName:"",//账号
            pwd:"",//密码
            pwdAgain:"",//确认密码
            code:"",//验证码
            verify:{
                userName:false,
                pwd:false,
                pwdAgain:false,
                code:false
            },//是否验证通过
            isVerifyEmpty:false,//是否进行非空验证
            userNameVerifyTip:"",//账号验证提示
            pwdVerifyTip:"",//密码验证提示
            pwdAgainVerifyTip:"",//确认密码验证提示
            codeVerifyTip:"",//验证码验证提示
        };
    },
    created() {
        if(IS_DEBUG){
            this.userName="sir";
            this.pwd="123456";
        }
    },
    methods:{
        /**
         * 登录
         */
        login(){
            this.isVerifyEmpty=true;
            if(this.currLoginType==LOGIN_TYPE.pwd){//密码登录
                if((!this.verify.userName)||(!this.verify.pwd)){
                    return;
                }
            }else if(this.currLoginType==LOGIN_TYPE.code){//验证码登录
                if(!this.verify.userName||!this.verify.code){
                    return;
                }
            }
            this.$root.requestData(DATA_TYPE.userlogin,{
                userid:this.userName,
                pwd:this.pwd,
                ip:"127.0.0.1",
                fromto:1,
            },(data)=>{
                this.$emit("set-param",APP_DATA_TYPE.user,{
                    name:this.userName,
                    guid:data.guid,
                });
                this.$root.linkTo("home",{
                    name:this.userName,
                    guid:data.guid,
                });
            })
        },
        /**
         * 注册
         */
        register(){
            this.isVerifyEmpty=true;
            if(!this.verify.userName||!this.verify.pwd||!this.verify.pwdAgain){
                return;
            }
            this.$root.axiosRequest({
                method:'post',
                url:'handler_login.ashx',
                params:{
                    fun:"userlogin",
                    userid:this.userName,
                    pwd:this.pwd,
                    ip:"127.0.0.1",
                    fromto:1,
                }
            },(data)=>{
                this.$router.push({
                    path:"/home",
                    params:{}
                })
            })
        },
        /**
         * 获取验证码
         */
        getCode(){
            this.$root.showLayer(LAYER_TYPE.info,"暂时无法获取验证码，请使用密码登录！");
        },
        /**
         * 验证样式
         */
        verifyClass(type){
            if(type=="userName"){
                if(this.userName==""){//账号没填
                    if(this.isVerifyEmpty){//需要验证非空
                        this.userNameVerifyTip="账号不能为空！";
                        this.verify.userName=false;
                        return "is-invalid";
                    }else{//不需要验证非空
                        return "";
                    }
                }
                if(this.currLoginType==LOGIN_TYPE.register){//注册
                    if(this.userName.length<6||this.userName.length>11){//账号长度不在6~11位之间
                        this.userNameVerifyTip="账号必须在6~11位！";
                        this.verify.userName=false;
                        return 'is-invalid';
                    }else{
                        this.verify.userName=true;
                        return "is-valid";
                    }
                }
                this.verify.userName=true;
            }else if(type=="pwd"){
                if(this.pwd==""){//密码没填
                    if(this.isVerifyEmpty){//需要验证非空
                        this.pwdVerifyTip="密码不能为空！";
                        this.verify.pwd=false;
                        return "is-invalid";
                    }else{//不需要验证非空
                        return "";
                    }
                }
                if(this.currLoginType==LOGIN_TYPE.register){//注册
                    if(this.pwd.length<6){//密码长度小于6位
                        this.pwdVerifyTip="密码长度至少为6位！";
                        this.verify.pwd=false;
                        return 'is-invalid';
                    }else{
                        this.verify.pwd=true;
                        return "is-valid";
                    }
                }
                this.verify.pwd=true;
            }else if(type=="pwdAgain"){
                if(this.pwdAgain==""){//账号没填
                    if(this.isVerifyEmpty){//需要验证非空
                        this.pwdAgainVerifyTip="确认密码不能为空！";
                        this.verify.pwdAgain=false;
                        return "is-invalid";
                    }else{//不需要验证非空
                        return "";
                    }
                }
                if(this.currLoginType==LOGIN_TYPE.register){//注册
                    if(this.pwd!=this.pwdAgain){
                        this.pwdAgainVerifyTip="确认密码与密码不一致！";
                        this.verify.pwdAgain=false;
                        return "is-invalid";
                    }else{
                        this.verify.pwdAgain=true;
                        return "is-valid";
                    }
                }
                this.verify.pwdAgain=true;
            }else if(type=="code"){
                if(this.currLoginType==LOGIN_TYPE.code){
                    if(this.code==""){//验证码没填
                        if(this.isVerifyEmpty){//需要验证非空
                            this.codeVerifyTip="验证码不能为空！";
                            this.verify.code=false;
                            return "is-invalid";
                        }else{//不需要验证非空
                            return "";
                        }
                    }
                }
            }
        }
    },
    watch: {
        /**
         * 一旦切换登录方式
         */
        currLoginType(newVal) {
            this.isVerifyEmpty=false;//不进行非空验证
        },
    },
}
</script>