// 添加角色页面
<style scoped>

</style>
<template>
    <section class="row">
        <div class="col-md-4 col-lg-3">
            <div class="d-grid">
                <button type="button" class="btn btn-info d-block mb-2 text-white">
                    <i class="bi bi-clipboard2-plus"></i> 新增角色及权限
                </button>
            </div>
            <div class="card">
                <div class="card-header bg-info text-white text-center">角色名称</div>
                <div class="card-body p-0">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed noico" type="button">院长</button>
                            </h2>
                            <!-- 子菜单 -->
                            <div id="flush-headingOne" class="accordion-collapse collapse show">
                                <div class="accordion-body p-0">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingOne1">
                                            <button class="accordion-button pt-2 pb-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                <div class="ps-2">人员配置</div>
                                            </button>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div id="flush-headingTwo" class="accordion-collapse">

                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed noico" type="button">财务人员</button>
                            </h2>
                            <!-- 子菜单 -->
                            <div id="flush-headingOne" class="accordion-collapse collapse show">
                                <div class="accordion-body p-0">
                                    <div class="accordion-item">
                                        <h2 class= "accordion-header" id="flush-headingOne1">
                                            <button class="accordion-button pt-2 pb-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                <div class="ps-2">财务模块</div>
                                            </button>
                                        </h2>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingOne1">
                                            <button class="accordion-button pt-2 pb-2" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                <div class="ps-2">库存模块</div>
                                            </button>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                <div class="card-header bg-info text-light">角色人员管理</div>
                <div class="card-body">
                    <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="roleId" class="form-label">角色ID</label>
                                <input class="form-control" id="roleId" placeholder="角色ID">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="roleName" class="form-label">角色名称</label>
                                <input class="form-control" id="roleName" placeholder="角色名称">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="parentRole" class="form-label">角色权限</label>
                                <select class="form-select">
                                    <option>科室管理</option>
                                    <option>人员调配</option>
                                    <option>前台人员</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="parentRole" class="form-label">上级角色</label>
                                <select class="form-select">
                                    <option>院长</option>
                                    <option>科长</option>
                                    <option>主任</option>
                                    <option>护士长</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="remarks" class="form-label">备注</label>
                                <input class="form-control" id="remarks" placeholder="备注">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex justify-content-end align-items-end mb-3">
                                <button type="button" class="btn btn-info me-3 text-white">
                                    <i class="bi bi-plus-lg"></i> 添加新产品
                                </button>
                                <button type="button" class="btn btn-warning me-3 text-white">
                                    <i class="bi bi-arrow-left-right"></i> 移动分组
                                </button>
                                <button type="button" class="btn btn-danger">
                                    <i class="bi bi-trash"></i> 删除选中数据
                                </button>
                            </div>
                        </div>
                    </form>
                    <tableList></tableList>
                </div>
                <div class="card-footer">
                    <pagination></pagination>
                </div>
            </div>
        </div>
            
    </section>
</template>
<script>
    import tableList from "@/components/public/table.vue";
    import pagination from "@/components/public/pagination.vue";
    export default{
        components:{
        tableList,
        pagination
    }
    }
</script>