<style scoped>
    
</style>
<template>
    <!-- 首页 -->
    <section class="d-flex h-100">
        <div class="d-flex flex-column me-3" style="width:60%">

            <!-- 事项数量栏 -->
            <menu-bar></menu-bar>

            <!-- 表格栏 -->
            <div class="px-2 mb-2 fw-bold">预约挂号</div>
            <div class="card bg-transparent border-0 flex-grow-1">
                <div class="card-body px-0 py-0">
                    <appoint-list pagesize="20"></appoint-list>    
                </div> 
            </div>

        </div>
        <div class="flex-grow-1 d-flex flex-column">
            <unwork class="shadow rounded-10 overflow-hidden mb-3"></unwork>
            <operation class="shadow rounded-10 overflow-hidden"></operation>
        </div>
    </section>
</template>
<script>
import menuBar from "@/components/public/menu.vue";
import tableList from "@/components/public/table.vue";
import appointList from "@/components/page/appointList.vue";
import pagination from "@/components/public/pagination.vue";
import operation from "@/components/public/operation.vue";
import unwork from "@/components/public/unwork.vue";
export default {
    components:{
        menuBar,
        tableList,
        appointList,
        pagination,
        operation,
        unwork
    },
    mounted() {
        
    },
}
</script>