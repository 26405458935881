// 分页列表组件
<style scoped>
    tr>th:not(:first-child):not(:last-child),tr>th:first-child>*,tr>th:last-child>*{
        background-color: #0d6efd;
    }
    tr>th:first-child,tr>th:last-child{
        padding: 0;
    }
    tr>th:first-child>*{
        border-top-left-radius: 50rem;
        border-bottom-left-radius: 50rem;
    }
    tr>th:last-child>*{
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
    }
    table{
        border-spacing: 0 5px !important;
        border-collapse: unset;
    }
</style>
<template>
    <div class="table-responsive">
        <table class="table table-hover table-borderless mb-0 text-nowrap align-middle">
            <thead class="text-white">
                <tr v-if="$route.name=='home'">
                    <th>
                        <div class="p-2">预约号</div>
                    </th>
                    <th>客户姓名</th>
                    <th>宠物编号</th>
                    <th>预约日期</th>
                    <th>
                        <div class="p-2">预约状态</div>
                    </th>
                </tr>
                <tr v-if="$route.name=='treatmentPlan'">
                    <th>宠物名字</th>
                    <th>客户名字</th>
                    <th>小屋</th>
                    <th>年龄</th>
                    <th>时间</th>
                </tr>
                <tr v-if="$route.name=='department'">
                    <th>科室编号</th>
                    <th>狗狗科室</th>
                    <th>科长</th>
                    <th>李某某</th>
                </tr>
                <tr v-if="$route.name=='payroll'">
                    <th>姓名</th>
                    <th>岗位</th>
                    <th>基本工资</th>
                    <th>加班</th>
                    <th>请假</th>
                    <th>提成</th>
                    <th>合计</th>
                </tr>
                <tr v-if="$route.name=='prepaidExpenses'">
                    <th>日期</th>
                    <th>项目</th>
                    <th>预支人员</th>
                    <th>所属部门</th>
                    <th>预支费用</th>
                    <th>备注</th>
                </tr>
                <tr v-if="$route.name=='expenseSheet'">
                    <th>日期</th>
                    <th>支出项目</th>
                    <th>经手人</th>
                    <th>支出金额</th>
                    <th>备注</th>
                </tr>
            </thead>
            <tbody>
                <template v-if="$route.name=='home'">
                    <tr v-for="i in 6" :key="i">
                        <td class="bg-white rounded-end-0 rounded-5">030</td>
                        <td class="bg-white">Defaultson</td>
                        <td class="bg-white">def@somemail.com</td>
                        <td class="bg-white">2024-05-19 15:00:00</td>
                        <td class="bg-white rounded-start-0 rounded-5">
                            <select class="form-select form-select-sm" id="sel1" name="sellist1">
                                <option>未到院</option>
                                <option>已到院</option>
                            </select>
                        </td>
                    </tr>
                </template>
                <tr v-if="$route.name=='treatmentPlan'" class="table-info" v-for="i in 6" :key="i">
                    <td>小卡卡</td>
                    <td>巴巴</td>
                    <td>1002房</td>
                    <td>3岁</td>
                    <td>2022-08-09</td>
                </tr>
                <tr v-if="$route.name=='department'" class="table-info" v-for="i in 10" :key="i">
                    <td>1001</td>
                    <td>科长</td>
                    <td>科室管理</td>
                    <td>主任</td>
                </tr>  
                <tr v-if="$route.name=='payroll'" class="table-info" v-for="i in 10" :key="i">
                    <td>李娜娜</td>
                    <td>护士长</td>
                    <td>5000</td>
                    <td>1000</td>
                    <td>0</td>
                    <td>2800</td>
                    <td>8800</td>
                </tr>    
                <tr v-if="$route.name=='prepaidExpenses'" class="table-info" v-for="i in 10" :key="i">
                    <th>2022-10-26</th>
                    <td>狗狗疫苗</td>
                    <td>李娜娜</td>
                    <td>狗狗科室</td>
                    <td>999</td>
                    <td>无</td>
                </tr>    
                <tr v-if="$route.name=='expenseSheet'" class="table-info" v-for="i in 10" :key="i">
                    <th>2022-10-26</th>
                    <td>狗狗疫苗</td>
                    <td>李娜娜</td>
                    <td>999</td>
                    <td>无</td>
                </tr>    
            </tbody>
        </table>
    </div>
</template>
<script>
import { DATA_TYPE,PARAMS_KEY,PRODUCT_TYPE } from "@/assets/js/config";
export default {
    
}
</script>