//工资单页面
<style scoped>

</style>
<template>
     <section>
        <div class="card">
            <div class="card-header bg-info text-light">添加工资单</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleId" class="form-label">姓名</label>
                            <input class="form-control" id="roleId" placeholder="姓名">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="roleName" class="form-label">岗位</label>
                            <input class="form-control" id="roleName" placeholder="岗位">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="parentRole" class="form-label">基本工资</label>
                            <input class="form-control" id="parentRole" placeholder="基本工资">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="parentRole" class="form-label">加班</label>
                            <input class="form-control" id="parentRole" placeholder="加班">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="remarks" class="form-label">请假</label>
                            <input class="form-control" id="remarks" placeholder="请假">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="remarks" class="form-label">提成</label>
                            <input class="form-control" id="remarks" placeholder="提成">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <label for="remarks" class="form-label">合计</label>
                            <input class="form-control" id="remarks" placeholder="合计">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                            <button type="button" class="btn btn-success btn-sm text-nowrap ms-2 ms-lg-0">
                                <i class="bi bi-save"></i> 保存
                            </button>
                        </div>
                    </div>
                </form>
                <tableList></tableList>
            </div>
        </div>
    </section>
</template>
<script>
    import tableList from "@/components/public/table.vue";
    export default{
        components:{
        tableList
    }
    }
</script>