<style scoped>
    
</style>
<template>
    <div class="row text-light pb-3">
        <div class="col-3">
            <div class="d-flex flex-column bg-warning bg-gradient rounded-3 p-2 shadow" role="button">
                <small>今日预约</small>
                <span class="text-center fw-bold fs-3 mb-1">66</span>
            </div>
        </div>
        <div class="col-3">
            <div class="d-flex flex-column bg-info bg-gradient rounded-3 p-2 shadow" role="button">
                <small>未处理</small>
                <span class="text-center fw-bold fs-3 mb-1">36</span>
            </div>
        </div>
        <div class="col-3">
            <div class="d-flex flex-column bg-primary bg-gradient rounded-3 p-2 shadow" role="button">
                <small>处理中</small>
                <span class="text-center fw-bold fs-3 mb-1">18</span>
            </div>
        </div>
        <div class="col-3">
            <div class="d-flex flex-column bg-danger bg-gradient rounded-3 p-2 shadow" role="button">
                <small>已处理</small>
                <span class="text-center fw-bold fs-3 mb-1">12</span>
            </div>
        </div>
    </div>
    
</template>
<script>
export default {
    
}
</script>