<!-- 预约管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header" v-if="$route.name=='appointlist'">
                <div class="d-flex justify-content-between">
                    <span>预约挂号</span>
                    <div class="text-primary" @click="$root.linkTo('appointinfo')" role="button">
                        <i class="bi bi-calendar-plus me-1"></i>
                        <span>预约</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form v-if="$route.name=='appointlist'">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="userName" class="form-label">客户账号</label>
                                <input class="form-control" id="userName" v-model="searchUserID" placeholder="客户账号">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="phone" class="form-label">联系电话</label>
                                <input class="form-control" id="phone" v-model="searchPhone" placeholder="联系电话">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="startDate" class="form-label">起始日期</label>
                                <input class="form-control" type="datetime-local" id="startDate" v-model="searchStartTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="endDate" class="form-label">结束日期</label>
                                <input class="form-control" type="datetime-local" id="endDate" v-model="searchEndTime">
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col d-flex justify-content-end align-items-end mb-3">
                                <button type="button" class="btn btn-outline-info me-3">
                                    <i class="bi bi-printer"></i> 打印本页预约
                                </button>
                                <button type="button" class="btn btn-danger">
                                    <i class="bi bi-trash"></i> 删除选中数据
                                </button>
                            </div>
                        </div> -->
                    </form>
                    <div class="flex-grow-1 h-0 overflow-scroll">
                        <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                            <thead>
                                <tr class="text-light">
                                    <th class="bg-primary bg-gradient">预约号</th>
                                    <th class="bg-primary bg-gradient">预约项目</th>
                                    <th class="bg-primary bg-gradient">客户账号</th>
                                    <th class="bg-primary bg-gradient">宠物编号</th>
                                    <th class="bg-primary bg-gradient">预约日期</th>
                                    <th class="bg-primary bg-gradient">预约状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in appointList" :key="item">
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('appointinfo',{appoinitId:item.ReserveID})" role="button">{{item.ReserveCode}}</span>
                                    </td>
                                    <td>{{item.ProductName}}</td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('userinfo')" role="button">{{item.UserID}}</span>
                                    </td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('petinfo')" role="button">{{item.PetID}}</span>
                                    </td>
                                    <td>{{item.ReserveDate}}</td>
                                    <!-- <td>
                                        <span class="btn-link" @click="$root.linkTo('userinfo')" role="button">郑神医</span>
                                    </td> -->
                                    <td>
                                        <select class="form-select form-select-sm" :name="'appointState'+item.ReserveID" :value="item.Status" @change="updState($event.target.value,item.ReserveCode)">
                                            <option :value="appoint_state.appoinit">未到店</option>
                                            <option :value="appoint_state.store">已到店</option>
                                            <option :value="appoint_state.complete">完成</option>
                                            <option :value="appoint_state.cancel">取消</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
            <div class="d-flex">
                <div class="col">
                    <div class="col-sm-3">
                        <span class="text-align"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import pagination from "@/components/public/pagination.vue";
import { DATA_TYPE,STATE_TYPE,OPERATE_TYPE,APPOINT_STATE,LAYER_TYPE } from "@/assets/js/config";
export default {
    components:{
        pagination
    },
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        },
        pagesize:0,//每页显示数据条数
    },
    data() {
        return {
            operate_type:OPERATE_TYPE,//操作类型
            stateType:STATE_TYPE,//状态类型
            appoint_state:APPOINT_STATE,//预约状态
            //预约
            appointList:[],//预约列表
            searchUserID:"",//用户ID
            searchPhone:"",//联系电话
            searchStartTime:"",//开始时间
            searchEndTime:"", //结束时间
            isSearching:false,//是否正在搜索
            updstate:0,
        };
    },
    mounted() {
        if(this.$route.params.result){
            this.showLayer({str:"保存成功！"});
        }
        this.getAppoint();
    },
    methods: {
        /**
         * 获取预约列表
         */
        getAppoint() {
            if(!this.isSearching){
                this.isSearching=true;
                let params={//参数
                    fun:"getreserve",
                    guid:this.user.guid,
                    // encrystr:"dd",
                    pageindex:"1",
                    pagesize:this.pagesize
                }
                this.searchUserID!=""&&(params.userid=this.searchUserID);
                this.searchPhone!=""&&(params.phone=this.searchPhone);
                this.searchStartTime!=""&&(params.starttime=this.searchStartTime);
                this.searchEndTime!=""&&(params.endtime=this.searchEndTime);
                this.$root.requestData(DATA_TYPE.getreserve,params,(data)=>{
                    this.appointList=data.list;
                    this.isSearching=false;
                    console.log(this.appointList)
                })
            }
        },
        updState(stateval,code){
            console.dir(stateval)
            this.showLayer({
                type:LAYER_TYPE.confirm,
                str:"是否确认修改客户状态",
                btn:["确认","取消"],
                yesFn:(i)=>{
                    layer.close(i);
                    this.$root.requestData(DATA_TYPE.updreserve,{
                        guid:this.user.guid,
                        reservecode:code,
                        status:stateval,
                    },(data)=>{
                        //this.$root.linkTo("appointlist",{result:true});
                        this.showLayer({str:"保存成功！"});
                        this.getAppoint();
                    })
                }
            })
            
        }
    },
    watch:{
        /**
         * 监听搜索条件改变
         */
         searchUserID() {
            this.getAppoint();
        },
        searchPhone() {
            this.getAppoint();
        },
        searchStartTime(){
            this.getAppoint();
        },
        searchEndTime(){
            this.getAppoint();
        }
        
    }
}
</script>