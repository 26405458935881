// 产品申购管理页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">产品进口收据</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="fileNum" class="form-label">文件编号</label>
                            <input class="form-control" id="fileNum" placeholder="文件编号">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="seller" class="form-label">申请详情</label>
                            <input class="form-control" id="seller" placeholder="申请详情">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg mb-3">
                            <label for="applyType" class="form-label">申请状态</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>申请中</option>
                                <option>通过</option>
                                <option>驳回</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col d-flex justify-content-end align-items-end mb-3">
                            <button type="button" class="btn btn-info text-white">
                                <i class="bi bi-plus-lg"></i> 提交申购
                            </button>
                        </div>
                    </div>
                </form>
                <tableList></tableList>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>