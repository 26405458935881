<!-- 服务管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>服务管理</span>
                    <div class="text-primary" role="button" @click="$root.linkTo('serviceinfo')">
                        <i class="bi bi-clipboard2-plus-fill me-1"></i>
                        <span>开设服务</span>
                    </div>
                    <!-- <div class="text-primary" v-show="currTab==tabName.type" role="button" @click="$root.linkTo('serviceinfo')">
                        <i class="bi bi-clipboard2-plus-fill me-1"></i>
                        <span>新增类型</span>
                    </div> -->
                </div>
            </div>
            <div class="card-body ps-0 pb-0">
                <div class="d-flex flex-column h-100">

                    <div class="flex-grow-1 h-0 overflow-scroll">

                        <!-- 未分配服务 -->
                        <div class="ps-3 pb-2">
                            <label for="user" class="form-label">未分配服务</label>
                            <div class="d-flex flex-wrap border rounded px-2 pb-2 pt-1">
                                <div v-for="service of noParentServiceList" :key="service" class="d-flex flex-wrap small">
                                    <div class="border rounded px-2 me-1 mt-1 bg-opacity-25 bg-secondary">
                                        <span @click="$root.linkTo('serviceinfo',{productId:service.ProductID})" role="button" class="">{{ service.ProductName }}</span>
                                        <i @click="delService(service)" class="bi bi-trash-fill text-danger ms-2" role="button" title="删除"></i>
                                    </div>
                                </div>
                                <div class="small mt-1" v-if="noParentServiceList.length==0">暂无</div>
                            </div>
                        </div>

                        <!-- 服务项目 -->
                        <div>
                            <label for="user" class="form-label ms-3">服务项目</label>
                            <div class="d-flex flex-wrap">
                                <div v-for="item of menuList" :key="item" class="col-3 ps-3 pb-3">
                                    <div class="border border-secondary rounded p-2 list-group-item-action">
    
                                        <div class="d-flex justify-content-between">
                                            <div @click="$root.linkTo('serviceinfo',{productId:item.ProductID})" class="flex-grow-1 d-flex justify-content-between align-items-center fw-bold" role="button">
                                                <span>{{item.ProductName}}</span>
                                                <!-- <span class="text-secondary small">100元</span> -->
                                            </div>
                                            <i @click="delService(item)" class="bi bi-trash-fill text-danger ms-2" role="button" title="删除"></i>
                                        </div>
    
                                        <div v-set-child="item" class="small"></div>

    
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
    import pagination from "@/components/public/pagination.vue";
    import { DATA_TYPE,SEX_TYPE,LAYER_TYPE,STATE_TYPE,OPERATE_TYPE,PRODUCT_TYPE } from "@/assets/js/config";
    export default {
        components:{
            pagination
        },
        props: {
            pagesize:0,//每页显示数据条数
        },
        directives: {
            "set-child":{
                mounted(elem,binding) {
                    let _this = binding.instance;
                    let parent = binding.value;
                    let html="";
                    let fn=(service)=>{
                        console.log(service)
                        let childArr = _this.getChildServiceListByService(service);
                        console.log(childArr)
                        if(childArr.length>0){
                            html+="<div class=\"list-group mt-1\">";
                        }
                        for (let i = 0; i < childArr.length; i++) {
                            html+="<div class=\"list-group-item list-group-item-action px-2 py-1\">";
                            html+=" <div class=\"d-flex justify-content-between\">";
                            html+="  <div class=\"flex-grow-1 d-flex justify-content-between align-items-center\" data-productid=\""+childArr[i].ProductID+"\" role=\"button\">";
                            html+="   <span>";
                            html+="    <span>"+childArr[i].ProductName+"</span>";
                            html+="    <span class=\"small text-secondary\">";
                            html+="     <i class=\"bi bi-x\"></i>";
                            html+="     <span>2</span>";
                            html+="    </span>";
                            html+="   </span>";
                            html+="   <span class=\"text-secondary small\">100元</span>";
                            html+="  </div>";
                            html+="  <i class=\"bi bi-trash text-danger ms-2\" role=\"button\" title=\"删除\"></i>";
                            html+=" </div>";
                            fn(childArr[i]);
                            html+="</div>";
                        }
                        if(childArr.length>0){
                            html+="</div>";
                        }
                    }
                    fn(parent);
                    console.log(html);
                    elem.innerHTML=html;
                    document.querySelectorAll("[data-productid]").forEach((e,i)=>{
                        //绑定跳转事件
                        e.onclick=()=>{
                            _this.$root.linkTo('serviceinfo',{productId:e.getAttribute("data-productid")});
                        }
                        //绑定删除事件
                        e.nextElementSibling.onclick=()=>{
                            _this.delService(_this.serviceList.find(v=>v.ProductID==e.getAttribute("data-productid")));
                        }
                    })
                },
            }
        },
        data() {
            return {
                operate_type:OPERATE_TYPE,//操作类型
                stateType:STATE_TYPE,//状态类型
                //服务
                serviceTypeList: [],//服务列表
                serviceList:[],//服务列表
                menuList:[],//菜单列表
                isShowAddServiceType:false,//是否显示新增服务
                serviceTypeName:"",//服务名称
                serviceTypeState:"",//服务状态
                serviceTypeMemo:"",//服务备注
                serviceTypeDepart:"",//服务科室id
                currChooseType:{},//当前选中的服务
            };
        },
        computed: {
            /**
             * 获取根父级服务列表
             */
            noParentServiceList() {
                //找到所有没有被其他服务或菜单作为子服务的服务
                return this.serviceList.filter(v=>this.serviceList.concat(this.menuList).every(w=>(","+w.ChildIDs+",").indexOf(","+v.ProductID+",")==-1));
            },
        },
        created() {
            if(this.$route.params.result){
                this.showLayer({str:"保存成功！"});
            }
        },
        async mounted() {
            await this.getServiceList();
            await this.getMenuList();
        },
        methods: {
            /**
             * 获取产品表服务列表
             */
            getServiceList() {
                this.$root.requestData(DATA_TYPE.getproduct,{
                    pageindex:1,
                    pagesize:this.pagesize,
                    serviceid:PRODUCT_TYPE.service.id
                },(data)=>{
                    this.serviceList=data.list;
                })
            },
            /**
             * 获取产品表菜单列表
             */
            getMenuList(){
                this.$root.requestData(DATA_TYPE.getproduct,{
                    pageindex:1,
                    pagesize:this.pagesize,
                    serviceid:PRODUCT_TYPE.menu.id
                },(data)=>{
                    this.menuList=data.list;
                })
            },
            /**
             * 根据父服务获取直接子服务列表
             */
            getChildServiceListByService(parent){
                let childIdArr=parent.ChildIDs?parent.ChildIDs.split(","):[];//获取子服务id数组
                return this.serviceList.filter(v=>childIdArr.indexOf(v.ProductID)>-1);
            },
            /**
             * 删除服务
             */
            delService(service){
                this.showLayer({
                    type:LAYER_TYPE.confirm,
                    str:"是否确认删除服务【"+service.ProductName+"】？",
                    btn:["确认","取消"],
                    yesFn:async (i)=>{
                        layer.close(i);

                        //删除要删除的服务的价格标准
                        this.$root.requestData(DATA_TYPE.delproduct,{
                            serviceid:PRODUCT_TYPE.price.id,
                            childids:service.ProductID
                        },()=>{})

                        //删除服务
                        this.$root.requestData(DATA_TYPE.delproduct,{
                            productid:service.ProductID
                        },()=>{})

                        // if(service.ChildIDs){//要删除的服务有子服务，说明可能存在价格标准
                        //     let childArr=service.ChildIDs.split(",");//包含了子服务和价格标准
                        //     let priceArr = this.priceList.filter(v=>childArr.indexOf(v.ProductID)!=-1);//筛选出价格标准
                        //     //删除价格标准
                        //     await new Promise((resolve)=>{
                        //         priceArr.length==0&&resolve();
                        //         for (let j = 0; j < priceArr.length; j++) {
                        //             this.$root.requestData(DATA_TYPE.delproduct,{
                        //                 productid:priceArr[j]
                        //             },()=>{
                        //                 j==priceArr.length-1&&resolve();
                        //             })
                        //         }
                        //     })
                        // }


                        // //删除服务（产品类型）
                        // this.$root.requestData(DATA_TYPE.delservices,{
                        //     serviceid:service.ServiceID
                        // },()=>{
                        //     this.serviceTypeList.splice(this.serviceTypeList.findIndex(v=>v.ServiceID==service.ServiceID),1);
                        // })
                    }
                })
            },
        },
    }
</script>