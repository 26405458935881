<!-- 科室信息页 -->
<style scoped>
    .bi-chevron-left::before{
        font-weight: bold !important;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>科室信息</span>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="departCode" class="form-label">科室号</label>
                                <input class="form-control" v-model="depart.departCode" id="departCode" placeholder="科室号">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <b class="me-1 text-danger">*</b>
                                <label for="departName" class="form-label">科室名称</label>
                                <input class="form-control" v-model="depart.departName" id="departName" placeholder="科室名称">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="position" class="form-label">位置</label>
                                <input class="form-control" v-model="depart.posi" id="position" placeholder="位置">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="identity" class="form-label">状态</label>
                                <select class="form-select" name="state" v-model="depart.status">
                                    <option :value="stateType.on">启用</option>
                                    <option :value="stateType.off">禁用</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">创建人</label>
                                <input type="text" class="form-control" disabled :placeholder="depart.creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">创建时间</label>
                                <input type="text" class="form-control" disabled :placeholder="depart.createTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-6 mb-2">
                                <label for="memo" class="form-label">备注</label>
                                <input type="text" class="form-control" v-model="depart.memo" id="memo" placeholder="备注">
                            </div>
                        </div>
                    </form>
                    <div class="d-flex flex-grow-1 h-0">
                        <!-- 服务配置 -->
                        <div class="flex-fill pe-2">
                            <div class="card h-100">
                                <div class="card-header bg-primary text-light">服务配置</div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>服务名称</th>
                                                        <th>所属科室</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="table-info align-middle" v-for="(item,i) in serviceList" :key="item.ServiceID" @click="serviceClick(item)">
                                                        <td :class="[currChooseServiceList.find(v=>v.ServiceID==item.ServiceID)?'text-success':'text-muted']">
                                                            <i :class="[currChooseServiceList.find(v=>v.ServiceID==item.ServiceID)?'bi-check-square-fill':'bi-check-square']" class="bi"></i>
                                                        </td>
                                                        <td :class="{'text-muted':!currChooseServiceList.find(v=>v.ServiceID==item.ServiceID)}">{{i+1}}</td>
                                                        <td>
                                                            <span :class="{'text-muted':!currChooseServiceList.find(v=>v.ServiceID==item.ServiceID)}" class="btn-link" @click="$root.linkTo('serviceinfo')" role="button">{{item.ServiceName}}</span>
                                                        </td>
                                                        <td>
                                                            <span :class="{'text-muted':!currChooseServiceList.find(v=>v.ServiceID==item.ServiceID)}" class="btn-link" @click="$root.linkTo('departmentinfo')" role="button">{{item.DepartName}}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 人员配置 -->
                        <div class="flex-fill ps-2">
                            <div class="card h-100">
                                <div class="card-header bg-secondary text-light">人员配置</div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>人员姓名</th>
                                                        <th>性别</th>
                                                        <th>职位</th>
                                                        <th>所属科室</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="table-info" v-for="(item,i) of userList" :key="i" @click="userClick(item)">
                                                        <td :class="[currChooseUserList.find(v=>v.UserID==item.UserID)?'text-success':'text-muted']">
                                                            <i :class="[currChooseUserList.find(v=>v.UserID==item.UserID)?'bi-check-square-fill':'bi-check-square']" class="bi"></i>
                                                        </td>
                                                        <td :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}">{{i+1}}</td>
                                                        <td>
                                                            <span :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}" class="btn-link" @click="$root.linkTo('userinfo')" role="button">{{item.UserName}}</span>
                                                        </td>
                                                        <td>
                                                            <i v-show="item.Sex==sexType.female" :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}" class="bi bi-gender-male text-primary"></i>
                                                            <i v-show="item.Sex==sexType.male" :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}" class="bi bi-gender-female text-danger"></i>
                                                        </td>
                                                        <td :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}">{{ item.PositionName }}</td>
                                                        <td>
                                                            <span v-for="(depart,j) of userDepartList.filter(v=>v.UserID==item.UserID)" :key="j" :class="{'text-muted':!currChooseUserList.find(v=>v.UserID==item.UserID)}" class="btn-link me-2" @click="$root.linkTo('departmentinfo')" role="button">{{depart.DepartName}}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="setDepart(depart.departID?operateType.update:operateType.insert)">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import {getDate} from "@/assets/js/common.js";
import { DATA_TYPE,SEX_TYPE,OPERATE_TYPE,LAYER_TYPE,STATE_TYPE } from "@/assets/js/config";
export default {
    props: {
        user:{
            name:"",//用户名
            guid:"",
        },
        
    },
    data(){
        return {
            //获取配置项
            operateType:OPERATE_TYPE,//操作类型
            sexType:SEX_TYPE,//性别配置
            stateType:STATE_TYPE,//状态类型
            
            departList:[],//科室列表
            serviceList:[],//服务列表
            currChooseServiceList:[],//当前选中的服务列表
            userList:[],//用户列表
            userDepartList:[],//用户所属科室列表
            currChooseUserList:[],//当前选中的用户列表
            depart:{
                departID:"",
                departCode:"",
                departName:"",
                posi:"",
                status:STATE_TYPE.on,
                creater:this.user.name,
                createTime:getDate(),
                memo:"",
            },//当前科室对象
        }
    },
    created() {
        let depart=this.$route.params.depart;
        if(depart){
            depart=JSON.parse(depart);
            this.depart.departID=depart.DepartID;
            this.depart.departCode=depart.DepartCode;
            this.depart.departName=depart.DepartName;
            this.depart.posi=depart.Posi;
            this.depart.status=depart.Status;
            this.depart.creater=depart.Creater;
            this.depart.createTime=depart.CreateTime;
            this.depart.memo=depart.Memo;
        }
    },
    mounted() {
        // 获取科室列表
        this.$root.requestData(DATA_TYPE.getdepart,{
            pageindex:1,
            pagesize:999999
        },(data)=>{
            this.departList=data.list;
        })
        // 获取服务列表
        this.$root.requestData(DATA_TYPE.getservices,{
            pageindex:1,
            pagesize:99999
        },(data)=>{
            this.serviceList=data.list;
            this.currChooseServiceList=this.serviceList.filter(v=>v.DepartID==this.depart.departId);
        })
        // 获取员工列表
        this.$root.requestData(DATA_TYPE.getusers,{
            isuser:1,
            status:1,
            pageindex:1,
            pagesize:99999
        },(data)=>{
            this.userList=data.list;
            let userIdList=[];
            for (let i = 0; i < this.userList.length; i++) {
                userIdList.push(this.userList[i].UserID)
            }
            //获取用户科室列表
            this.$root.requestData(DATA_TYPE.getuserdepart,{
                userid:String(userIdList)
            },(data)=>{
                this.userDepartList=data.list;
                this.currChooseUserList=this.userDepartList.filter(v=>v.DepartID==this.depart.departID);
            })
        })
    },
    methods: {
        /**
         * 选中服务
         */
        serviceClick(service){
            let index=this.currChooseServiceList.findIndex(v=>v.ServiceID==service.ServiceID);//获取点击项的下标
            if(index!=-1){//选中的列表中已有当前点击的服务
                this.currChooseServiceList.splice(index,1);//移除
            }else{
                this.currChooseServiceList.push(service);//加入
            }
        },
        /**
         * 选中人员
         */
        userClick(user) {
            let index=this.currChooseUserList.findIndex(v=>v.UserID==user.UserID);//获取点击项的下标
            if(index!=-1){//选中的列表中已有当前点击的人员
                this.currChooseUserList.splice(index,1);//移除
            }else{
                this.currChooseUserList.push(user);//加入
            }
        },
        /**
         * 科室增改
         * @param {OPERATE_TYPE} type 操作类型
         */
        setDepart(type){
            let depart={
                departname:this.depart.departName,
                departcode:this.depart.departCode,
                status:this.depart.status,
                posi:this.depart.posi,
                memo:this.depart.memo
            }
            if(this.depart.departName.trim()==""){
                this.showLayer({str:"科室名称不能为空！"});
            }else{
                switch (type) {
                    case OPERATE_TYPE.insert:
                        this.$root.requestData(DATA_TYPE.adddepart,depart,(data)=>{
                            this.$root.linkTo("departmentlist",{result:true});
                        })
                        break;
                    case OPERATE_TYPE.update:
                        depart.departid=this.depart.departID;
                        this.$root.requestData(DATA_TYPE.upddepart,depart,(data)=>{
                            // //将所属科室包含当前科室且未选中的人员去除当前科室，将所属科室不包含当前科室且选中的人员添加当前科室
                            // console.log(JSON.stringify(this.userDepartList));
                            // console.log(this.depart.departID)
                            // let chooseArr=JSON.parse(JSON.stringify(this.currChooseUserList));
                            // let checkArr=this.userDepartList.filter(v=>(v.DepartID!=this.depart.departID&&chooseArr.find(w=>w.UserID==v.UserID)));//要新增选中的项
                            // // let checkArr=this.userDepartList.filter((v)=>{
                            // //     v.DepartID!=this.depart.departID&&chooseArr.find(w=>w.UserID==v.UserID)
                            // // });//要新增选中的项
                            // let uncheckArr=this.userDepartList.filter(v=>v.DepartID==this.depart.departID&&!chooseArr.find(w=>w.UserID==v.UserID));//要取消选中的项
                            // console.log(JSON.stringify(checkArr))
                            // console.log(JSON.stringify(uncheckArr))
                            // this.$root.requestData(DATA_TYPE.setuserdepart,{
                            //     userid:String(this.currChooseUserList.map(v=>v.UserID)),
                            //     departid:this.depart.departID,
                            // },(data)=>{
                                
                            // })
                            this.$root.linkTo("departmentlist",{result:true});
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    },
}
</script>