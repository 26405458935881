// 底部栏
<style scoped>
footer{background: linear-gradient(90deg,#4a93f2, #3b40f4);}
</style>
<template>
    <footer class="text-white text-center pt-2 pb-2 mt-3">
        <div class="container">
            版权所有：深圳市致远鸿图科技有限公司  &nbsp;&nbsp; 备案/许可证编号：粤ICP备2021125205号
        </div>
    </footer>
</template>
<script>
export default {
    
}
</script>