<style scoped>
    .border-cyan{
        border-color: #4fcaa9 !important;
    }
</style>
<template>
    <div class="d-flex flex-column bg-light py-2 px-3">
        <span class="fw-bold mb-2 mt-1">快速操作</span>
        <div class="d-flex justify-content-between mb-1 small text-center">
            <div class="d-flex flex-column" role="button" @click="$root.linkTo('appointinfo')">
                <img class="mx-auto p-2 border border-2 border-warning rounded-3" src="@/assets/images/common/op-icon1.png" alt="">
                <span class="text-drck d-block mt-1">新预约</span>
            </div>
            <div class="d-flex flex-column" role="button" @click="$root.linkTo('userinfo')">
                <img class="mx-auto p-2 border border-2 border-cyan rounded-3" src="@/assets/images/common/op-icon2.png" alt="">
                <span class="text-drck d-block mt-1">开户</span>
            </div>
            <div class="d-flex flex-column" role="button" @click="$root.linkTo('userlist')">
                <img class="mx-auto p-2 border border-2 border-primary rounded-3" src="@/assets/images/common/op-icon3.png" alt="">
                <span class="text-drck d-block mt-1">新加宠物</span>
            </div>
            <div class="d-flex flex-column" role="button">
                <img class="mx-auto p-2 border border-2 border-info rounded-3" src="@/assets/images/common/op-icon4.png" alt="">
                <span class="text-drck d-block mt-1">更新寄养</span>
            </div>
        </div>
    </div>
</template>
