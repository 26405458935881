<style>
	*::-webkit-scrollbar {
		display: none;
	}
	.w-0{
		width: 0 !important;
	}
	.w-3rem{
		width: 3rem;
	}
	.h-0{
		height: 0;
	}
	.min-h{
		min-height: 100%;
	}
	.min-h-auto{
		min-height: auto !important;
	}
	.align-middle{
		vertical-align: middle;
	}
	.table-row-fixed tr th{
		position: sticky;
		top:0;
	}
	/* 文本竖直显示 */
	.text-vertical{
		writing-mode: vertical-lr;
	}
	.bg-content{
		background: content-box;
	}
	.bg-red{
        background:red;
    }
    .bg-green{
        background:green;
    }
    .bg-yellow{
        background:yellow;
    }
    .bg-blue{
        background:blue;
    }
    .bg-orange{
        background:orange;
    }
    .bg-cyan{
        background:cyan;
    }
    .bg-purple{
        background:purple;
    }
    .bg-gray{
        background:gray;
    }
    .bg-pink{
        background:pink;
    }
    .bg-brown{
        background:brown;
    }
	.border-style-dashed{
		border-bottom-style: dashed !important;
	}
	.disabled{
		cursor: no-drop !important;
		pointer-events:auto !important;
	}
	.rounded-5{
		border-radius: .5rem;
	}
	.rounded-10{
		border-radius: 1rem !important;
	}
	.rounded-20{
		border-radius: 2rem;
	}
	.rounded-start-0{
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	.rounded-end-0{
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.rounded-bottom-0{
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	.rounded-top-0{
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
	}
</style>
<template>
  <router-view @set-param="setParam" />
</template>
<script>
import { LAYER_TYPE,RESULT_TYPE,IS_DEBUG,DATA_TYPE,APP_DATA_TYPE } from "@/assets/js/config";
export default {
	data() {
		return {
			/**
             * 用户对象
             */
			user: {
				name:"",//用户名
				guid:"",
			},
		};
	},
	methods: {
		/**
		 * 设置参数
		 * @param {APP_DATA_TYPE} type 参数类型
		 */
		setParam(type,val){
			this[type]=val;
		},
		/**
		 * axios异步请求
		 * @param {Object} param 异步请求参数
		 * @param {Function} fn 异步请求成功的回调函数
		 */
		axiosRequest(param,fn){
			IS_DEBUG&&console.log("发送参数："+JSON.stringify(param));
			let promise=this.axios(param);
			promise.then((response)=>{
				IS_DEBUG&&console.log("接收参数："+JSON.stringify(response));
				switch (Number(response["r_val"])) {
					case RESULT_TYPE.success:
						fn&&fn(response);
						break;
					case RESULT_TYPE.defeat:
						this.showLayer({str:"请求失败！"});
						break;
					case RESULT_TYPE.sysErr:
						this.showLayer({str:"系统错误！"});
						break;
					case RESULT_TYPE.paramErr:
						this.showLayer({str:"参数错误！"});
						break;
					case RESULT_TYPE.userEmpty:
						this.showLayer({str:"用户不存在！"});
						break;
					case RESULT_TYPE.pwdErr:
						this.showLayer({str:"密码错误！"});
						break;
					case RESULT_TYPE.stateDisabled:
						this.showLayer({str:"用户状态被禁用！"});
						break;
					case RESULT_TYPE.countMax:
						this.showLayer({str:"登录过于频繁，请十分钟后再登录！"});
						break;
					case RESULT_TYPE.verifyErr:
						this.showLayer({str:"登录校验错误！"});
						break;
					case RESULT_TYPE.unNormal:
						this.showLayer({str:"非法访问！"});
						break;
					case RESULT_TYPE.unPower:
						this.showLayer({str:"没有访问权限！"});
						break;
					case RESULT_TYPE.userHave:
						this.showLayer({str:"用户已存在！"});
						break;
					case RESULT_TYPE.unDel:
						this.showLayer({str:"不允许删除！"});
						break;
					case RESULT_TYPE.roleHave:
						this.showLayer({str:"角色标识已存在！"});
						break;
					case RESULT_TYPE.oldPwdErr:
						this.showLayer({str:"原密码错误！"});
						break;
					default:
						break;
				}
			})
			return promise;
		},
		/**
		 * 请求数据交互
		 * @param {DATA_TYPE} type 接口名称
		 * @param {Function} fn 异步获取数据成功的回调函数
		 */
		requestData(type,params,fn){
			let obj={
				methods:'post',
				url:'',
				params:params||{}
			};
			obj.params.fun=type;//接口名称
			obj.params.encrystr="";//加密参数
			this.user.guid&&(obj.params.guid=this.user.guid);
			switch (type) {
				case DATA_TYPE.userlogin:
					obj.url='handler_login.ashx';
					break;
				case DATA_TYPE.getroles:
				case DATA_TYPE.addroles:
				case DATA_TYPE.delroles:
				case DATA_TYPE.updroles:
					obj.url='handler_role.ashx';
					break;
				case DATA_TYPE.getpowers:
					obj.url='handler_power.ashx';
					break;
				case DATA_TYPE.getdepart:
				case DATA_TYPE.adddepart:
				case DATA_TYPE.upddepart:
				case DATA_TYPE.deldepart:
					obj.url='handler_depart.ashx';
					break;
				case DATA_TYPE.getservices:
				case DATA_TYPE.addservices:
				case DATA_TYPE.updservices:
				case DATA_TYPE.delservices:
					obj.url='handler_services.ashx';
					break;
				case DATA_TYPE.getusers:
				case DATA_TYPE.adduser:
				case DATA_TYPE.upduser:
				case DATA_TYPE.getusersbyuserid:
				case DATA_TYPE.getuserdepart:
				case DATA_TYPE.setuserdepart:
					obj.url='handler_user.ashx';
					break;
				case DATA_TYPE.getpettype:
				case DATA_TYPE.addpettype:
				case DATA_TYPE.delpettype:
				case DATA_TYPE.updpettype:
				case DATA_TYPE.updpettypepetanimalid:
				case DATA_TYPE.getpetanimal:
				case DATA_TYPE.addpetanimal:
				case DATA_TYPE.delpetanimal:
				case DATA_TYPE.updpetanimal:
					obj.url='handler_pettype.ashx';
					break;
				case DATA_TYPE.getpets:
				case DATA_TYPE.addpets:
				case DATA_TYPE.delpets:
				case DATA_TYPE.updpets:
				case DATA_TYPE.getpetsbyid:
					obj.url='handler_pets.ashx';
					break;
				case DATA_TYPE.getproduct:
				case DATA_TYPE.delproduct:
				case DATA_TYPE.addproduct:
				case DATA_TYPE.updproduct:
				case DATA_TYPE.getproductbyid:
					obj.url='handler_product.ashx';
					break;
				case DATA_TYPE.getstock:
				case DATA_TYPE.getstockbyid:
				case DATA_TYPE.addstock:
				case DATA_TYPE.updstock:
					obj.url='handler_getstock.ashx';
					break;
				case DATA_TYPE.getreserve:
				case DATA_TYPE.getreservebyid:
				case DATA_TYPE.addreserve:
				case DATA_TYPE.updreserve:
					obj.url='handler_reserve.ashx';
					break;
				case DATA_TYPE.getparams:
				case DATA_TYPE.addparams:
				case DATA_TYPE.updparams:
				case DATA_TYPE.delparams:
					obj.url='handler_params.ashx';
					break;
				case DATA_TYPE.getorders:
				case DATA_TYPE.getordersbyid:
				case DATA_TYPE.addorders:
				case DATA_TYPE.updorders:
					obj.url='handler_orders.ashx';
					break;
				default:
					throw new Error("必须在App.vue中注册"+type+"的事件处理");
			}
			return this.axiosRequest(obj,fn);
		},
		/**
		 * 路由跳转
		 * @param {String} name 路由名称
		 * @param {Object} params 路由传递参数
		 */
		linkTo(name,params,state){
			params=params||{};
			state=state||{};
			this.$router.push({
				name,
				params,
				state
			})
		},
	},
}
</script>
