<style scoped>
    .list-group-item{
        background-color: #e1f1ff;
        border: 0;
    }
</style>
<template>
    <div class="d-flex flex-column bg-light py-2 px-3">
        <div class="d-flex justify-content-between align-items-center mb-2 mt-1">
            <span class="fw-bold">待处理业务</span>
            <span class="text-secondary small" role="button">查看全部 ></span>
        </div>
        <div class="d-flex flex-column small">
            <div class="d-flex bg-info bg-opacity-25 rounded py-2 px-3 mb-2" role="button">
                <span class="pe-3 text-danger fw-bold">急</span>
                <span class="flex-grow-1">小泰迪的遛狗时间到啦。。。。</span>
                <span class="ps-3">05-22</span>
            </div>
            <div class="d-flex bg-info bg-opacity-25 rounded py-2 px-3 mb-2" role="button">
                <span class="pe-3 text-danger fw-bold">急</span>
                <span class="flex-grow-1">喂养小猫咪啦</span>
                <span class="ps-3">05-22</span>
            </div>
            <div class="d-flex bg-info bg-opacity-25 rounded py-2 px-3 mb-2" role="button">
                <span class="pe-3 text-danger fw-bold">急</span>
                <span class="flex-grow-1">要带小狗狗打针了</span>
                <span class="ps-3">05-22</span>
            </div>
        </div>
    </div>
</template>