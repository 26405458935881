<style scoped>
    .lbl-title{
        font-weight: bold;
    }
    .lbl-data{
        display: block;width: 100%;line-height: 1.5;border-width: 1px 0;font-size: smaller;
    }
    .info-name{line-height: 30px;font-size: 14px;font-weight: bold;}
    .info-data{font-weight: normal;}
</style>
<template>
    <section class="row">
        <div class="col-md-4 col-lg-3">
            <div class="card">
                <div class="row" style="height:150px;margin: 0 auto;">
                    <img class="img-thumbnail mt-2" style="width: auto; height:100%" src="@/assets/images/upload/dog1.jpg" alt="">
                </div>
                <div style="text-align: center;">
                    <label>{{this.Pet.petName}}</label><br/>
                </div>
                <hr/>
                <div class="card-body info">
                    <span class="info-name">宠物编号： <label class="info-data" for="">{{ this.Pet.petId }}</label></span><br/>
                    <span class="info-name">性别： <label class="info-data">{{ this.Pet.sex==1?"男": this.Pet.sex==2?"女":"未填写"}}</label></span><br/>
                    <span class="info-name">类型：  <label class="info-data" for="">{{ this.Pet.petTypeName }}</label></span><br/>
                    <span class="info-name">品种： <label class="info-data">{{ this.Pet.petAnimalName }}</label></span><br/>
                    <span class="info-name">颜色 : <label class="info-data">{{this.Pet.color}}</label></span><br/>
                    <span class="info-name">体重 : <label class="info-data">{{ this.Pet.weight }}</label></span><br/>
                    <span class="info-name">其他信息： <label class="info-data">{{ this.Pet.memo }}</label></span>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                
                <nav class="navbar navbar-expand-sm  bg-info">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">宠物主人信息</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-white" href="#">预约历史记录</a>
                        </li>
                    </ul>
                </nav>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="userId" class="form-label lbl-title">会员ID</label>
                            <label class="lbl-data">{{ this.User.userId }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">姓名</label>
                            <label class="lbl-data">{{ this.User.userName }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">性别</label>
                            <label class="lbl-data">{{ this.User.sexid==1?"男":"女" }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="phone" class="form-label lbl-title">联系电话</label>
                            <label class="lbl-data">{{ this.User.phone }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="birthday" class="form-label lbl-title">生日</label>
                            <label class="lbl-data">{{ this.User.birthday }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">vip等级</label>
                            <label class="lbl-data">{{ this.User.vipLevel }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="idCard" class="form-label lbl-title">余额存款</label><br/>
                            <label class="lbl-data">{{ this.User.balance }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="idCard" class="form-label lbl-title">消费总额</label><br/>
                            <label class="lbl-data">{{ this.User.useAmount }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">地址</label>
                            <label class="lbl-data">{{ this.User.address }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">电子邮箱</label>
                            <label class="lbl-data">{{ this.User.email }}</label>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                            <label for="email" class="form-label lbl-title">其他信息</label>
                            <label class="lbl-data">{{ this.User.memo }}</label>
                        </div>
                    </div>
                </div>
                <table-list></table-list>
                <div class="card-body">
                    <div class="row">

                    </div>
                </div>
            </div>
            <!-- <div class="card-footer">
                <pagination></pagination>
            </div> -->
        </div>
    </section>
</template>
<script>
    import tableList from "@/components/public/table.vue";
    import pagination from "@/components/public/pagination.vue";
    import { LAYER_TYPE,STATE_TYPE,DATA_TYPE,OPERATE_TYPE } from "@/assets/js/config";
    export default {
        props:{
            user:{
                type:Object,
                default:{
                    name:"",
                    guid:"",
                }
            }
        },
        data(){
            return{
                User:{//指定用户详细信息
                    userId:"",
                    userName:"",
                    sexid:"",
                    phone:"",
                    birthday:"",
                    useAmount:0,
                    vipLevel:0,
                    balance:0,
                    email:"",
                    address:"",
                    status:1,
                    creater:"",
                    memo:"",
                },
                Pet:{//指定宠物信息
                    petId:0,
                    petName:"",
                    petTypeId:0,
                    petTypeName:"",
                    petAnimalId:0,
                    petAnimalName:"",
                    sex:0,
                    color:"",
                    weight:0,
                    memo:"",
                }
            }
        },
        created() {
            let userId=this.$route.params.userId;
            let petId=this.$route.params.petId;
            if(userId){
                this.getUsers(userId);
                this.getPetinfo(petId);
            }
        },
        methods(){},
        methods:{
             /**
             * 获取指定用户信息（点击用户ID进入页面进行编辑修改，赋值该账号信息）
             */
            getUsers(itemId){
                this.$root.requestData(DATA_TYPE.getusersbyuserid,{
                    userid:itemId
                },(data)=>{
                    this.User.userId=data.list[0].UserID;
                    this.User.userName=data.list[0].UserName;
                    this.User.sexid=data.list[0].Sex;
                    this.User.birthday=data.list[0].Birthday;
                    this.User.phone=data.list[0].Phone;
                    this.User.address=data.list[0].Address;
                    this.User.email=data.list[0].Email;
                    this.User.status=data.list[0].Status;
                    this.User.creater=data.list[0].Creater;
                    this.User.memo=data.list[0].Memo;

                })
            },
            /**
             * 获取指定用户信息（点击用户ID进入页面进行编辑修改，赋值该账号信息）
             */
             getPetinfo(itemId){
                this.$root.requestData(DATA_TYPE.getpetsbyid,{
                    petid:itemId
                },(data)=>{
                    this.Pet.petId=data.list[0].PetID;
                    this.Pet.petName=data.list[0].PetName;
                    this.Pet.sex=data.list[0].Sex;
                    this.Pet.petTypeName=data.list[0].PetTypeName;
                    this.Pet.petAnimalName=data.list[0].PetAnimalName;
                    this.Pet.color=data.list[0].Color;
                    this.Pet.weight=data.list[0].Weight;
                    this.Pet.memo=data.list[0].Memo;

                })
            },
        },
        components:{
            tableList,
            pagination
        },
        
    }
</script>