<!-- 角色权限管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">角色权限管理</div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <div class="d-flex flex-grow-1 h-0">

                        <!-- 角色管理 -->
                        <div class="flex-fill pe-2">
                            <div class="card h-100">
                                <div class="card-header bg-primary text-light">
                                    <div class="d-flex justify-content-between">
                                        <span>角色管理</span>
                                        <div @click="btnAddRoleClick" role="button">
                                            <i v-show="!isShowAddRole" class="bi bi-person-plus-fill me-1"></i>
                                            <span>{{isShowAddRole?'取消':'创建角色'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>角色名称</th>
                                                        <th>状态</th>
                                                        <th>操作</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- 新增栏 -->
                                                    <tr v-show="isShowAddRole">
                                                        <td></td>
                                                        <td>{{roleList.length+1}}</td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="role.name" class="form-control form-control-sm" ref="txtAddRole" placeholder="角色名称">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <select class="form-select form-select-sm" v-model="role.state" name="state">
                                                                        <option :value="stateType.on" selected>启用</option>
                                                                        <option :value="stateType.off">禁用</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <i class="bi bi-save me-2 text-success" @click="setRole(operateType.insert)" role="button" title="保存"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- 列表栏 -->
                                                    <tr v-for="(item,i) of roleList" :key="item.RoleID" @click="roleClick(item)">
                                                        <td :class="[item.RoleID==currChooseRole.RoleID?'text-success':'text-muted']">
                                                            <i :class="[item.RoleID==currChooseRole.RoleID?'bi-check-circle-fill':'bi-check-circle']" class="bi"></i>
                                                        </td>
                                                        <td :class="{'text-muted':item.RoleID!=currChooseRole.RoleID}">{{i+1}}</td>
                                                        <td v-if="item.RoleID==currChooseRole.RoleID">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="role.name" ref="txtUpdateRole" class="form-control form-control-sm" placeholder="角色名称">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td v-else :class="{'text-muted':item.RoleID!=currChooseRole.RoleID}">{{item.RoleName}}</td>
                                                        <td v-if="item.RoleID==currChooseRole.RoleID">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <select class="form-select form-select-sm" v-model="role.state" name="currState">
                                                                        <option :value="stateType.on">启用</option>
                                                                        <option :value="stateType.off">禁用</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td v-else :class="{'text-muted':item.RoleID!=currChooseRole.RoleID}">{{item.Isenable==stateType.on?"启用":item.Isenable==stateType.off?"禁用":"状态异常"}}</td>
                                                        <td>
                                                            <i class="bi bi-x-circle-fill text-danger me-2" @click.stop="setRole(operateType.delete,item)" role="button" title="删除"></i>
                                                            <i v-if="item.RoleID==currChooseRole.RoleID" class="bi bi-save text-success" @click.stop="setRole(operateType.update,item)" role="button" title="保存"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 权限管理 -->
                        <div class="flex-fill ps-2">
                            <div class="card h-100">
                                <div class="card-header bg-secondary text-light">权限配置</div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>权限名称</th>
                                                        <th>权限详情</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item,i) in powerList" :key="item.ID" @click="powerClick(item)">
                                                        <td :class="[currChoosePowerList.find(v=>v.ID==item.ID)?'text-success':'text-muted']">
                                                            <i :class="[currChoosePowerList.find(v=>v.ID==item.ID)?'bi-check-square-fill':'bi-check-square']" class="bi"></i>
                                                        </td>
                                                        <td :class="{'text-muted':!currChoosePowerList.find(v=>v.ID==item.ID)}">{{i+1}}</td>
                                                        <td :class="{'text-muted':!currChoosePowerList.find(v=>v.ID==item.ID)}">{{item.PowerName}}</td>
                                                        <td :class="{'text-muted':!currChoosePowerList.find(v=>v.ID==item.ID)}">{{item.Memo}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!-- 没有数据 -->
                                            <div v-if="powerList.length<=0" class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                                                <div class="bg-secondary bg-opacity-50 p-2 mb-2 rounded">
                                                    <i class="bi bi-file-earmark-text text-light"></i>
                                                </div>
                                                <span class="text-secondary text-opacity-50">暂无数据</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import pagination from "@/components/public/pagination.vue";
    import { DATA_TYPE,LAYER_TYPE,OPERATE_TYPE,STATE_TYPE } from "@/assets/js/config";
    export default {
        components:{
            pagination
        },
        data() {
            return {
                //获取配置项
                operateType:OPERATE_TYPE,//操作类型
                stateType:STATE_TYPE,//状态类型
                // 角色
                isShowAddRole: false,//是否显示添加角色
                roleList:[],//角色列表
                currChooseRole:{},//当前选中的角色
                roleName:"",//角色名称
                roleState:"",//角色状态
                role:{
                    name:"",//角色名称
                    state:"",//状态
                },//当前要修改的角色对象
                // 权限
                powerList:[],//权限列表
                currChoosePowerList:[],//当前选中的权限列表
            };
        },
        created() {
            this.roleState=this.stateType.on;
        },
        mounted() {
            // 获取角色列表
            this.$root.requestData(DATA_TYPE.getroles,null,(data)=>{
                this.roleList=data.list;
            })
            // 获取权限列表
            this.$root.requestData(DATA_TYPE.getpowers,null,(data)=>{
                this.powerList=data.list;
            })
        },
        methods: {
            /**
             * 角色增删改
             * @param {OPERATE_TYPE} type 操作类型
             */
            setRole(type,role) {
                let r={
                    rolename:this.role.name,
                    powerstring:String(this.currChoosePowerList.map(v=>v.PowerKey)),
                    isenable:this.role.state
                }
                if(this.role.name.trim()==""&&type!=OPERATE_TYPE.delete){
                    this.showLayer({str:"角色名称不能为空！"});
                    this.$refs.txtAddRole.focus();
                    this.$refs.txtUpdateRole[0].focus();
                }else{
                    switch (type) {
                        case OPERATE_TYPE.insert:
                            r.rolekey=this.role.name;
                            this.$root.requestData(DATA_TYPE.addroles,r,()=>{
                                // 获取角色列表
                                this.$root.requestData(DATA_TYPE.getroles,null,(data)=>{
                                    this.roleList=data.list;
                                })
                                this.role.name="";
                                this.role.state=STATE_TYPE.on;
                                this.isShowAddRole=false;
                                this.$root.showLayer({str:"保存成功！"});
                            })
                            break;
                        case OPERATE_TYPE.delete:
                            this.showLayer({
                                type:LAYER_TYPE.confirm,
                                str:"是否确认删除角色【"+role.RoleName+"】？",
                                btn:["确认","取消"],
                                yesFn:(i)=>{
                                    layer.close(i);
                                    this.$root.requestData(DATA_TYPE.delroles,{
                                        roleid:role.RoleID
                                    },()=>{
                                        role.RoleID==this.currChooseRole.RoleID&&(this.currChoosePowerList=[]);
                                        this.roleList.splice(this.roleList.findIndex(v=>v.RoleID==role.RoleID),1);
                                        this.showLayer({str:"删除成功！"});
                                    })
                                }
                            })
                            break;
                        case OPERATE_TYPE.update:
                            r.roleid=role.RoleID;
                            this.$root.requestData(DATA_TYPE.updroles,r,()=>{
                                role.RoleName=this.role.name;
                                role.Isenable=this.role.state;
                                this.roleList.find(v=>v.RoleID==this.currChooseRole.RoleID).Powerstring=r.powerstring;
                                this.currChooseRole={};
                                this.currChoosePowerList=[];
                                this.showLayer({str:"保存成功！"});
                            })
                            break;
                        default:
                            throw new Error("数据类型应为OPERATE_TYPE类型的值");
                    }
                }
            },
            /**
             * 选中角色
             * @param {Object} role 角色
             */
            roleClick(role){
                if(role.RoleID!=this.currChooseRole.RoleID){
                    this.isShowAddRole=false;
                    this.currChooseRole=role;
                    this.role.name=role.RoleName;
                    this.role.state=role.Isenable;
                    let arr=role.Powerstring.split(',');
                    this.currChoosePowerList=[];
                    for (let i = 0; i < arr.length; i++) {
                        let p = this.powerList.find(v=>v.PowerKey==arr[i]);
                        p&&this.currChoosePowerList.push(p);
                    }
                    this.$nextTick(()=>{
                        this.$refs.txtUpdateRole[0].focus();
                    })
                }
            },
            /**
             * 选中权限
             */
            powerClick(power){
                if(this.currChooseRole.RoleID||this.isShowAddRole){//有选中宠物种类或者新增种类时，点击才生效
                    let index=this.currChoosePowerList.findIndex(v=>v.ID==power.ID);//获取点击项的下标
                    if(index!=-1){//选中的列表中已有当前点击的权限
                        this.currChoosePowerList.splice(index,1);//移除
                    }else{
                        this.currChoosePowerList.push(power);//加入
                    }
                }else{
                    this.showLayer({str:"请选中一个角色进行配置"});
                }
            },
            /**
             * 点击创建角色或取消
             */
            btnAddRoleClick(){
                this.isShowAddRole=!this.isShowAddRole;
                if(this.isShowAddRole){
                    this.currChooseRole={},
                    this.currChoosePowerList=[];
                    this.role.name="";
                    this.role.state=STATE_TYPE.on;
                    this.$nextTick(()=>{
                        this.$refs.txtAddRole.focus();
                    })
                }
            }
        },
    }
</script>