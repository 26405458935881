<!-- 预约管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>预约管理</span>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <div class="flex-grow-1 h-0 overflow-scroll">

                        <form>
                            <div class="row">
                                <div class="col-12">
                                    <label for="user" class="form-label">预约时段设置</label>
                                    <small class="ms-2 link-primary" role="button" @click="showAddTimeslice">{{ isShowAddTimeslice?'取消':'添加' }}</small>
                                    <div class="d-flex flex-wrap border rounded px-2 pb-2">
                                        
                                        <!-- 时段项 -->
                                        <div v-for="item of timesliceList" :key="item" class="d-flex border rounded me-2 mt-2 p-2 bg-secondary bg-opacity-25">
                                            <!-- 内容 -->
                                            <div class="d-flex flex-column small me-2 justify-content-between" @click="timesliceClick(item)">
                                                <span :class="[(item['ID']&&timeslice.id!=item['ID'])?'py-1':'pt-1']" class="d-flex">
                                                    <i class="bi bi-clock me-1"></i>
                                                    <!-- 文本显示 -->
                                                    <span v-if="item['ID']&&timeslice.id!=item['ID']">{{item['ParamText']}}</span>
                                                    <!-- 输入框显示 -->
                                                    <div v-else class="d-flex">
                                                        <!-- 修改显示 -->
                                                        <select v-if="item['ID']" class="form-select form-select-sm py-0" v-model="timeslice.paramint">
                                                            <option v-for="i of 24" :key="i" :value="i-1">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                        </select>
                                                        <!-- 新增显示 -->
                                                        <select v-else class="form-select form-select-sm py-0" v-model="item['ParamInt']">
                                                            <option v-for="i of 24" :key="i" :value="i-1">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                        </select>
                                                        <i class="bi bi-dash"></i>
                                                        <!-- 修改显示 -->
                                                        <select v-if="item['ID']" class="form-select form-select-sm py-0" v-model="timeslice.paramdouble">
                                                            <option v-for="i of 24" :key="i" :value="i-1">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                        </select>
                                                        <!-- 新增显示 -->
                                                        <select v-else class="form-select form-select-sm py-0" v-model="item['ParamDouble']">
                                                            <option v-for="i of 24" :key="i" :value="i-1">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                        </select>
                                                    </div>
                                                </span>
                                                <span class="py-1 d-flex">
                                                    <i class="bi bi-people me-1"></i>
                                                    <!-- 文本显示 -->
                                                    <span v-if="item['ID']&&timeslice.id!=item['ID']">{{item['ParamString']}}</span>
                                                    <!-- 输入框显示 -->
                                                    <!-- 修改显示 -->
                                                    <input v-else-if="item['ID']" v-model.trim="timeslice.paramstring" class="form-control form-control-sm py-0 min-h-auto w-0 flex-grow-1" type="number">
                                                    <!-- 新增显示 -->
                                                    <input v-else v-model.trim="item['ParamString']" class="form-control form-control-sm py-0 min-h-auto w-0 flex-grow-1" type="number">
                                                </span>
                                            </div>
                                            <!-- 操作 -->
                                            <div class="d-flex flex-column">
                                                <i class="bi bi-x-circle-fill d-flex align-items-center flex-fill text-danger" @click="setTimeslice(operateType.delete,item)" title="删除" role="button"></i>
                                                <i v-if="!item['ID']||timeslice.id==item['ID']" class="bi bi-check-circle-fill d-flex align-items-center flex-fill text-success" @click="setTimeslice(operateType.insert,item)" title="保存" role="button"></i>
                                            </div>
                                        </div>

                                        <!-- 添加时间段 -->
                                        <!-- <div :class="[isShowAddTimeslice?'d-flex':'d-none']" class="border rounded me-2 mt-2 p-2">
                                            <div class="d-flex flex-column small me-2 justify-content-between">
                                                <span class="pb-1 d-flex">
                                                    <i class="bi bi-clock me-1"></i>
                                                    <select class="form-select form-select-sm py-0">
                                                        <option v-for="i of 24" :key="i" value="i">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                    </select>
                                                    <i class="bi bi-dash"></i>
                                                    <select class="form-select form-select-sm py-0">
                                                        <option v-for="i of 24" :key="i" value="i">{{ (i<11?'0':'')+(i-1)+':00' }}</option>
                                                    </select>
                                                </span>
                                                <span class="pt-1 d-flex">
                                                    <i class="bi bi-people me-1"></i>
                                                    <input class="form-control form-control-sm py-0 min-h-auto w-0 flex-grow-1" type="number">
                                                </span>
                                            </div>
                                            <div class="d-flex flex-column">
                                                <i class="bi bi-x-circle-fill d-flex align-items-center flex-fill text-danger" title="删除" role="button"></i>
                                                <i class="bi bi-check-circle-fill d-flex align-items-center flex-fill text-success" title="保存" role="button"></i>
                                            </div>
                                        </div> -->

                                        <!-- <div v-for="service of isEditService?serviceTypeList:getPowerListByUser" :key="service" class="d-flex flex-wrap small">
                                            <span :class="[!isEditService?'bg-secondary':(getPowerListByUser.find(v=>v.ID==power.ID)?'btn-primary':'')]" @click="powerClick(power.ID)" :role="isEditPower?'button':''" class="border rounded px-2 me-1 mt-1 bg-opacity-25">{{ power.PowerName }}</span>
                                        </div> -->
                                        <div class="small mt-2" v-if="timesliceList.length==0">未设置</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { DATA_TYPE,PARAMS_KEY,OPERATE_TYPE,LAYER_TYPE } from "@/assets/js/config";
export default {
    data() {
        return {
            //获取配置项
            operateType:OPERATE_TYPE,//操作类型

            timesliceList:[],//时间片段列表
            isShowAddTimeslice:false,//是否显示添加时间片段
            timeslice:{//要修改的时间片段对象
                id:"",
                paramkey:PARAMS_KEY.timeslice,
                depict:"",
                paramtext:"",
                paramint:0,
                paramdouble:0,
                paramstring:""
            }
        };
    },
    mounted() {
        this.getTimeslice();
    },
    methods: {
        /**
         * 获取预约时段设置
         */
        getTimeslice(){
            this.$root.requestData(DATA_TYPE.getparams,{
                pageindex:1,
                pagesize:24,
                paramkey:PARAMS_KEY.timeslice
            },(data)=>{
                this.timesliceList=data.list;
            })
        },
        /**
         * 显示添加预约时段
         */
        showAddTimeslice(){
            if(this.isShowAddTimeslice){//当前为显示
                this.isShowAddTimeslice=false;
                this.timesliceList.pop();
            }else{//当前为不显示
                this.isShowAddTimeslice=true;
                this.timesliceList.push({
                    ID:null,
                    ParamKey:PARAMS_KEY.timeslice,
                    Depict:"预约时段配置",
                    ParamText:"",//时间段
                    ParamInt:0,//时间段起始时间
                    ParamDouble:0,//时间段结束时间
                    ParamString:"",//人数

                })
            }
        },
        /**
         * 预约时段增删改
         * @param {OPERATE_TYPE} type 操作类型
         */
        setTimeslice(type,timeslice){
            if(type==OPERATE_TYPE.delete){//删除
                if(timeslice["ID"]){//已有的数据项
                    this.showLayer({
                        type:LAYER_TYPE.confirm,
                        str:"是否确认删除预约时段【"+timeslice.ParamText+"】？",
                        btn:["确认","取消"],
                        yesFn:(i)=>{
                            layer.close(i);
                            this.$root.requestData(DATA_TYPE.delparams,{
                                id:timeslice.ID
                            },()=>{
                                this.timesliceList.splice(this.timesliceList.findIndex(v=>v.ID==timeslice.ID),1);
                                this.showLayer({str:"删除成功！"});
                            })
                        }
                    })
                }else{
                    this.showAddTimeslice();
                }
            }else{
                let operate=OPERATE_TYPE.insert;
                timeslice["ID"]&&(operate=OPERATE_TYPE.update);
                let obj={
                    paramkey:PARAMS_KEY.timeslice,
                    depict:operate==OPERATE_TYPE.insert?timeslice["Depict"]:this.timeslice.depict,
                    paramtext:operate==OPERATE_TYPE.insert?
                        ((timeslice["ParamInt"]<10?'0':'')+timeslice["ParamInt"]+":00")+"-"+((timeslice["ParamDouble"]<10?'0':'')+timeslice["ParamDouble"]+":00"):
                        ((this.timeslice.paramint<10?'0':'')+this.timeslice.paramint+":00")+"-"+((this.timeslice.paramdouble<10?'0':'')+this.timeslice.paramdouble+":00"),
                    paramint:operate==OPERATE_TYPE.insert?timeslice["ParamInt"]:this.timeslice.paramint,
                    paramdouble:operate==OPERATE_TYPE.insert?timeslice["ParamDouble"]:this.timeslice.paramdouble,
                    paramstring:operate==OPERATE_TYPE.insert?timeslice["ParamString"]:this.timeslice.paramstring
                }
                if(!obj.paramstring){
                    this.showLayer({str:"可预约人数为必填项！"});
                    return;
                }
                if(timeslice["ID"]){//修改
                    obj.id=this.timeslice.id;
                    this.$root.requestData(DATA_TYPE.updparams,obj,(data)=>{
                        timeslice["ParamText"]=obj.paramtext;
                        timeslice["ParamInt"]=this.timeslice.paramint;
                        timeslice["ParamDouble"]=this.timeslice.paramdouble;
                        timeslice["ParamString"]=this.timeslice.paramstring;
                        this.timeslice.id="";
                        this.timeslice.paramtext="";
                        this.timeslice.paramint=0;
                        this.timeslice.paramdouble=0;
                        this.timeslice.paramstring="";
                        this.showLayer({str:"保存成功！"});
                    })
                }else{//新增
                    this.$root.requestData(DATA_TYPE.addparams,obj,(data)=>{
                        this.showAddTimeslice();
                        this.getTimeslice();
                        this.showLayer({str:"保存成功！"});
                    })
                }
            }
        },
        /**
         * 点击预约时段
         */
        timesliceClick(timeslice){
            if(timeslice.ID!=this.timeslice.id&&timeslice.ID){//选中了不同于当前的项
                this.timeslice.id=timeslice.ID;
                this.timeslice.paramkey=timeslice.ParamKey;
                this.timeslice.depict=timeslice.Depict;
                this.timeslice.paramtext=timeslice.ParamText;
                this.timeslice.paramint=timeslice.ParamInt;
                this.timeslice.paramdouble=timeslice.ParamDouble;
                this.timeslice.paramstring=timeslice.ParamString;
            }
        }
    },
};
</script>