<!-- 物料信息页 -->
<style scoped>
    
</style>
<template>
    <section class="h-100">
        <div class="card h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>物料信息</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="h-100">
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="productName" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>物料名称
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('productName')}" class="form-control" id="productName" placeholder="物料名称" v-model="productName">
                                <div class="invalid-tooltip">{{ userDataValid("productName") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="brandName" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>品牌名
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('brandName')}" class="form-control" id="brandName" placeholder="品牌名" v-model="brandName">
                                <div class="invalid-tooltip">{{ userDataValid("brandName") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="unitName" class="form-label">单位名称</label>
                                <input class="form-control" id="unitName" placeholder="单位名称" v-model="unitName">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="price" class="form-label">价格</label>
                                <input class="form-control" id="price" placeholder="价格" v-model="price">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="stock" class="form-label">库存</label>
                                <input class="form-control" id="stock" placeholder="库存" v-model="stock">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="memo" class="form-label">产品说明</label>
                                <input class="form-control" id="memo" placeholder="产品说明" v-model="memo">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="isenable" class="form-label">是否启用</label>
                                <select class="form-select" id="isenable" name="isenable" v-model="isenable">
                                    <option :value="state_type.on">启用</option>
                                    <option :value="state_type.off">禁用</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="creater" class="form-label">创建人</label>
                                <input class="form-control" id="creater" disabled :placeholder="creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="createTime" class="form-label">创建时间</label>
                                <input class="form-control" id="createTime" disabled :placeholder="createTime">
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import { LAYER_TYPE,RESULT_TYPE,IS_DEBUG,DATA_TYPE,APP_DATA_TYPE,OPERATE_TYPE,STATE_TYPE,PRODUCT_TYPE } from "@/assets/js/config";
import common from "@/assets/js/common";
export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        }
    },
    data(){
        return {
            state_type:STATE_TYPE,//获取状态配置
            funParams:"",
            isVerifyEmpty:false,//是否进行非空验证
            //物料字段
            productId:0,
            productName:"",
            brandName:"",
            unitName:"",
            price:0,
            stock:0,
            memo:"",
            isenable:1,
            creater:"",
            createTime:new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
            editModel:OPERATE_TYPE.insert,//当前编辑模式，默认为新增
        }
    },
    created(){
        let productid=this.$route.params.productId;
        this.creater=this.user.name;
        if(productid){//修改模式
            this.productId=productid;
            this.editModel=OPERATE_TYPE.update;
            this.getWareInfoById(productid);
        }
    },
    mounted(){

    },
    methods:{
        /**
         * 用户数据有效性验证
         */
         userDataValid(dataName){
            switch (dataName) {
                case "productName"://产品名称校验
                    if(!this.productName){
                        return "产品名称为必填项！";
                    }
                    break;
                case "brandName"://品牌名校验
                    if(!this.brandName){
                        return "品牌名为必填项！";
                    }
                    break;
                default:
                    break;
            }
            return null;
        },
        /**
         * 保存物料信息
         */
         save(){
            //验证未通过
            if(this.userDataValid("productName")||this.userDataValid("brandName")){
                return;
            }
            this.$root.requestData(this.editModel==OPERATE_TYPE.update?DATA_TYPE.updproduct:DATA_TYPE.addproduct,{
                productid:this.productId,
                productname:this.productName,
                serviceid:PRODUCT_TYPE.drug.id,
                brandname:this.brandName,
                unitname:this.unitName,
                price:this.price,
                stock:this.stock,
                isenable:this.isenable,//是否启用
                isreserve:0,//是否可预约,0代表否
                isstock:1,//是否实物，1代表是
                memo:this.memo,
            },async (data)=>{
                this.$root.linkTo("warelist",{result:true});
            })
        },
        /**获取物料信息 */
        getWareInfoById(productId){
            this.$root.requestData(DATA_TYPE.getproductbyid,{
                    productid:productId
                },(data)=>{
                    this.productName=data.list[0].ProductName;
                    this.brandName=data.list[0].BrandName;
                    this.unitName=data.list[0].UnitName;
                    this.price=data.list[0].Price;
                    this.stock=data.list[0].Stock;
                    this.isenable=data.list[0].Isenable;
                    this.memo=data.list[0].Memo;
                })
        }
    }
}
</script>