<!-- 科室管理页 -->
<style scoped>

</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>科室管理</span>
                    <div class="text-primary" role="button" @click="$root.linkTo('departmentinfo')">
                        <i class="bi bi-house-add-fill me-1"></i>
                        <span>开设科室</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="roleId" class="form-label">科室号</label>
                                <input class="form-control" id="roleId" placeholder="科室号">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
                                <label for="roleName" class="form-label">科室名称</label>
                                <input class="form-control" id="roleName" placeholder="科室名称">
                            </div>
                        </div>
                    </form>
                    <div class="flex-grow-1 h-0 overflow-scroll">
                        <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                            <thead class="bg-primary bg-gradient text-light">
                                <tr>
                                    <th>编号</th>
                                    <th>科室号</th>
                                    <th>科室名称</th>
                                    <th>位置</th>
                                    <th>状态</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,i) of departList" :key="item.DepartID">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('departmentinfo',{depart:JSON.stringify(item)})" role="button">{{item.DepartCode}}</span>
                                    </td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('departmentinfo',{depart:JSON.stringify(item)})" role="button">{{item.DepartName}}</span>
                                    </td>
                                    <td>{{item.Posi}}</td>
                                    <td>{{item.Status==stateType.on?"启用":item.Status==stateType.off?"禁用":"状态异常"}}</td>
                                    <td>
                                        <i class="bi bi-x-circle-fill text-danger me-2" @click.stop="delDepartment(item)" role="button" title="删除"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
    import pagination from "@/components/public/pagination.vue";
    import { DATA_TYPE,LAYER_TYPE,STATE_TYPE } from "@/assets/js/config";
    export default{
        components:{
            pagination
        },
        props: {
            pagesize:0,//每页显示数据条数
        },
        data() {
            return {
                stateType:STATE_TYPE,//状态类型
                departList: [],//科室列表
            };
        },
        mounted() {
            if(this.$route.params.result){
                this.showLayer({str:"保存成功！"});
            }
            // 获取科室列表
            this.$root.requestData(DATA_TYPE.getdepart,{
                pageindex:1,
                pagesize:this.pagesize
            },(data)=>{
                this.departList=data.list;
            })
        },
        methods: {
            /**
             * 删除科室
             */
            delDepartment(depart) {
                this.showLayer({
                    type:LAYER_TYPE.confirm,
                    str:"是否确认删除科室【"+depart.DepartName+"】",
                    btn:["确认","取消"],
                    yesFn:(i)=>{
                        this.$root.requestData(DATA_TYPE.deldepart,{
                            departid:depart.DepartID
                        },()=>{
                            this.departList.splice(this.departList.findIndex(v=>v.DepartID==depart.DepartID),1);
                            this.showLayer({str:"删除成功！"});
                        })
                    }
                })
            },
        },
    }
</script>