// 宠物小屋页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">查找宠物小屋信息</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="userId" class="form-label">客户ID</label>
                            <input class="form-control" id="userId" placeholder="客户ID">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="userName" class="form-label">客户姓名</label>
                            <input class="form-control" id="userName" placeholder="客户姓名">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="petId" class="form-label">宠物ID</label>
                            <input class="form-control" id="petId" placeholder="宠物ID">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="petName" class="form-label">宠物爱称</label>
                            <input class="form-control" id="petName" placeholder="宠物爱称">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="opdDate" class="form-label">日期</label>
                            <input class="form-control" type="datetime-local" id="opdDate" placeholder="日期">
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="doctor" class="form-label">负责人</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>布鲁斯李</option>
                                <option>魏教授</option>
                                <option>张医师</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="disease" class="form-label">症状</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>呼吸困难</option>
                                <option>腹泻</option>
                                <option>呕吐</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="petType" class="form-label">宠物种类</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>猫</option>
                                <option>狗</option>
                                <option>猪</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="organize" class="form-label">分支机构</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>总公司</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="condition" class="form-label">宠物状况</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>未指定</option>
                                <option>痊愈</option>
                                <option>死亡</option>
                            </select>
                        </div>
                    </div>
                </form>
                <tableList></tableList>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>