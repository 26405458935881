const IS_DEBUG=true;//是否开启调试模式以显示输出
/**
 * 登录类型
 */
const LOGIN_TYPE={
    pwd:1,//密码登录
    code:2,//验证码登录
    register:3,//注册登录
}
/**
 * 弹出框类型
 */
const LAYER_TYPE={
    info:0,//提示框
    confirm:1,//确认框
    load:2,//加载层
}
/**
 * 服务端返回结果类型
 */
const RESULT_TYPE={
    success:1,//成功
    defeat:2,//失败
    sysErr:3,//系统错误
    paramErr:4,//参数错误
    userEmpty:5,//用户不存在
    pwdErr:6,//密码错误
    stateDisabled:7,//状态被禁用
    countMax:8,//登录次数过多
    verifyErr:9,//登录校验错误
    unNormal:10,//非法访问
    unPower:11,//无权限访问
    userHave:12,//用户已存在
    unDel:13,//不允许删除
    roleHave:14,//角色标识已存在
    oldPwdErr:15,//原密码错误
}
/**
 * 路由类型
 */
const ROUTE_TYPE={
    firstMenu:1,//一级菜单
    secondMenu:2,//二级菜单
    fun:3,//功能
}
/**
 * 服务器数据类型
 */
const DATA_TYPE={
    userlogin:"userlogin",//登录
    
    getroles:"getroles",//获取角色列表
    addroles:"addroles",//新增角色
    delroles:"delroles",//删除角色
    updroles:"updroles",//修改角色
    getpowers:"getpowers",//获取权限列表
    getdepart:"getdepart",//获取科室列表
    adddepart:"adddepart",//新增科室
    upddepart:"upddepart",//修改科室
    deldepart:"deldepart",//删除科室
    getusers:"getusers",//获取用户列表
    upduser:"upduser",//修改用户
    adduser:"adduser",//新增用户
    getuserdepart:"getuserdepart",//获取用户科室列表
    setuserdepart:"setuserdepart",//设置用户科室列表
    getservices:"getservices",//获取服务列表
    addservices:"addservices",//新增服务
    updservices:"updservices",//修改服务
    delservices:"delservices",//删除服务
    getusersbyuserid:"getusersbyuserid",//获取指定用户信息
    getpettype:"getpettype",//获取宠物品种列表
    addpettype:"addpettype",//新增宠物品种
    delpettype:"delpettype",//删除宠物品种
    updpettype:"updpettype",//修改宠物品种
    updpettypepetanimalid:"updpettypepetanimalid",//批量修改宠物品种的宠物种类id
    getpetanimal:"getpetanimal",//获取宠物种类列表
    addpetanimal:"addpetanimal",//新增宠物种类
    delpetanimal:"delpetanimal",//删除宠物种类
    updpetanimal:"updpetanimal",//修改宠物种类
    getpets:"getpets",//获取宠物列表
    addpets:"addpets",//新增宠物
    delpets:"delpets",//删除宠物
    updpets:"updpets",//修改宠物
    getpetsbyid:"getpetsbyid",//获取宠物详细信息
    getproduct:"getproduct",//获取产品列表
    delproduct:"delproduct",//删除产品
    updproduct:"updproduct",//修改产品
    addproduct:"addproduct",//新增产品
    getproductbyid:"getproductbyid",//获取产品详细信息
    getstock:"getstock",//获取入库列表
    getstockbyid:"getstockbyid",//获取入库详细信息
    addstock:"addstock",//新增入库
    updstock:"updstock",//修改入库
    getreserve:"getreserve",//获取预约列表
    getreservebyid:"getreservebyid",//获取预约详情
    addreserve:"addreserve",//新增预约
    updreserve:"updreserve",//修改预约
    getparams:"getparams",//获取参数配置
    addparams:"addparams",//新增参数配置
    updparams:"updparams",//修改参数配置
    delparams:"delparams",//删除参数配置
    getorders:"getorders",//获取订单列表
    getordersbyid:"getordersbyid",//获取订单详细信息
    addorders:"addorders",//新增订单
    updorders:"updorders",//修改订单

}
/**
 * app.vue中要设置的数据类型
 */
const APP_DATA_TYPE={
    user:"user",//用户对象
}
/**
 * 操作类型
 */
const OPERATE_TYPE={
    insert:1,//增加
    delete:2,//删除
    update:3,//修改
}
/**
 * 状态类型
 */
const STATE_TYPE={
    off:0,//禁用
    on:1,//启用
}
/**
 * 状态类型名称
 */
const STATE_NAME={
    0:"禁用",
    1:"启用"
}
/**
 * 性别类型
 */
const SEX_TYPE={
    female:0,//女
    male:1,//男
}
/**
 * 宠物性别
 */
const PET_SEX_TYPE={
    unset:0,//未设置
    male:1,//公
    female:2,//母
}
/**
 * 绝育类型
 */
const BEAR_TYPE={
    unset:0,//未设置
    fertility:1,//未绝育
    infertility:2//已绝育
}
/**
 * 是否员工
 */
const IS_WORKER={
    false:0,
    true:1,
}
/**
 * 参数表key值
 */
const PARAMS_KEY={
    timeslice:"timeslice",//时间段
    
}
/**
 * 产品类型
 */
const PRODUCT_TYPE={
    service:{
        id:1,
        name:"服务"
    },
    feed:{
        id:2,
        name:"饲料"
    },
    drug:{
        id:3,
        name:"药品"
    },
    medicalItems:{
        id:4,
        name:"医用物品"
    },
    device:{
        id:5,
        name:"设备"
    },
    price:{
        id:6,
        name:"价格标准"
    },
    menu:{
        id:7,
        name:"菜单"
    }
}
/**
 * 预约状态
 */
const APPOINT_STATE={
    appoinit:0,//预约
    store:1,//到店（未处理）
    complete:2,//完成
    cancel:3,//取消
}
/**
 * 订单状态
 */
 const ORDERS_STATE={
    unpaid:0,//未付款
    paid:1,//已付款(待取药)
    //nodispensary:2,//待取药
    complete:2,//完成
}
export{
    IS_DEBUG,LOGIN_TYPE,LAYER_TYPE,RESULT_TYPE,
    ROUTE_TYPE,DATA_TYPE,APP_DATA_TYPE,OPERATE_TYPE,
    STATE_TYPE,SEX_TYPE,IS_WORKER,PET_SEX_TYPE,BEAR_TYPE,
    STATE_NAME,PARAMS_KEY,PRODUCT_TYPE,APPOINT_STATE,ORDERS_STATE
}