<style scoped>
    /* .card{background-color: #f1f1f1 !important;} */
    .bi-gender-male::before,.bi-gender-female::before{
        font-weight: bold !important;
    }
</style>
<!-- 用户管理页 -->
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>用户资料</span>
                    <div class="text-primary" @click="$root.linkTo('userinfo')" role="button">
                        <i class="bi bi-person-fill-add me-1"></i>
                        <span>开户</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="userId" class="form-label">用户账号</label>
                                <input class="form-control" id="userId" v-model="searchUserID" placeholder="用户账号">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="userName" class="form-label">用户姓名</label>
                                <input class="form-control" id="userName" v-model="searchUserName" placeholder="用户姓名">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="phone" class="form-label">联系电话</label>
                                <input class="form-control" id="phone" v-model="searchPhone" placeholder="联系电话">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="usertype" class="form-label">用户类型</label>
                                <select id="usertype" class="form-select" v-model="searchUserType">
                                    <option value="">全部</option>
                                    <option :value="isWorker.true">员工</option>
                                    <option :value="isWorker.false">客户</option>
                                </select>
                            </div>
                            <div v-if="isChoose" class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <button type="button" class="btn btn-primary bg-gradient mt-3" @click="getRadioChecked()">确认选择</button>
                            </div>
                        </div>
                    </form>
                    <div class="flex-grow-1 h-0 overflow-scroll">
                        <table class="table table-hover table-striped table-borderless table-row-fixed mb-0 align-middle">
                            <thead class="bg-primary bg-gradient text-light">
                                <tr>
                                    <th v-if="isChoose"></th>
                                    <th>用户账号</th>
                                    <th>用户姓名</th>
                                    <th>性别</th>
                                    <th>联系电话</th>
                                    <th>VIP等级</th>
                                    <th>账户余额</th>
                                    <th>状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in userList" :key="item">
                                    <td v-if="isChoose">
                                        <input class="form-check-input" type="radio" id="radioUser" name="option" :value="item.UserID" v-model="checkedUser">
                                    </td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('userinfo',{userId:item.UserID})" role="button">{{item.UserID}}</span>
                                    </td>
                                    <td>{{item.UserName}}</td>
                                    <td>
                                        <i :class="[item.Sex==0?'bi-gender-female text-danger':item.Sex==1?'bi-gender-male text-primary':'']" class="bi"></i>
                                    </td>
                                    <td>{{item.Phone}}</td>
                                    <td>
                                        <span class="btn-link" @click="$root.linkTo('petinfo')" role="button">{{item.VipLevel}}</span>
                                    </td>
                                    <td>{{item.Balance}}</td>
                                    <td>{{ state_name[item.Status] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import pagination from "@/components/public/pagination.vue";
import { DATA_TYPE,LAYER_TYPE,STATE_TYPE,IS_WORKER,STATE_NAME } from "@/assets/js/config";
export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        },
        pagesize:0,//每页显示数据条数
        isChoose:false,//是否显示选择框
    },
    data(){
        return{
            //配置获取
            isWorker:IS_WORKER,//是否工作人员配置
            stateType:STATE_TYPE,//状态类型配置
            state_name:STATE_NAME,//状态类型名称配置

            searchUserID:"",//用户ID
            searchUserName:"",//用户名
            searchPhone:"",//联系电话
            searchUserType:"",//用户类型（是否员工）
            status:"1",
            userList:[],//用户列表
            isSearching:false,//是否正在搜索
            checkedUser:"",
        }
    },
    components:{
        pagination
    },
    mounted(){
        if(this.$route.params.result){
            this.showLayer({str:"保存成功！"});
        }
        this.getUserList();
    },
    methods:{
        //获取用户列表
        getUserList(){
            if(!this.isSearching){
                this.isSearching=true;
                let params={//参数
                    fun:"getusers",
                    guid:this.user.guid,
                    // encrystr:"dd",
                    pageindex:"1",
                    pagesize:this.pagesize
                }
                this.searchUserID!=""&&(params.searchuserid=this.searchUserID);
                this.searchUserName!=""&&(params.searchusername=this.searchUserName);
                this.searchPhone!=""&&(params.phone=this.searchPhone);
                String(this.searchUserType)!=""&&(params.isuser=this.searchUserType);
                this.$root.requestData(DATA_TYPE.getusers,params,(data)=>{
                    this.userList=data.list;
                    this.isSearching=false;
                })
            }
        },
        getRadioChecked(){
            // this.showLayer({
            //     type:LAYER_TYPE.confirm,
            //     str:"是否确认选择用户【"+this.checkedUser+"】",
            //     btn:["确认","取消"],
            //     yesFn:(i)=>{
            //         layer.close(i);
                    this.$emit("set-user",this.checkedUser);
            //     }
            // })
        }
    },
    watch: {
        /**
         * 监听搜索条件改变
         */
        searchUserID() {
            this.getUserList();
        },
        searchUserName() {
            this.getUserList();
        },
        searchPhone(){
            this.getUserList();
        },
        searchUserType(){
            this.getUserList();
        }
    },
}
</script>