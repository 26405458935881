// 仪表盘页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">仪表盘</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="userId" class="form-label">日期</label>
                            <input type="datetime-local" class="form-control" id="userId">
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-xl-5 mb-3">
                        <h5>值班医生</h5>
                        <tableList class="mb-2"></tableList>
                        <pagination></pagination>
                    </div>
                    <div class="col-xl-7 mb-3">
                        <h5>预约</h5>
                        <tableList class="mb-2"></tableList>
                        <pagination></pagination>
                    </div>
                </div>
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="petType" class="form-label">宠物种类</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>猫</option>
                                <option>狗</option>
                                <option>猪</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="department" class="form-label">科室</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>猫检查室</option>
                                <option>狗检查室</option>
                                <option>猪检查室</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="disease" class="form-label">症状</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>呼吸困难</option>
                                <option>腹泻</option>
                                <option>呕吐</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="case" class="form-label">案例类型</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>案例1</option>
                                <option>案例2</option>
                                <option>案例3</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="promise" class="form-label">预约方式</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>预约前来</option>
                                <option>预约后来</option>
                            </select>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                            <label for="doctor" class="form-label">医生姓名</label>
                            <select class="form-select">
                                <option>全部</option>
                                <option>布鲁斯李</option>
                                <option>魏教授</option>
                                <option>张医师</option>
                            </select>
                        </div>
                    </div>
                </form>
                <h5>治疗清单</h5>
                <tableList class="mb-2"></tableList>
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>