<!-- 服务信息页 -->
<style scoped>
    .bi-x::before,.bi-chevron-left::before{
        font-weight: bold !important;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>服务信息</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="d-flex flex-column flex-grow-1">

                    <form>
                        <!-- 服务信息填写 -->
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="userName" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>服务名称
                                </label>
                                <input type="text" class="form-control" id="userName" v-model.trim="service.productName" placeholder="服务名称">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="departid" class="form-label">执行科室</label>
                                <select class="form-select" id="departid" v-model="service.departId">
                                    <option selected value="0">请选择</option>
                                    <option v-for="i in departList" :key="i.DepartID" :value=i.DepartID>{{ i.DepartName }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                <label class="form-label">可否预约</label>
                                <div class="form-control form-control-plaintext pb-0">
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" v-model="service.isReserve" name="isReserve" value="1">
                                        <label class="form-check-label">是</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" class="form-check-input" v-model="service.isReserve" name="isReserve" value="0">
                                        <label class="form-check-label">否</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                <label class="form-label">显示为菜单</label>
                                <div class="form-control form-control-plaintext pb-0">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" :checked="service.serviceId==product_type.menu.id" @change="changeIsMenu" role="switch">
                                        <label class="form-check-label">{{ service.serviceId==product_type.menu.id?"是":"否" }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                <label for="state" class="form-label">状态</label>
                                <select class="form-select" name="state" id="state" v-model="service.status">
                                    <option :value="state_type.on">启用</option>
                                    <option :value="state_type.off">禁用</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">创建人</label>
                                <input type="text" class="form-control" disabled :placeholder="service.creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">创建时间</label>
                                <input type="text" class="form-control" disabled :placeholder="service.createTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-6 col-xl-6 mb-2">
                                <label for="memo" class="form-label">备注</label>
                                <input type="text" class="form-control" id="memo" v-model.trim="service.memo" placeholder="备注">
                                <!-- <textarea class="form-control" rows="3" id="memo" placeholder="备注"></textarea> -->
                            </div>
                            <div class="col-12 mb-2">
                                <label class="form-label">包含服务项</label>
                                <span class="ms-2 link-primary small" role="button" @click="isEditChildService=!isEditChildService">{{ isEditChildService?'取消':'选择' }}</span>
                                <div class="d-flex flex-wrap border rounded px-2 pt-1 pb-2">
                                    <div class="small mt-1" v-if="isEditChildService&&getServiceListUnCurr.length==0">暂无可选服务</div>
                                    <div class="small mt-1" v-else-if="getServiceListByService.length==0&&!isEditChildService">未设置</div>
                                    <template v-else>
                                        <div v-for="s of isEditChildService?getServiceListUnCurr:getServiceListByService" :key="s" class="d-flex flex-wrap small">
                                            <span v-if="s.ProductID!=service.serviceId" :class="[!isEditChildService?'bg-secondary':(getServiceListByService.find(v=>v.ProductID==s.ProductID)?'btn-primary':'')]" @click="serviceClick(s.ProductID)" :role="isEditChildService?'button':''" class="border rounded px-2 me-1 mt-1 bg-opacity-25">{{ s.ProductName }}</span>
                                            <!--先判断该产品ID是否 -->
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <!-- 价格标准填写 -->
                        <div v-for="(item,i) in getPriceListByService" :key="item" class="col-12 mb-2">
                            <div class="card small">
                                <div class="card-body p-2">
                                    <div class="d-flex flex-column">
                                        <form>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row h-100 align-content-around">
                                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                                            <label :for="'price'+i" class="form-label">
                                                                <span class="text-danger fw-bold me-1">*</span>价格（元）
                                                            </label>
                                                            <input class="form-control form-control-sm" :id="'price'+i" v-model.trim="item.Price" placeholder="价格（元）">
                                                        </div>
                                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                                            <label :for="'unit'+i" class="form-label">
                                                                <span class="text-danger fw-bold me-1">*</span>计量单位
                                                            </label>
                                                            <input class="form-control form-control-sm" :id="'unit'+i" v-model.trim="item.UnitName" placeholder="计量单位">
                                                        </div>
                                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2">
                                                            <label class="form-label">状态</label>
                                                            <select class="form-select form-select-sm" name="state" :id="'state'+i" v-model="item.Isenable">
                                                                <option :value="state_type.on">启用</option>
                                                                <option :value="state_type.off">禁用</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                            <label class="form-label">创建人</label>
                                                            <input type="text" class="form-control form-control-sm" disabled :placeholder="item.Creater">
                                                        </div>
                                                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                            <label class="form-label">创建日期</label>
                                                            <input type="text" class="form-control form-control-sm" disabled :placeholder="item.CreateTime">
                                                        </div>
                                                        <div class="col-12 mb-2">
                                                            <div>
                                                                <label class="form-label">品种设置</label>
                                                                <span class="ms-2 link-primary" data-bs-toggle="collapse" :data-bs-target="'#collapseSetBreed'+i" role="button">设置</span>
                                                                <div class="collapse mb-1" :id="'collapseSetBreed'+i">
                                                                    <ol v-if="petTypeList.length>0" class="list-group">
                                                                        <li v-for="petType of petTypeList" :key="petType" :class="[getPetBreedByType(petType).length>0?'d-flex':'d-none']" class="list-group-item flex-column py-1 px-2">
                                                                            <div class="fw-bold">{{ petType.PetAnimalName }}</div>
                                                                            <div class="d-flex flex-wrap small">
                                                                                <span v-for="breed of getPetBreedByType(petType)" :key="breed" :class="{'btn-primary':item.PetTypeID.split(',').find(v=>v==breed.PetTypeID)}" class="border rounded px-2 me-1 mt-1" @click="breedClick(item,petType.PetAnimalID,breed.PetTypeID)" role="button">{{ breed.PetTypeName }}</span>
                                                                            </div>
                                                                        </li>
                                                                        <li class="list-group-item d-flex justify-content-end py-1 px-2">
                                                                            <span class="link-primary" data-bs-toggle="collapse" :data-bs-target="'#collapseSetBreed'+i" role="button">
                                                                                <i class="bi bi-chevron-bar-up"></i> 收起
                                                                            </span>
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                            <ol v-if="item.PetAnimalID" class="list-group">
                                                                <li v-for="petType of item.PetAnimalID.split(',')" :key="petType" class="list-group-item d-flex flex-column py-1 px-2">
                                                                    <div class="fw-bold">{{ petTypeList.find(v=>v.PetAnimalID==petType).PetAnimalName }}</div>
                                                                    <div v-if="item.PetTypeID" class="d-flex flex-wrap small">
                                                                        <span class="border rounded px-2 me-1 mt-1 bg-secondary bg-opacity-25" v-for="breed of getCheckedBreedByTypeId(item,petType)" :key="breed">{{ petBreedList.find(v=>v.PetTypeID==breed).PetTypeName }}</span>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                            <div class="border rounded px-2 py-1" v-else>未设置</div>
                                                        </div>
                                                        <!-- <div class="col-sm-6 col-md-4 col-lg-6 col-xl-4">
                                                            <label :for="'remark'+i" class="form-label">备注</label>
                                                            <input class="form-control form-control-sm" :id="'remark'+i" v-model="item.Memo" placeholder="备注">
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="d-flex flex-row justify-content-end ps-md-3 text-end">
                                            <button type="button" :class="[item.ProductID?'btn-danger':'btn-warning']" class="btn btn-sm ms-2 ms-lg-0 d-flex align-items-center justify-content-center" @click="delPrice(item,i)">
                                                <i :class="[item.ProductID?'bi-trash':'bi-x-circle-fill']" class="bi me-1"></i>
                                                <span>{{ item.ProductID?"删除":"取消" }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 添加价格标准 -->
                        <div class="text-secondary btn btn-lg btn-light d-block fs-5" @click="addPriceClick">
                            <i class="bi bi-plus-lg me-2"></i>
                            <span>添加价格标准</span>
                        </div>
                    </form>

                </div>
            </div>

            <!-- 按钮栏 -->
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>

        </div>
    </section>
</template>
<script>
import { DATA_TYPE,SEX_TYPE,LAYER_TYPE,STATE_TYPE,PRODUCT_TYPE,OPERATE_TYPE } from "@/assets/js/config";
import {getDate} from "@/assets/js/common.js";
export default {
    props: {
        pagesize:0,
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        }
    },
    data(){
        return {
            //获取配置项
            sex_type:SEX_TYPE,//性别配置
            state_type:STATE_TYPE,//状态类型
            product_type:PRODUCT_TYPE,//产品类型

            isEditChildService:false,//是否正处于添加服务项中

            //服务信息
            serviceList:[],//服务列表
            menuList:[],//菜单列表
            service:{//服务对象
                productId:"",
                productName:"",//服务名称
                departId:0,
                serviceId:PRODUCT_TYPE.service.id,//类型为服务
                isReserve:1,//是否可预约
                status:1,
                memo:"",
                childServiceIds:"",//子服务id字符串
                createTime:getDate(),
                creater:""
            },
            departList:[],//科室列表

            //价格标准
            priceList:[],//价格标准列表
            price(){//价格标准对象
                return {
                    ProductID:"",
                    ProductName:"",
                    ServiceID:PRODUCT_TYPE.price.id,//类型为价格标准
                    IsReserve:1,
                    PetAnimalID:"",
                    PetTypeID:"",
                    UnitName:"",
                    Price:"",
                    Isenable:1,
                    ChildIDs:"",
                    CreateTime:"",
                    Creater:""
                }
            },
            petTypeList:[],//宠物种类列表
            petBreedList:[],//宠物品种列表
        }
    },
    computed: {
        /**
         * 获取除了当前服务的服务列表
         */
        getServiceListUnCurr(){
            let index=this.serviceList.findIndex(v=>v.ProductID==this.service.productId);//获取当前服务在列表中的下标
            let arr = JSON.parse(JSON.stringify(this.serviceList));//深克隆一个数组
            arr.splice(index,1);//截取掉当前服务
            return arr;
        },
        /**
         * 获取当前被编辑的服务的子服务字符串对应的服务列表
         */
        getServiceListByService() {
            return this.serviceList.filter(v=>this.service.childServiceIds.split(',').find(w=>w==v.ProductID));
        },
        /**
         * 获取当前被编辑的服务的价格标准列表
         */
        getPriceListByService(){
            // return this.priceList.filter(v=>(this.service.childServiceIds.split(',').find(w=>w==v.ProductID)||v.ProductID==""));
            return this.priceList.filter(v=>v.ChildIDs==this.service.productId||v.ProductID=="");
        }
    },
    created() {
        let productId=this.$route.params.productId;
        this.service.creater=this.user.name;
        if(productId){
            this.service.productId = productId;
            this.getProduct(productId);
        }
    },
    mounted() {
        this.getPetAnimal();
        this.getPetBreed();
        this.getServiceList();
        this.service.productId&&this.getPriceList();
        this.getDepart();
    },
    methods: {
        /**
         * 改变是否显示为菜单
         */
        changeIsMenu(){
            this.service.serviceId = this.service.serviceId==PRODUCT_TYPE.service.id?PRODUCT_TYPE.menu.id:PRODUCT_TYPE.service.id;
        },
        /**
         * 获取所有服务项
         */
        getServiceList() {
            return this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                pagesize:this.pagesize,
                serviceid:PRODUCT_TYPE.service.id
            },(data)=>{
                this.serviceList=data.list;
            })
        },
        /**
         * 获取产品表菜单列表
         */
        getMenuList(){
            return this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                pagesize:this.pagesize,
                serviceid:PRODUCT_TYPE.menu.id
            },(data)=>{
                this.menuList=data.list;
            })
        },
        /**
         * 根据产品id获取产品详情
         */
        getProduct(productId){
            this.$root.requestData(DATA_TYPE.getproductbyid,{
                pageindex:1,
                pagesize:this.pagesize,
                productid:productId
            },(data)=>{
                this.service.productId=data.list[0].ProductID;
                this.service.productName=data.list[0].ProductName;
                this.service.serviceId=data.list[0].ServiceID;
                this.service.isReserve=data.list[0].IsReserve;
                this.service.status=data.list[0].Isenable;
                this.service.memo=data.list[0].Memo;
                this.service.childServiceIds=data.list[0].ChildIDs;
                this.service.createTime=data.list[0].CreateTime;
                this.service.creater=data.list[0].Creater;
                this.service.departId=data.list[0].DepartID;
            })
        },
        /**
         * 获取所有价格标准
         */
        getPriceList(){
            return this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                pagesize:this.pagesize,
                serviceid:PRODUCT_TYPE.price.id
            },(data)=>{
                this.priceList=data.list;
            })
        },
        /**
         * 点击服务
         */
        serviceClick(serviceId){
            let serviceArr=this.service.childServiceIds.split(',');//获取当前被编辑的服务的包含服务项
            if(!serviceArr.find(v=>v==serviceId)){//包含服务项不存在
                this.service.childServiceIds+=(this.service.childServiceIds==""?serviceId:(","+serviceId));
            }else{
                //移除附加权限
                serviceArr.splice(serviceArr.findIndex(v=>v==serviceId),1);
                this.service.childServiceIds=String(serviceArr);
            }
        },
        /**
         * 获取科室列表
         */
        getDepart(){
            this.$root.requestData(DATA_TYPE.getdepart,{
                pageindex:1,
                pagesize:9999
            },(data)=>{
                this.departList=data.list;
            })
        },
        /**
         * 获取宠物种类
         */
        getPetAnimal(){
            // 获取宠物种类列表
            this.$root.requestData(DATA_TYPE.getpetanimal,{
                pageindex:1,
                pagesize:99999
            },(data)=>{
                this.petTypeList=data.list; 
            })
        },
        /**
         * 获取宠物品种
         */
        getPetBreed(){
            // 获取宠物品种列表
            this.$root.requestData(DATA_TYPE.getpettype,{
                pageindex:1,
                pagesize:99999
            },(data)=>{
                this.petBreedList=data.list;
            })
        },
        /**
         * 根据宠物种类获取对应品种
         */
        getPetBreedByType(pet) {
            return this.petBreedList.filter(v=>v.PetAnimalID==pet.PetAnimalID);
        },
        /**
         * 保存
         */
        async save() {
            console.log(this.service)
            if(!this.service.productName){
                this.showLayer({str:"服务名称不能为空"});
                return;
            }
            for (let item of this.getPriceListByService) {
                if(item["Price"]==""){
                    alert("价格不能为空！");
                    return;
                }
                if(item["UnitName"]==""){
                    alert("计量单位不能为空！");
                    return;
                }
            }
            let childids=[];
            let id = this.service.productId;
            //拼接包含的服务项
            for (let i = 0,arr=this.getServiceListByService; i < arr.length; i++) {
                childids.push(arr[i].ProductID);
            }
            //新增或修改当前编辑的服务
            await this.$root.requestData(this.service.productId?DATA_TYPE.updproduct:DATA_TYPE.addproduct,{
                productid:this.service.productId,
                productname:this.service.productName,
                serviceid:this.service.serviceId,
                brandname:"",
                petanimalid:"",
                pettypeid:"",
                unitname:"",
                price:"0",
                stock:0,
                memo:this.service.memo,
                isenable:this.service.status,
                isreserve:this.service.isReserve,
                isstock:0,
                typeid:0,
                childids:String(childids),
                departid:this.service.departId
            },(data)=>{
                this.$root.linkTo("servicelist",{result:true});
            })
            //如果是新增则需要获取新增的服务的id
            if(!id){
                if(this.service.serviceId==PRODUCT_TYPE.service.id){//非菜单
                    await this.getServiceList();
                    id=this.serviceList[0].ProductID;
                }else if(this.service.serviceId==PRODUCT_TYPE.menu.id){//菜单
                    await this.getMenuList();
                    id=this.menuList[0].ProductID;
                }
            }
            //添加新加的价格标准，修改原有的价格标准
            await new Promise((resolve)=>{
                this.getPriceListByService.length==0&&resolve();
                for (let i = 0; i < this.getPriceListByService.length; i++) {
                    this.getPriceListByService[i].ServiceID=PRODUCT_TYPE.price.id;
                    this.getPriceListByService[i].ChildIDs=id;
                    this.$root.requestData(this.getPriceListByService[i].ProductID?DATA_TYPE.updproduct:DATA_TYPE.addproduct,this.getPriceListByService[i],(data)=>{
                        i==this.getPriceListByService.length-1&&resolve();
                    })
                }
            })
        },
        /**
         * 点击添加价格标准
         */
        addPriceClick(){
            let price =this.price();
            price.Creater=this.user.name;
            price.CreateTime=getDate();
            this.priceList.push(price);
        },
        /**
         * 删除价格标准
         */
        delPrice(price,i){
            if(price.ProductID){//已存档的价格标准
                this.showLayer({
                    type:LAYER_TYPE.confirm,
                    str:"是否确认删除？",
                    btn:["确认","取消"],
                    yesFn:(i)=>{
                        layer.close(i);
                        this.$root.requestData(DATA_TYPE.delproduct,{
                            productid:price.ProductID
                        },(data)=>{
                            this.priceList.forEach((item,i)=>{
                                item.ProductID==price.ProductID&&this.priceList.splice(i,1);
                            })
                        })
                    }
                })
            }else{//未存档的宠物
                this.priceList.splice(i,1);
            }
        },
        /**
         * 点击品种
         * @param {*} price 价格标准对象
         * @param {*} type 宠物种类id
         * @param {*} breed 宠物品种id
         */
        breedClick(price,typeId,breedId){
            let typeArr=price.PetAnimalID.split(",");//宠物种类
            let breedArr=price.PetTypeID.split(",");//宠物品种
            //处理品种
            if(!breedArr.find(v=>v==breedId)){//宠物品种不存在
                price.PetTypeID+=(price.PetTypeID==""?breedId:(","+breedId));
            }else{
                //移除品种
                breedArr.splice(breedArr.findIndex(v=>v==breedId),1);
                price.PetTypeID=String(breedArr);
            }
            //处理种类
            if(!typeArr.find(v=>v==typeId)){//价格标准中没有找到点击的品种所属的宠物种类
                price.PetAnimalID+=(price.PetAnimalID==""?typeId:(","+typeId));//加进去
            }else{//有找到
                if(this.getCheckedBreedByTypeId(price,typeId).length==0){//只有在该种类下没有其他被选中的品种时
                    //移除种类
                    typeArr.splice(typeArr.findIndex(v=>v==typeId),1);
                    price.PetAnimalID=String(typeArr);
                }
            }
        },
        /**
         * 根据宠物种类获取已选中的品种
         */
        getCheckedBreedByTypeId(price,typeId){
            //在品种字符串中找到所属种类为当前指定种类的品种
            if(!price.PetTypeID){
                return [];
            }
            // return this.petBreedList.filter(v=>price.PetTypeID.split(",").indexOf(v.PetTypeID)!=-1)
            return price.PetTypeID.split(",").filter(v=>{
                let breed=this.petBreedList.find(val=>val.PetTypeID==v);
                return breed&&breed.PetAnimalID==typeId;
            });
        }
    },
}
</script>