<!-- 医护开药处理页 -->
<style scoped>
    .bi-chevron-left::before{
        font-weight: bold !important;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>医护开药处理页</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="h-100">
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="reserveCode" class="form-label">预约号</label>
                                <input class="form-control" id="reserveCode" placeholder="预约号" disabled v-model="reserveCode">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="userID" class="form-label">账号</label>
                                <input class="form-control" id="userID" placeholder="账号" disabled v-model="userID">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="serviceName" class="form-label">预约服务项目</label>
                                <input class="form-control" id="serviceName" placeholder="预约服务项目" disabled v-model="serviceName">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="unitPrice" class="form-label">价格</label>
                                <input class="form-control" id="unitPrice" placeholder="价格" disabled v-model="unitPrice">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="isenable" class="form-label">是否需要开药</label>
                                <select class="form-select" id="isenable" name="isenable" v-model="isenable">
                                    <option :value="1">是</option>
                                    <option :value="0">否</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2" :class="{'d-none':isenable=='0'}">
                                <label for="productId" class="form-label">药品</label>
                                <select class="form-select" id="productId" name="productId" v-model="productId" @change="getSelectVal($event)" >
                                    <option value="0">请选择</option>
                                    <option v-for="ware in wareList" :key="ware.ProductID" :value="ware.ProductID" :title="ware.Price">{{ware.ProductName}}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2" :class="{'d-none':isenable=='0'}">
                                <label for="price" class="form-label">药品价格</label>
                                <input class="form-control" id="price" placeholder="药品价格" disabled v-model="price">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative" :class="{'d-none':isenable=='0'}">
                                <label for="quantity" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>数量
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('quantity')}" class="form-control" id="quantity" placeholder="数量" v-model="quantity">
                                <div class="invalid-tooltip">{{ userDataValid("quantity") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="memo" class="form-label">医嘱/说明</label>
                                <input class="form-control" id="memo" placeholder="医嘱/说明" v-model="memo">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="creater" class="form-label">创建人</label>
                                <input class="form-control" id="creater" disabled :placeholder="creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="createTime" class="form-label">创建时间</label>
                                <input class="form-control" id="createTime" disabled :placeholder="createTime">
                            </div>
                        </div>
                    </form>
                </div>
                
            </div>
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import { LAYER_TYPE,RESULT_TYPE,IS_DEBUG,DATA_TYPE,APP_DATA_TYPE,OPERATE_TYPE,STATE_TYPE,PRODUCT_TYPE,APPOINT_STATE } from "@/assets/js/config";
import common from "@/assets/js/common";
export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        }
    },
    data(){
        return {
            state_type:STATE_TYPE,//获取状态配置
            funParams:"",
            isVerifyEmpty:false,//是否进行非空验证
            reserveID:0,
            reserveCode:0,
            userID:"",
            serviceName:"",
            unitPrice:0,
            isenable:0,
            productId:0,
            productName:"",
            price:0,
            quantity:0,
            memo:"",
            creater:"",
            createTime:new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),
            editModel:OPERATE_TYPE.insert,//当前编辑模式，默认为新增
            wareList:[],
        }
    },
    created(){
        let reserveid=this.$route.params.reserveID;
        this.creater=this.user.name;
        if(reserveid){//修改模式
            this.reserveID=reserveid;
            this.getReserveById(reserveid);
            this.getWareList();
        }
    },
    mounted(){

    },
    methods:{
        /**
         * 用户数据有效性验证
         */
         userDataValid(dataName){
            switch (dataName) {
                case "quantity":
                    if(!this.quantity||this.quantity=="0"){
                        return "数量为必填项！";
                    }
                    break;
                default:
                    break;
            }
            return null;
        },
        /**
         * 保存订单信息
         */
         save(){

            //验证未通过
            if(this.isenable!=0){
                if(this.userDataValid("quantity")){
                    return;
                }
                if(this.productId==0){
                    return "药品为必选项！";
                }
            }
            this.$root.requestData(DATA_TYPE.addorders,{
                reservecode:this.reserveCode,
                productid:this.productId,
                productname:this.productName,
                unitprice:this.price,
                quantity:this.quantity,
                totalprice:(parseInt(this.price)*parseInt(this.quantity)),
                memo:this.memo,
            },async (data)=>{
                this.showLayer({str:"保存成功！"});
            })
        },
        /**获取预约信息 */
        getReserveById(reserveID){
            this.$root.requestData(DATA_TYPE.getreservebyid,{
                reserveid:reserveID
                },(data)=>{
                    this.reserveCode=data.list[0].ReserveCode;
                    this.userID=data.list[0].UserID;
                    this.serviceName=data.list[0].ProductName;
                    this.unitPrice=data.list[0].UnitPrice;
                    this.memo=data.list[0].Memo;
                })
        },
        getWareList(){
            //获取物料列表
            this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                pagesize:9999,
                isreserve:0,
                isstock:1,
            },(data)=>{
                this.wareList=data.list;
            })
        },
        getSelectVal(val){
            this.productId=val.target.value;
            this.productName=val.target.options[val.target.selectedIndex].text;
            this.price=val.target.options[val.target.selectedIndex].title;
            console.dir(val.target.options[val.target.selectedIndex].title)
            console.log(val.target.options[val.target.selectedIndex].text)
        }
    }
}
</script>


