// 经销商管理页
<style scoped>

</style>
<template>
    <section>
        <div class="card">
            <div class="card-header bg-info text-light">经销商管理</div>
            <div class="card-body">
                <form>
                    <div class="row">
                        <div class="col-sm-6 col-md-3 mb-3">
                            <label for="dealer" class="form-label">经销商</label>
                            <input class="form-control" id="dealer" placeholder="经销商">
                        </div>
                        <div class="col-sm-6 col-md-3 mb-3">
                            <label for="dealerName" class="form-label">联系人</label>
                            <input class="form-control" id="dealerName" placeholder="联系人">
                        </div>
                        <div class="col-sm-6 col-md-3 mb-3">
                            <label for="phone" class="form-label">联系电话</label>
                            <input class="form-control" id="phone" placeholder="联系电话">
                        </div>
                        <div class="col-sm-6 col-md-3 mb-3">
                            <label for="email" class="form-label">电子邮件</label>
                            <input class="form-control" id="email" placeholder="电子邮件">
                        </div>
                    </div>
                    <div class="row">
                            <div class="col d-flex justify-content-end align-items-end mb-3">
                                <button type="button" class="btn btn-info me-3 text-white">
                                    <i class="bi bi-plus-lg"></i> 添加经销商
                                </button>
                                <button type="button" class="btn btn-danger">
                                    <i class="bi bi-trash"></i> 删除选中数据
                                </button>
                            </div>
                        </div>
                </form>
                <tableList></tableList>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import tableList from "@/components/public/table.vue";
import pagination from "@/components/public/pagination.vue";
export default {
    components:{
        tableList,
        pagination
    }
}
</script>