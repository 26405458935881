import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import Index from '@/components/page/index.vue'
import userList from '@/components/page/userList.vue'
import userInfo from '@/components/page/userInfo.vue'
import appointList from '@/components/page/appointList.vue'
import appointInfo from '@/components/page/appointInfo.vue'
import appointMge from '@/components/page/appointMge.vue'
import payList from '@/components/page/payList.vue'
import doctorList from '@/components/page/doctorList.vue'
import petList from '@/components/page/petList.vue'
import petInfo from '@/components/page/petInfo.vue'
import dispensaryList from '@/components/page/dispensaryList.vue'
import rolePowerList from '@/components/page/rolePowerList.vue'
import serviceList from '@/components/page/serviceList.vue'
import serviceInfo from '@/components/page/serviceInfo.vue'
import departmentList from '@/components/page/departmentList.vue'
import departmentInfo from '@/components/page/departmentInfo.vue'
import wareList from '@/components/page/wareList.vue'
import wareInfo from '@/components/page/wareInfo.vue'
import dashboard from '@/components/page/dashboard.vue'
import opd from '@/components/page/opd.vue'
import admit from '@/components/page/admit.vue'
import surgery from '@/components/page/surgery.vue'
import grooming from '@/components/page/grooming.vue'
import cabin from '@/components/page/cabin.vue'
import treatmentPlan from '@/components/page/treatmentPlan.vue'
import stock from '@/components/page/stock.vue'
import productCode from '@/components/page/productCode.vue'
import dealer from '@/components/page/dealer.vue'
import receipt from '@/components/page/receipt.vue'
import applyBuy from '@/components/page/applyBuy.vue'
import noticeModal from '@/components/page/noticeModal.vue'
import addRole from '@/components/page/addRole.vue'
import payroll from '@/components/page/payroll.vue'
import prepaidExpenses from '@/components/page/prepaidExpenses.vue'
import expenseSheet from '@/components/page/expenseSheet.vue'
import docdruyInfo from '@/components/page/docdruyInfo.vue'
import historyOrders from '@/components/page/historyOrders.vue'
import { ROUTE_TYPE } from "@/assets/js/config";

const routes = [
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    children:[
      {
        path:'/home',
        redirect:'/home/index'
      },
      {
        name:'home',
        path:'/home/index',
        meta:{
          type:ROUTE_TYPE.firstMenu,
          title:"首页",
          ico:"bi-house"
        },
        component:Index
      },
      {
        path:"",
        name:'customer',
        meta:{
          type:ROUTE_TYPE.firstMenu,
          title:"客户服务",
          ico:"bi-people"
        }
      },
      {
        name:'userlist',
        path:'/home/userlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,//类型为菜单
          title:"用户资料",//文本
          parent:"customer",//父菜单路由名称
        },
        component:userList
      },
      {
        name:'userinfo',
        path:'/home/userinfo',
        meta:{
          type:ROUTE_TYPE.fun,//类型为功能
          title:"用户信息",
          parent:"userlist",
        },
        component:userInfo
      },
      {
        name:'appointlist',
        path:'/home/appointlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"预约挂号",
          parent:"customer",
        },
        component:appointList
      },
      {
        name:'appointinfo',
        path:'/home/appointinfo',
        meta:{
          type:ROUTE_TYPE.fun,//类型为功能
          title:"预约信息",
          parent:"appointlist",
        },
        component:appointInfo
      },
      {
        name:'doctorlist',
        path:'/home/doctorlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"医护服务",
          parent:"customer",
        },
        component:doctorList
      },
      {
        path:'/home/docdruyInfo',
        name:'docdruyInfo',
        meta:{
          type:ROUTE_TYPE.fun,
          title:"医护开药处理页",
          parent:"doctorlist",
        },
        component:docdruyInfo
      },
      {
        name:'paylist',
        path:'/home/paylist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"结账缴费",
          parent:"customer",
        },
        component:payList
      },
      {
        name:'dispensarylist',
        path:'/home/dispensarylist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"药房取药",
          parent:"customer",
        },
        component:dispensaryList
      },
      {
        path:"",
        name:'service',
        meta:{
          type:ROUTE_TYPE.firstMenu,
          title:"内务管理",
          ico:"bi-pc-display-horizontal"
        }
      },
      {
        name:'rolepowerlist',
        path:'/home/rolepowerlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"角色权限管理",
          parent:"service",
        },
        component:rolePowerList
      },
      {
        name:'servicelist',
        path:'/home/servicelist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"服务管理",
          parent:"service",
        },
        component:serviceList
      },
      {
        name:'serviceinfo',
        path:'/home/serviceinfo',
        meta:{
          type:ROUTE_TYPE.fun,
          title:"服务信息",
          parent:"servicelist",
        },
        component:serviceInfo
      },
      {
        name:'departmentlist',
        path:'/home/departmentlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"科室管理",
          parent:"service",
        },
        component:departmentList
      },
      {
        name:'departmentinfo',
        path:'/home/departmentinfo',
        meta:{
          type:ROUTE_TYPE.fun,
          title:"科室信息",
          parent:"departmentlist",
        },
        component:departmentInfo
      },
      {
        name:'warelist',
        path:'/home/warelist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"仓库管理",
          parent:"service",
        },
        component:wareList
      },
      {
        name:'wareinfo',
        path:'/home/wareinfo',
        meta:{
          type:ROUTE_TYPE.fun,
          title:"物料信息",
          parent:"warelist",
        },
        component:wareInfo
      },
      {
        name:'petlist',
        path:'/home/petlist',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"宠物管理",
          parent:"service",
        },
        component:petList
      },
      {
        name:'petinfo',
        path:'/home/petinfo',
        meta:{
          type:ROUTE_TYPE.fun,
          title:"宠物信息",
          parent:"petlist",
        },
        component:petInfo
      },
      {
        name:'appointmge',
        path:'/home/appointmge',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"预约管理",
          parent:"service",
        },
        component:appointMge
      },
      {
        path:"",
        name:'report',
        meta:{
          type:ROUTE_TYPE.firstMenu,
          title:"报表统计",
          ico:"bi-graph-up-arrow"
        }
      },
      {
        name:'historyorders',
        path:'/home/historyorders',
        meta:{
          type:ROUTE_TYPE.secondMenu,
          title:"历史订单",
          parent:"report",
        },
        component:historyOrders
      },
      {
        path:"",
        name:'setting',
        meta:{
          type:ROUTE_TYPE.firstMenu,
          title:"设置",
          ico:"bi-gear"
        }
      },

      {
        path:'/home/dashboard',
        component:dashboard
      },
      {
        path:'/home/opd',
        component:opd
      },
      {
        path:'/home/admit',
        component:admit
      },
      {
        path:'/home/surgery',
        component:surgery
      },
      {
        path:'/home/grooming',
        component:grooming
      },
      {
        path:'/home/cabin',
        component:cabin
      },
      {
        path:'/home/treatmentplan',
        name:'treatmentPlan',
        component:treatmentPlan
      },
      {
        path:'/home/stock',
        name:"stock",
        meta:{
          type:ROUTE_TYPE.fun,
          title:"出入库信息",
          parent:"warelist",
        },
        component:stock
      },
      {
        path:'/home/productcode',
        component:productCode
      },
      {
        path:'/home/dealer',
        component:dealer
      },
      {
        path:'/home/receipt',
        component:receipt
      },
      {
        path:'/home/applybuy',
        component:applyBuy
      },
      {
        path:'/home/noticeModal',
        name:'noticeModal',
        component:noticeModal
      },
      {
        path:'/home/addRole',
        name:'addRole',
        component:addRole
      },
      {
        path:'/home/payroll',
        name:'payroll',
        component:payroll
      },
      {
        path:'/home/prepaidExpenses',
        name:'prepaidExpenses',
        component:prepaidExpenses
      },
      {
        path:'/home/expenseSheet',
        name:'expenseSheet',
        component:expenseSheet
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
