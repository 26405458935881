<!-- 宠物管理页 -->
<style scoped>
    .bi-gender-male::before,.bi-gender-female::before{
        font-weight: bold !important;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">宠物管理</div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <ul class="nav nav-tabs mb-2">
                        <li class="nav-item">
                            <span :class="{'active':currTab==tabName.type}" class="nav-link" @click="currTab=tabName.type" role="button">宠物种类</span>
                        </li>
                        <li class="nav-item">
                            <span :class="{'active':currTab==tabName.breed}" class="nav-link" @click="currTab=tabName.breed" role="button">宠物品种</span>
                        </li>
                        <li class="nav-item">
                            <span :class="{'active':currTab==tabName.pet}" class="nav-link" @click="currTab=tabName.pet" role="button">宠物</span>
                        </li>
                    </ul>
                    <!-- 宠物种类 -->
                    <div :class="[currTab==tabName.type?'d-flex':'d-none']" class="flex-grow-1">
                        <div class="flex-fill pe-2">
                            <div class="card h-100">
                                <div class="card-header bg-primary text-light">
                                    <div class="d-flex justify-content-between">
                                        <span>宠物种类管理</span>
                                        <div @click="btnAddPetTypeClick" role="button">
                                            <i v-show="!isShowAddPetType" class="bi bi-plus-circle me-1"></i>
                                            <span>{{isShowAddPetType?'取消':'新增种类'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>种类名称</th>
                                                        <th>状态</th>
                                                        <th>备注</th>
                                                        <th>操作</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- 新增栏 -->
                                                    <tr v-show="isShowAddPetType">
                                                        <td></td>
                                                        <td>{{petTypeList.length+1}}</td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="petTypeName" class="form-control form-control-sm" placeholder="种类名称">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <select class="form-select form-select-sm" v-model="petTypeState" name="state">
                                                                        <option :value="stateType.on">启用</option>
                                                                        <option :value="stateType.off">禁用</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="petTypeMemo" class="form-control form-control-sm" placeholder="备注">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <i class="bi bi-save me-2 text-success" @click="setPetType(operateType.insert)" role="button" title="保存"></i>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- 列表栏 -->
                                                    <tr v-for="(item,i) of petTypeList" :key="item.PetAnimalID" @click="typeClick(item)">
                                                        <td :class="[item.PetAnimalID==currChooseType.PetAnimalID?'text-success':'text-muted']">
                                                            <i :class="[item.PetAnimalID==currChooseType.PetAnimalID?'bi-check-circle-fill':'bi-check-circle']" class="bi"></i>
                                                        </td>
                                                        <td class="text-muted">{{i+1}}</td>
                                                        <td v-if="item.PetAnimalID==currChooseType.PetAnimalID">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="updateType.name" class="form-control form-control-sm" placeholder="种类名称">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td v-else class="text-muted">{{item.PetAnimalName}}</td>
                                                        <td v-if="item.PetAnimalID==currChooseType.PetAnimalID">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <select class="form-select form-select-sm" v-model="updateType.state" name="updTypeState">
                                                                        <option :value="stateType.on">启用</option>
                                                                        <option :value="stateType.off">禁用</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td v-else class="text-muted">{{item.Isenable==stateType.on?"启用":item.Isenable==stateType.off?"禁用":"状态异常"}}</td>
                                                        <td v-if="item.PetAnimalID==currChooseType.PetAnimalID">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-grow-1 w-0">
                                                                    <input type="text" v-model="updateType.memo" class="form-control form-control-sm" placeholder="备注">
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td v-else class="text-muted">{{ item.Memo }}</td>
                                                        <td>
                                                            <i class="bi bi-x-circle-fill text-danger me-2" @click.stop="setPetType(operateType.delete,item)" role="button" title="删除"></i>
                                                            <i v-if="item.PetAnimalID==currChooseType.PetAnimalID" class="bi bi-save text-success" @click.stop="setPetType(operateType.update,item)" role="button" title="保存"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-fill">
                            <div class="card h-100">
                                <div class="card-header bg-secondary text-light">品种配置</div>
                                <div class="card-body p-0">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex flex-column flex-grow-1 h-0 overflow-scroll">
                                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>编号</th>
                                                        <th>品种名称</th>
                                                        <th>所属种类</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item,i) in petBreedList" :key="item.PetTypeID" @click="breedCheckClick(item)">
                                                        <td :class="[currChooseBreedList.find(v=>v.PetTypeID==item.PetTypeID)?'text-success':'text-muted']">
                                                            <i :class="[currChooseBreedList.find(v=>v.PetTypeID==item.PetTypeID)?'bi-check-square-fill':'bi-check-square']" class="bi"></i>
                                                        </td>
                                                        <td :class="{'text-muted':!currChooseBreedList.find(v=>v.PetTypeID==item.PetTypeID)}">{{i+1}}</td>
                                                        <td :class="{'text-muted':!currChooseBreedList.find(v=>v.PetTypeID==item.PetTypeID)}">{{item.PetTypeName}}</td>
                                                        <td :class="{'text-muted':!currChooseBreedList.find(v=>v.PetTypeID==item.PetTypeID)}">{{ item.PetAnimalName }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!-- 没有数据 -->
                                            <!-- <div v-if="powerList.length<=0" class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                                                <div class="bg-secondary bg-opacity-50 p-2 mb-2 rounded">
                                                    <i class="bi bi-file-earmark-text text-light"></i>
                                                </div>
                                                <span class="text-secondary text-opacity-50">暂无数据</span>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 宠物品种 -->
                    <div :class="[currTab==tabName.breed?'d-flex':'d-none']" class="d-flex flex-column flex-grow-1">
                        <div class="flex-grow-1 h-0 overflow-scroll">
                            <div class="d-flex justify-content-end mb-2">
                                <div @click="btnAddBreedClick" class="text-primary" role="button">
                                    <i v-show="!isShowAddBreed" class="bi bi-plus-circle me-1"></i>
                                    <span>{{isShowAddBreed?'取消':'新增品种'}}</span>
                                </div>
                            </div>
                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                                <thead class="bg-primary bg-gradient text-light">
                                    <tr>
                                        <th></th>
                                        <th>编号</th>
                                        <th>品种名称</th>
                                        <th>所属种类</th>
                                        <th>状态</th>
                                        <th>备注</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- 新增栏 -->
                                    <tr v-show="isShowAddBreed">
                                        <td></td>
                                        <td>{{petBreedList.length+1}}</td>
                                        <td>
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <input type="text" v-model="petBreedName" class="form-control form-control-sm" placeholder="品种名称">
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <select class="form-select form-select-sm" v-model="petBreedBelongTypeId" name="belongType" placeholder="所属种类">
                                                        <option value="0" selected>请选择</option>
                                                        <option v-for="item of petTypeList" :key="item.PetAnimalID" :value="item.PetAnimalID">{{item.PetAnimalName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <select class="form-select form-select-sm" v-model="petBreedState" name="state">
                                                        <option :value="stateType.on">启用</option>
                                                        <option :value="stateType.off">禁用</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <input type="text" v-model="petBreedMemo" class="form-control form-control-sm" placeholder="备注">
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <i class="bi bi-save me-2 text-success" @click="setPetBreed(operateType.insert)" role="button" title="保存"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-for="(item,i) of petBreedList" :key="item.PetTypeID" @click="breedClick(item)">
                                        <td :class="[item.PetTypeID==currChooseBreed.PetTypeID?'text-success':'text-muted']">
                                            <i :class="[item.PetTypeID==currChooseBreed.PetTypeID?'bi-check-circle-fill':'bi-check-circle']" class="bi"></i>
                                        </td>
                                        <td :class="{'text-muted':item.PetTypeID!=currChooseBreed.PetTypeID}">{{i+1}}</td>
                                        <td v-if="item.PetTypeID==currChooseBreed.PetTypeID">
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <input type="text" v-model="updateBreed.name" class="form-control form-control-sm" placeholder="品种名称">
                                                </div>
                                            </div>
                                        </td>
                                        <td v-else class="text-muted">{{item.PetTypeName}}</td>
                                        <td v-if="item.PetTypeID==currChooseBreed.PetTypeID">
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <select class="form-select form-select-sm" v-model="updateBreed.belong" name="belongType" placeholder="所属种类">
                                                        <option value="0" selected>请选择</option>
                                                        <option v-for="item of petTypeList" :key="item.PetAnimalID" :value="item.PetAnimalID">{{item.PetAnimalName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td v-else class="text-muted">{{ item.PetAnimalName }}</td>
                                        <td v-if="item.PetTypeID==currChooseBreed.PetTypeID">
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <select class="form-select form-select-sm" v-model="updateBreed.state" name="updState">
                                                        <option :value="stateType.on">启用</option>
                                                        <option :value="stateType.off">禁用</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td v-else class="text-muted">{{item.Isenable==stateType.on?"启用":item.Isenable==stateType.off?"禁用":"状态异常"}}</td>
                                        <td v-if="item.PetTypeID==currChooseBreed.PetTypeID">
                                            <div class="d-flex w-100">
                                                <div class="flex-grow-1 w-0">
                                                    <input type="text" v-model="updateBreed.memo" class="form-control form-control-sm" placeholder="备注">
                                                </div>
                                            </div>
                                        </td>
                                        <td v-else class="text-muted">{{ item.Memo }}</td>
                                        <td>
                                            <i class="bi bi-x-circle-fill text-danger me-2" @click.stop="setPetBreed(operateType.delete,item)" role="button" title="删除"></i>
                                            <i v-if="item.PetTypeID==currChooseBreed.PetTypeID" class="bi bi-save text-success" @click.stop="setPetBreed(operateType.update,item)" role="button" title="保存"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- 宠物 -->
                    <div :class="[currTab==tabName.pet?'d-flex':'d-none']" class="d-flex flex-column flex-grow-1">
                        <form class="px-2">
                            <div class="row">
                                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                    <label for="userName" class="form-label">笼子编号</label>
                                    <input class="form-control" id="userName" placeholder="笼子编号">
                                </div>
                            </div>
                        </form>
                        <div class="flex-grow-1 h-0 overflow-scroll">
                            <table class="table table-striped table-hover table-borderless table-row-fixed mb-0">
                                <thead class="bg-primary bg-gradient text-light">
                                    <tr>
                                        <th>宠物ID</th>
                                        <th>宠物名字</th>
                                        <th>宠物类型</th>
                                        <th>宠物种类</th>
                                        <th>宠物性别</th>
                                        <th>宠物颜色</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in petList" :key="item.PetID">
                                        <td>{{item.PetID}}</td>
                                        <td>
                                            <span class="btn-link" @click="$root.linkTo('petinfo',{userId:item.UserID,petId:item.PetID})" role="button">{{item.PetName}}</span>
                                        </td>
                                        <td>{{item.PetTypeName}}</td>
                                        <td>{{ item.PetAnimalName }}</td>
                                        <td>
                                            <i :class="[item.Sex==2?'bi-gender-female text-danger':item.Sex==1?'bi-gender-male text-primary':'']" class="bi"></i>
                                        </td>
                                        <td>
                                            <!-- <span class="pe-4 me-2 rounded bg-orange"></span> -->
                                            <span>{{item.Color}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
import pagination from "@/components/public/pagination.vue";
import { LAYER_TYPE,STATE_TYPE,DATA_TYPE,OPERATE_TYPE } from "@/assets/js/config";
export default {
    components:{
        pagination
    },
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        },
        pagesize:0,//每页显示数据条数
    },
    data() {
        return {
            operateType:OPERATE_TYPE,//操作类型
            stateType:STATE_TYPE,//状态类型
            tabName:{
                type:1,//宠物种类
                breed:2,//宠物品种
                pet:3,//宠物
            },//选项卡名称
            currTab: "",//当前选中的选项卡
            //宠物种类
            petTypeList:[],//宠物种类列表
            petBreedList:[],//宠物品种列表
            isShowAddPetType:false,//是否显示新增种类
            petTypeName:"",//宠物种类名称
            petTypeState:"",//宠物种类状态
            petTypeMemo:"",//宠物种类备注
            currChooseType:{},//当前选中的宠物种类
            updateType:{
                name:"",//种类名称
                state:"",//状态
                memo:"",//备注
            },//当前要修改的宠物种类对象
            currChooseBreedList:[],//当前选中的品种列表
            //宠物品种
            isShowAddBreed:false,//是否显示新增品种
            petBreedName:"",//宠物品种名称
            petBreedState:"",//宠物品种状态
            petBreedMemo:"",//宠物品种备注
            petBreedBelongTypeId:0,//宠物品种所属种类id
            currChooseBreed:{},//当前选中的宠物品种
            updateBreed:{
                name:"",//品种名称
                belong:0,//所属种类
                state:"",//状态
                memo:"",//备注
            },//当前要修改的宠物品种对象
            //宠物
            petList:[],//宠物列表
        };
    },
    created() {
        this.currTab=this.tabName.type;
        this.petTypeState=this.stateType.on;
        this.petBreedState=this.stateType.on;
    },
    mounted() {
        // 获取宠物种类列表
        this.$root.requestData(DATA_TYPE.getpetanimal,{
            pageindex:1,
            pagesize:this.pagesize
        },(data)=>{
            this.petTypeList=data.list;
        })
        // 获取宠物品种列表
        this.$root.requestData(DATA_TYPE.getpettype,{
            pageindex:1,
            pagesize:this.pagesize
        },(data)=>{
            this.petBreedList=data.list;
        })
        //获取宠物列表
        this.$root.requestData(DATA_TYPE.getpets,{
            pageindex:1,
            pagesize:this.pagesize
        },(data)=>{
            this.petList=data.list;
        })
    },
    methods: {
        /**
         * 点击新增宠物种类或取消
         */
        btnAddPetTypeClick() {
            this.isShowAddPetType=!this.isShowAddPetType;
        },
        /**
         * 宠物种类增删改
         */
        setPetType(type,petType){
            switch (type) {
                case OPERATE_TYPE.insert:
                    this.$root.requestData(DATA_TYPE.addpetanimal,{
                        petanimalname:this.petTypeName,
                        isenable:this.petTypeState,
                        memo:this.petTypeMemo
                    },()=>{
                        // 获取宠物种类列表
                        this.$root.requestData(DATA_TYPE.getpetanimal,{
                            pageindex:1,
                            pagesize:99999
                        },(data)=>{
                            this.petTypeList=data.list;
                        })
                        this.isShowAddPetType=false;
                        this.petTypeName="";
                        this.petTypeState=STATE_TYPE.on;
                        this.petTypeMemo="";
                    })
                    break;
                case OPERATE_TYPE.update:
                    this.$root.requestData(DATA_TYPE.updpetanimal,{
                        petanimalid:petType.PetAnimalID,
                        petanimalname:this.updateType.name,
                        isenable:this.updateType.state,
                        memo:this.updateType.memo
                    },()=>{
                        petType.PetAnimalName=this.updateType.name;
                        petType.Isenable=this.updateType.state;
                        petType.Memo=this.updateType.memo;
                        //将所属种类为当前选中种类且未选中的项修改种类为空，又将所属种类不为当前选中种类且选中的项修改种类为当前选中种类
                        let chooseArr=JSON.parse(JSON.stringify(this.currChooseBreedList));//拷贝一份选中数组
                        let checkArr=this.petBreedList.filter(v=>v.PetAnimalID!=this.currChooseType.PetAnimalID&&chooseArr.find(w=>w.PetTypeID==v.PetTypeID));//要新增选中的项
                        let uncheckArr=this.petBreedList.filter(v=>v.PetAnimalID==this.currChooseType.PetAnimalID&&!chooseArr.find(w=>w.PetTypeID==v.PetTypeID));//要取消选中的项
                        if(checkArr.length>0){//有需要新增的项
                            let checkIdArr=checkArr.map(v=>v.PetTypeID);//新增项的id
                            this.$root.requestData(DATA_TYPE.updpettypepetanimalid,{
                                pettypeids:String(checkIdArr),
                                petanimalid:petType.PetAnimalID
                            },()=>{
                                this.petBreedList.forEach((v)=>{
                                    if(checkIdArr.indexOf(v.PetTypeID)!=-1){
                                        v.PetAnimalID=petType.PetAnimalID;
                                        v.PetAnimalName=petType.PetAnimalName;
                                    }
                                });
                            })
                        }
                        if(uncheckArr.length>0){//有需要去除的项
                            let uncheckIdArr=uncheckArr.map(v=>v.PetTypeID);
                            this.$root.requestData(DATA_TYPE.updpettypepetanimalid,{
                                pettypeids:String(uncheckIdArr),
                                petanimalid:0
                            },()=>{
                                this.petBreedList.forEach((v)=>{
                                    if(uncheckIdArr.indexOf(v.PetTypeID)!=-1){
                                        v.PetAnimalID=0;
                                        v.PetAnimalName="";
                                    }
                                });
                            })
                        }
                        this.showLayer({str:"保存成功！"});
                    })
                    break;
                case OPERATE_TYPE.delete:
                    this.showLayer({
                        type:LAYER_TYPE.confirm,
                        str:"是否确认删除宠物种类【"+petType.PetAnimalName+"】",
                        btn:["确认","取消"],
                        yesFn:(i)=>{
                            layer.close(i);
                            this.$root.requestData(DATA_TYPE.delpetanimal,{
                                petanimalid:petType.PetAnimalID
                            },()=>{
                                this.petTypeList.splice(this.petTypeList.findIndex(v=>v.PetAnimalID==petType.PetAnimalID),1);
                            })
                        }
                    })
                    break;
                default:
                    break;
            }
        },
        /**
         * 点击新增宠物品种或取消
         */
        btnAddBreedClick(){
            this.isShowAddBreed=!this.isShowAddBreed;
        },
        /**
         * 宠物品种增删改
         */
        setPetBreed(type,breed){
            switch (type) {
                case OPERATE_TYPE.insert:
                    this.$root.requestData(DATA_TYPE.addpettype,{
                        pettypename:this.petBreedName,
                        petanimalid:this.petBreedBelongTypeId,
                        isenable:this.petBreedState,
                        memo:this.petBreedMemo
                    },()=>{
                        // 获取宠物品种列表
                        this.$root.requestData(DATA_TYPE.getpettype,{
                            pageindex:1,
                            pagesize:99999
                        },(data)=>{
                            this.petBreedList=data.list;
                        })
                        this.isShowAddBreed=false;
                        this.petBreedName="";
                        this.petBreedBelongTypeId=0;
                        this.petBreedState=STATE_TYPE.on;
                        this.petBreedMemo="";
                    })
                    break;
                case OPERATE_TYPE.update:
                    this.$root.requestData(DATA_TYPE.updpettype,{
                        pettypeid:breed.PetTypeID,
                        pettypename:this.updateBreed.name,
                        petanimalid:this.updateBreed.belong,
                        isenable:this.updateBreed.state,
                        memo:this.updateBreed.memo
                    },()=>{
                        let obj=this.petTypeList.find(v=>v.PetAnimalID==this.updateBreed.belong);
                        breed.PetTypeName=this.updateBreed.name;
                        breed.PetAnimalID=this.updateBreed.belong;
                        breed.Isenable=this.updateBreed.state;
                        breed.Memo=this.updateBreed.memo;
                        breed.PetAnimalName=obj?obj.PetAnimalName:"";
                        this.showLayer({str:"保存成功！"});
                    })
                    break;
                case OPERATE_TYPE.delete:
                    this.showLayer({
                        type:LAYER_TYPE.confirm,
                        str:"是否确认删除宠物品种【"+breed.PetTypeName+"】",
                        btn:["确认","取消"],
                        yesFn:(i)=>{
                            layer.close(i);
                            this.$root.requestData(DATA_TYPE.delpettype,{
                                pettypeid:breed.PetTypeID
                            },()=>{
                                this.petBreedList.splice(this.petBreedList.findIndex(v=>v.PetTypeID==breed.PetTypeID),1);
                            })
                        }
                    })
                    break;
                default:
                    throw new Error("数据类型应为OPERATE_TYPE类型的值");
            }
        },
        /**
         * 选中宠物品种
         */
        breedClick(breed){
            if(breed.PetTypeID!=this.currChooseBreed.PetTypeID){
                this.currChooseBreed=breed;
                this.updateBreed.name=breed.PetTypeName;
                this.updateBreed.belong=breed.PetAnimalID;
                this.updateBreed.state=breed.Isenable;
                this.updateBreed.memo=breed.Memo;
            }
        },
        /**
         * 选中宠物种类
         */
        typeClick(type){
            if(type.PetAnimalID!=this.currChooseType.PetAnimalID){
                this.currChooseType=type;
                this.updateType.name=type.PetAnimalName;
                this.updateType.state=type.Isenable;
                this.updateType.memo=type.Memo;
                this.currChooseBreedList=this.petBreedList.filter(v=>v.PetAnimalID==this.currChooseType.PetAnimalID);
            }
        },
        /**
         * 选中品种配置中的宠物品种
         */
        breedCheckClick(breed){
            if(this.currChooseType.PetAnimalID||this.isShowAddPetType){//有选中宠物种类或者新增种类时，点击才生效
                let index=this.currChooseBreedList.findIndex(v=>v.PetTypeID==breed.PetTypeID);//获取点击项的下标
                if(index!=-1){//选中的列表中已有当前点击的品种
                    this.currChooseBreedList.splice(index,1);//移除
                }else{
                    this.currChooseBreedList.push(breed);//加入
                }
            }else{
                this.showLayer({str:"请选中一个宠物种类进行配置"});
            }
        }
    },
}
</script>