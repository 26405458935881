<!-- 用户信息页 -->
<style scoped>
    .bi-gender-male::before,.bi-gender-female::before,.bi-chevron-left::before{
        font-weight: bold !important;
    }
    .bg-mosaic{
        background-position: 0 0,.5rem .5rem;
        background-size: 1rem 1rem;
        background-image: 
            linear-gradient(45deg,#eee 25%,transparent 0, transparent 75%,#eee 0,#eee),
            linear-gradient(45deg,#eee 25%,transparent 0,transparent 75%,#eee 0,#eee)
    }
    .letter-spacing-2{
        letter-spacing: .5rem;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>用户信息</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="h-100">

                    <!-- 用户信息填写 -->
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="userId" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>账号
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('userId')}" class="form-control" id="userId" v-model.trim="editUser.userId" placeholder="账号">
                                <div class="invalid-tooltip">{{ userDataValid("userId") }}</div>
                            </div>
                            <div v-show="editUser.userId==user.name||editModel==operate_type.insert" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                <label for="pwd" class="form-label">
                                    <span class="text-danger fw-bold me-1">*</span>密码
                                    <span class="text-danger small">（必填）</span>
                                </label>
                                <input :class="{'is-invalid':userDataValid('pwd')}" class="form-control" type="password" id="pwd" v-model.trim="editUser.pwd" placeholder="密码">
                                <div class="invalid-tooltip">{{ userDataValid("pwd") }}</div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="userName" class="form-label">真实姓名</label>
                                <input class="form-control" id="userName" v-model.trim="editUser.userName" placeholder="真实姓名">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="phone" class="form-label">联系电话</label>
                                <input class="form-control" id="phone" v-model.trim="editUser.phone" placeholder="联系电话">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="sexid" class="form-label">性别</label>
                                <select class="form-select" name="sexid" id="sexid" v-model="editUser.sexid">
                                    <option selected value="">请选择</option>
                                    <option selected value="1">男</option>
                                    <option value="0">女</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="isuser" class="form-label">是否员工</label>
                                <select class="form-select" id="isuser" name="isuser" v-model="editUser.isuser">
                                    <option :value="is_worker.true">是</option>
                                    <option :value="is_worker.false">否</option>
                                </select>
                            </div>
                            <div v-show="editUser.isuser==1" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="roleid" class="form-label">角色</label>
                                <select class="form-select" name="roleid" id="roleid" v-model="editUser.roleid">
                                    <option selected value="0">请选择</option>
                                    <option v-for="item in rolesList" :key="item.RoleID" :value=item.RoleID>{{ item.RoleName }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="birthday" class="form-label">生日</label>
                                <input class="form-control" type="datetime-local" id="birthday" v-model="editUser.birthday"> 
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="email" class="form-label">电子邮件</label>
                                <input class="form-control" id="email" placeholder="电子邮件" v-model.trim="editUser.email">
                            </div>
                            
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="hospitalid" class="form-label">分院</label>
                                <select class="form-select" name="hospitalid" id="hospitalid" v-model="editUser.hospitalid">
                                    <option selected value="0">请选择</option>
                                    <option v-for="i in hospitalList" :key="i.HospitalID" :value=i.HospitalID>{{ i.HospitalName }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="departid" class="form-label">科室</label>
                                <select class="form-select" name="departid" id="departid" v-model="editUser.departid">
                                    <option selected value="0">请选择</option>
                                    <option v-for="i in departList" :key="i.DepartID" :value=i.DepartID>{{ i.DepartName }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="positionid" class="form-label">职位</label>
                                <select class="form-select" name="positionid" id="positionid" v-model="editUser.positionid">
                                    <option selected value="0">请选择</option>
                                    <option v-for="i in positionList" :key="i.PositionID" :value=i.PositionID>{{ i.PositionName }}</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="address" class="form-label">状态</label>
                                <select class="form-select" id="status" name="statuslist" v-model="editUser.status">
                                    <option :value="state_type.on">启用</option>
                                    <option :value="state_type.off">禁用</option>
                                </select>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">账户余额</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.balance">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">VIP等级</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.vipLevel">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">消费总额</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.useAmount">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">创建人</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.creater">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">注册日期</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.createTime">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label class="form-label">最后登录日期</label>
                                <input type="text" class="form-control" disabled :placeholder="editUser.lastTime">
                            </div>
                            <div class="col-lg-6 col-xl-6 mb-2">
                                <label for="address" class="form-label">联系地址</label>
                                <input class="form-control" id="address" placeholder="联系地址" v-model.trim="editUser.address">
                            </div>
                            <div class="col-12">
                                <label class="form-label">附加权限</label>
                                <span class="ms-2 link-primary small" role="button" @click="isEditPower=!isEditPower">{{ isEditPower?'取消':'附加' }}</span>
                                <div class="d-flex flex-wrap border rounded px-2 pt-1 pb-2">
                                    <div v-for="power of isEditPower?powerList:getPowerListByUser" :key="power" class="d-flex flex-wrap small">
                                        <span :class="[!isEditPower?'bg-secondary':(getPowerListByUser.find(v=>v.ID==power.ID)?'btn-primary':'')]" @click="powerClick(power.ID)" :role="isEditPower?'button':''" class="border rounded px-2 me-1 mt-1 bg-opacity-25">{{ power.PowerName }}</span>
                                    </div>
                                    <div class="small mt-1" v-if="getPowerListByUser.length==0&&!isEditPower">未设置</div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- 宠物信息填写 -->
                    <div class="row">

                        <div v-for="(item,i) of petList" :key="item" class="col-12 mb-2">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex flex-column flex-lg-row">
                                        <div class="flex-grow-1">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-2 pe-md-0 d-flex flex-column justify-content-center">
                                                        <!-- <img class="img-thumbnail" src="@/assets/images/upload/dog1.jpg" alt=""> -->
                                                        <div class="d-flex justify-content-center align-items-center fs-5 fw-bold img-thumbnail bg-content bg-mosaic flex-grow-1 text-secondary text-opacity-50 text-vertical letter-spacing-2" role="button">上传宠物萌照</div>
                                                    </div>
                                                    <div class="col-12 col-md-10">
                                                        <div class="row h-100 align-content-around">
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label class="form-label">宠物编号</label>
                                                                <label class="form-control-sm form-control-plaintext">{{item.PetID||"无"}}</label>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                                                <label :for="'petName'+i" class="form-label">
                                                                    <span class="text-danger fw-bold me-1">*</span>宠物昵称
                                                                    <span class="text-danger small">（必填）</span>
                                                                </label>
                                                                <input :class="{'is-invalid':petDataValid(i,'PetName')}" class="form-control form-control-sm" :id="'petName'+i" v-model.trim="item.PetName" placeholder="宠物昵称">
                                                                <div class="invalid-tooltip">{{ petDataValid(i,"PetName") }}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label class="form-label">宠物性别</label>
                                                                <div class="form-control form-control-sm form-control-plaintext pb-0">
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio" class="form-check-input" :id="'petSexFemale'+i" name="petSex" :value="pet_sex_type.female" v-model="item.Sex">
                                                                        <label class="form-check-label" :for="'petSexFemale'+i">
                                                                            <i class="bi bi-gender-male text-primary"></i>
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio" class="form-check-input" :id="'petSexMale'+i" name="petSex" :value="pet_sex_type.male" v-model="item.Sex">
                                                                        <label class="form-check-label" :for="'petSexMale'+i">
                                                                            <i class="bi bi-gender-female text-danger"></i>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label class="form-label">生育功能</label>
                                                                <div class="form-control form-control-sm form-control-plaintext pb-0">
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio" class="form-check-input" :id="'petInfertility'+i" name="sterilization" :value="bear_type.infertility" v-model="item.Sterilization">
                                                                        <label class="form-check-label" :for="'petInfertility'+i">已绝育</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input type="radio" class="form-check-input" :id="'petFertility'+i" name="sterilization" :value="bear_type.fertility" v-model="item.Sterilization">
                                                                        <label class="form-check-label" :for="'petFertility'+i">未绝育</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                                                <label :for="'petTypeId'+i" class="form-label">
                                                                    <span class="text-danger fw-bold me-1">*</span>宠物种类
                                                                    <span class="text-danger small">（必选）</span>
                                                                </label>
                                                                <select :class="{'is-invalid':petDataValid(i,'PetAnimalID')}" @change="item.PetTypeID=''" class="form-select form-select-sm" :id="'petTypeId'+i" v-model="item.PetAnimalID">
                                                                    <option value="">请选择</option>
                                                                    <option v-for="petType in petTypeList" :key="petType" :value="petType.PetAnimalID">{{ petType.PetAnimalName }}</option>
                                                                </select>
                                                                <div class="invalid-tooltip">{{ petDataValid(i,"PetAnimalID") }}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2 position-relative">
                                                                <label :for="'petBreedID'+i" class="form-label">
                                                                    <span class="text-danger fw-bold me-1">*</span>宠物品种
                                                                    <span class="text-danger small">（必选）</span>
                                                                </label>
                                                                <select :class="{'is-invalid':petDataValid(i,'PetTypeID')}" class="form-select form-select-sm" :id="'petBreedID'+i" v-model="item.PetTypeID">
                                                                    <option value="">请选择{{ item.PetTypeID }}</option>
                                                                    <option v-for="petBreed in getPetBreedByType(item)" :key="petBreed" :value="petBreed.PetTypeID">{{petBreed.PetTypeName}}</option>
                                                                </select>
                                                                <div class="invalid-tooltip">{{ petDataValid(i,"PetTypeID") }}</div>
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label :for="'petColor'+i" class="form-label">宠物颜色</label>
                                                                <input class="form-control form-control-sm" :id="'petColor'+i" v-model="item.Color" placeholder="宠物颜色">
                                                            </div>
                                                            <!-- <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label for="petAge" class="form-label">宠物年龄</label>
                                                                <input class="form-control form-control-sm" id="petAge" placeholder="宠物年龄">
                                                            </div> -->
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                                                <label :for="'petWeight'+i" class="form-label">宠物体重</label>
                                                                <input class="form-control form-control-sm" :id="'petWeight'+i" v-model.trim="item.Weight" placeholder="宠物体重">
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-6 col-xl-6">
                                                                <label :for="'petRemark'+i" class="form-label">备注</label>
                                                                <input class="form-control form-control-sm" :id="'petRemark'+i" v-model.trim="item.Memo" placeholder="备注">
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                                <label class="form-label">创建人</label>
                                                                <input type="text" class="form-control form-control-sm" disabled :placeholder="item.Creater">
                                                            </div>
                                                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                                                <label class="form-label">创建日期</label>
                                                                <input type="text" class="form-control form-control-sm" disabled :placeholder="item.CreateTime">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="d-flex flex-row justify-content-end justify-content-lg-around ps-md-3 text-end">
                                            <button type="button" :class="[item.PetID?'btn-danger':'btn-warning']" class="btn btn-sm ms-2 ms-lg-0 d-flex flex-column align-items-center justify-content-center" @click="delPet(item,i)">
                                                <i :class="[item.PetID?'bi-trash':'bi-x-circle-fill']" class="bi"></i>{{ item.PetID?"删除":"取消" }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- 添加宠物资料 -->
                    <div class="pb-3">
                        <div class="text-secondary btn btn-lg btn-light d-block fs-5" @click="addPetClick">
                            <i class="bi bi-plus-lg me-2"></i>
                            <span>添加宠物资料</span>
                        </div>
                    </div>

                </div>
            </div>

            <!-- 按钮栏 -->
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>

        </div>
    </section>
</template>
<script>
import { LAYER_TYPE,RESULT_TYPE,IS_DEBUG,DATA_TYPE,APP_DATA_TYPE,OPERATE_TYPE,PET_SEX_TYPE,BEAR_TYPE,STATE_TYPE,IS_WORKER } from "@/assets/js/config";
import {getDate} from "@/assets/js/common.js";

export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        }
    },
    data(){
        return {
            operate_type:OPERATE_TYPE,//获取操作类型配置
            pet_sex_type:PET_SEX_TYPE,//获取宠物性别配置
            bear_type:BEAR_TYPE,//获取宠物绝育类型配置
            state_type:STATE_TYPE,//获取状态配置
            is_worker:IS_WORKER,//获取是否员工配置
            isVerifyEmpty:false,//是否进行非空验证
            rolesList:[],//角色列表
            departList:[],//科室列表
            positionList:[],//职位列表
            hospitalList:[],//分院列表
            editUser:{//当前正在编辑的用户对象
                userId:"",
                userName:"",
                pwd:"",
                isuser:1,
                sexid:"",
                roleid:0,
                phone:"",
                birthday:"",
                email:"",
                address:"",
                hospitalid:0,//分院ID
                departid:0,//科室ID
                positionid:0,//职位ID
                status:1,
                creater:"",
                lastTime:"",//最后登录时间
                useAmount:"",//消费总额
                vipLevel:0,//vip等级
                createTime:getDate(),//注册日期
                balance:0,//账户余额
                powerString:"",//附加权限
            },
            pet(){//宠物表字段
                return{
                    PetID:"",
                    PetName:"",
                    PetTypeID:"",
                    PetAnimalID:"",
                    UserID:"",
                    Sex:0,
                    Sterilization:0,
                    Weight:0,
                    Color:"",
                    Character:"",
                    Memo:"",
                    CreateTime:"",
                    Creater:""
                }
            },
            petList:[],//宠物列表
            petTypeList:[],//宠物种类列表
            petBreedList:[],//宠物品种列表
            editModel:OPERATE_TYPE.insert,//当前编辑模式，默认为新增

            powerList:[],//权限列表
            isEditPower:false,//是否正处于附加权限中
        }
    },
    computed: {
        /**
         * 获取当前被编辑的用户的权限字符串对应的权限列表
         */
        getPowerListByUser() {
            return this.powerList.filter(v=>this.editUser.powerString.split(',').find(w=>w==v.ID));
        },
    
    },
    created() {
        let userId=this.$route.params.userId;
        this.editUser.creater=this.user.name;
        if(userId){//修改模式
            this.editUser.userId=userId;
            this.editModel=OPERATE_TYPE.update;
            this.getUsers(userId);
        }
    },
    mounted(){
        this.getRole();
        this.getDepart();
        this.getHospital();
        this.getPosition();
        this.editUser.userId&&this.getPetsByUser();
        this.getPetAnimal();
        this.getPetBreed();
        // 获取权限列表
        this.$root.requestData(DATA_TYPE.getpowers,null,(data)=>{
            this.powerList=data.list;
        })
    },
    methods:{
        /**
         * 用户数据有效性验证
         */
        userDataValid(dataName){
            let data=this.editUser[dataName];//获取要校验的值
            switch (dataName) {
                case "userId"://账号校验
                    if(!data){
                        return "账号为必填项！";
                    }
                    break;
                case "pwd"://密码校验
                    if((this.editUser.userId==this.user.name||this.editModel==OPERATE_TYPE.insert)&&!data){//开户或者修改自己的信息
                        return "密码为必填项！";
                    }
                    break;
                default:
                    break;
            }
            return null;
        },
        /**
         * 宠物数据有效性验证
         */
        petDataValid(i,dataName){
            let data=this.petList[i][dataName];//获取要校验的值
            switch (dataName) {
                case "PetName"://宠物昵称校验
                    if(!data){
                        return "宠物昵称为必填项！";
                    }
                    break;
                case "PetAnimalID"://宠物种类校验
                    if(!data){
                        return "宠物种类为必选项！";
                    }
                    break;
                case "PetTypeID":
                    if(!data){
                        return "宠物品种为必选项！";
                    }
                    break;
                default:
                    break;
            }
            return null;
        },
        /**
         * 保存用户和宠物信息
         */
        save(){

            //验证未通过
            if(this.userDataValid("userId")||this.userDataValid("pwd")){
                return;
            }
            for (let i = 0; i < this.petList.length; i++) {
                if(this.petDataValid(i,"PetName")||this.petDataValid(i,"PetAnimalID")||this.petDataValid(i,"PetTypeID")){
                    return;
                }
            }

            //提交
            this.$root.requestData(this.editModel==OPERATE_TYPE.update?DATA_TYPE.upduser:DATA_TYPE.adduser,{
                userid:this.editUser.userId,
                pwd:this.editUser.pwd,
                roleid:this.editUser.isuser==IS_WORKER.true?this.editUser.roleid:0,//角色ID（非员工则角色id为0）
                isuser:this.editUser.isuser,
                username:this.editUser.userName,
                sex:this.editUser.sexid,
                birthday:this.editUser.birthday.replace('T',' '),
                phone:this.editUser.phone,
                address:this.editUser.address,
                email:this.editUser.email,
                begintime:getDate(),////进入本院日期
                hospitalid:this.editUser.hospitalid,//分院ID
                departid:this.editUser.departid,//科室ID
                positionid:this.editUser.positionid,//职位ID
                powerstring:this.editUser.powerString,//权限字符串
            },async (data)=>{
                await new Promise((resolve)=>{
                    //不需要保存宠物
                    if(this.petList.length==0){
                        resolve();
                    }
                    //保存宠物
                    for (let i = 0; i < this.petList.length; i++) {
                        this.petList[i].UserID=this.editUser.userId;
                        this.$root.requestData(this.petList[i].PetID?DATA_TYPE.updpets:DATA_TYPE.addpets,this.petList[i],(data)=>{
                            i==this.petList.length-1&&resolve();
                        })
                    }
                })
                this.$root.linkTo("userlist",{result:true});
            })
        },
        /**
         * 获取角色
         */
        getRole(){
            this.$root.axiosRequest({
                method:'post',
                url:'handler_role.ashx',
                params:{
                    fun:"getroles",
                    guid:this.user.guid,
                    encrystr:"",//加密参数
                }
            },(data)=>{
                this.rolesList=data.list;
            })
        },
        /**
         * 获取科室
         */
        getDepart(){
            this.$root.axiosRequest({
                method:'post',
                url:'handler_depart.ashx',
                params:{
                    fun:"getdepart",
                    guid:this.user.guid,
                    pageindex:"1",
                    pagesize:"999"
                }
            },(data)=>{
                this.departList=data.list;
            })
        },
        /**
         * 获取职位
         */
        getPosition(){
            this.$root.axiosRequest({
                method:'post',
                url:'handler_position.ashx',
                params:{
                    fun:"getposition",
                    guid:this.user.guid,
                    pageindex:"1",
                    pagesize:"999"
                }
            },(data)=>{
                this.positionList=data.list;
            })
        },
        /**
         * 获取分院
         */
        getHospital(){
            this.$root.axiosRequest({
                method:'post',
                url:'handler_hospital.ashx',
                params:{
                    fun:"gethospital",
                    guid:this.user.guid,
                    pageindex:"1",
                    pagesize:"999"
                }
            },(data)=>{
                this.hospitalList=data.list;
            })
        },
        /**
         * 获取指定用户信息（点击用户ID进入页面进行编辑修改，赋值该账号信息）
         */
        getUsers(itemId){
            this.$root.requestData(DATA_TYPE.getusersbyuserid,{
                userid:itemId
            },(data)=>{
                this.editUser.userId=data.list[0].UserID;
                this.editUser.pwd=data.list[0].Pwd;
                this.editUser.roleid=data.list[0].RoleID;
                this.editUser.userName=data.list[0].UserName;
                this.editUser.sexid=data.list[0].Sex;
                this.editUser.birthday=data.list[0].Birthday;
                this.editUser.phone=data.list[0].Phone;
                this.editUser.address=data.list[0].Address;
                this.editUser.email=data.list[0].Email;
                this.editUser.hospitalid=data.list[0].HospitalID;
                this.editUser.departid=data.list[0].DepartID; 
                this.editUser.positionid=data.list[0].PositionID;
                this.editUser.begintime=data.list[0].BeginTime;
                this.editUser.status=data.list[0].Status;
                this.editUser.creater=data.list[0].Creater;
                this.editUser.isuser=data.list[0].IsUser;
                this.editUser.lastTime=data.list[0].LastTime.replace(/\//g,'-');
                this.editUser.useAmount=data.list[0].UseAmount;
                this.editUser.vipLevel=data.list[0].VipLevel;
                this.editUser.createTime=data.list[0].CreateTime.replace(/\//g,'-');
                this.editUser.balance=data.list[0].Balance;
                this.editUser.powerString=data.list[0].Powerstring;
            })
        },
        /**
         * 根据当前查看的用户信息获取宠物列表
         */
        getPetsByUser(){
            this.$root.requestData(DATA_TYPE.getpets,{
                pageindex:1,
                pagesize:99,
                userid:this.editUser.userId?this.editUser.userId:0
            },(data)=>{
                this.petList=data.list;
            })
        },
        /**
         * 点击添加宠物资料
         */
        addPetClick(){
            let pet =this.pet();
            pet.Creater=this.user.name;
            pet.CreateTime=getDate();
            this.petList.push(pet);
        },
        /**
         * 获取宠物种类
         */
        getPetAnimal(){
            // 获取宠物种类列表
            this.$root.requestData(DATA_TYPE.getpetanimal,{
                pageindex:1,
                pagesize:99999
            },(data)=>{
                this.petTypeList=data.list;
            })
        },
        /**
         * 获取宠物品种
         */
        getPetBreed(){
            // 获取宠物品种列表
            this.$root.requestData(DATA_TYPE.getpettype,{
                pageindex:1,
                pagesize:99999
            },(data)=>{
                this.petBreedList=data.list;
            })
        },
        /**
         * 删除宠物
         */
        delPet(pet,i){
            if(pet.PetID){//已存档的宠物
                this.showLayer({
                    type:LAYER_TYPE.confirm,
                    str:"是否确认删除宠物【"+pet.PetName+"】？",
                    btn:["确认","取消"],
                    yesFn:(i)=>{
                        layer.close(i);
                        this.$root.requestData(DATA_TYPE.delpets,{
                            petid:pet.PetID
                        },(data)=>{
                            this.petList.forEach((item,i)=>{
                                item.PetID==pet.PetID&&this.petList.splice(i,1);
                            })
                        })
                    }
                })
            }else{//未存档的宠物
                this.petList.splice(i,1);
            }
        },
        /**
         * 根据宠物种类获取对应品种
         */
        getPetBreedByType(pet) {
            // pet.PetTypeID="";//将选中重置为：请选择
            return this.petBreedList.filter(v=>v.PetAnimalID==pet.PetAnimalID);
        },
        /**
         * 点击权限
         */
        powerClick(powerId){
            let powerArr=this.editUser.powerString.split(',');//获取当前被编辑的用户的附加权限
            if(!powerArr.find(v=>v==powerId)){//附加权限不存在
                this.editUser.powerString+=(this.editUser.powerString==""?powerId:(","+powerId));
            }else{
                //移除附加权限
                powerArr.splice(powerArr.findIndex(v=>v==powerId),1);
                this.editUser.powerString=String(powerArr);
            }
        }
    }
}
</script>