import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//使用bootstrap框架，安装：npm install bootstrap，官网：https://getbootstrap.com/ https://www.bootcss.com/
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
//使用弹出插件，安装：npm i layer-src，官网：http://layui-doc.pearadmin.com/layer/mobile/
import 'layer-src/dist/mobile/layer';
import "layer-src/dist/mobile/need/layer.css";
//使用封装的弹出插件
import showLayer from '@/assets/js/showLayer'
//使用axios，安装：npm install --save axios vue-axios，官网：http://www.axios-js.com/zh-cn/docs/vue-axios.html
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.headers.post['Content-Type']="application/json";
axios.defaults.baseURL="http://106.75.216.57/handlers/";
//axios.defaults.baseURL="http://192.168.1.119:1000/handlers/";
axios.interceptors.response.use((res)=>{
    if(res.data.d){
        try {
            return JSON.parse(res.data.d)
        } catch (error) {
            return res.data.d;
        }
    }
    return res.data;
})
const app=createApp(App);
app.use(store).use(router).use(showLayer).use(VueAxios, axios).mount('#app')
