<!-- 预约信息页 -->
<style scoped>
    .bi-chevron-left::before{
        font-weight: bold !important;
    }
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">
                <i class="bi bi-chevron-left me-1" @click="$router.back" role="button"></i>
                <span>预约信息</span>
            </div>
            <div class="card-body h-0 overflow-scroll">
                <div class="h-100">
                    <form>
                        <div class="row pb-2">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-2">
                                <label for="user" class="form-label">预约用户</label>
                                <small class="ms-2 link-primary" role="button" data-bs-toggle="modal" data-bs-target="#diaoUserList" @click="diaoUserList">选择</small>
                                <input class="form-control" id="user" disabled placeholder="" :value="appointUser&&(appointUser.UserID)" >
                            </div>
                            <div class="col-12 mb-2">
                                <label for="user" class="form-label">宠物选择</label>
                                <div class="d-flex flex-wrap border rounded px-2 pb-2 pt-1">
                                    <div v-if="petList.length>0" class="d-flex">
                                        <div v-for="item of petList" :key="item" @click="pet=item" :class="{'btn-primary':pet&&item.PetID==pet.PetID}" class="me-1 mt-1 border rounded px-2" role="button">{{ item.PetName }}</div>
                                    </div>
                                    <div v-else class="small mt-1">暂无</div>
                                </div>
                            </div>
                            <div class="col-12 mb-2">
                                <label for="user" class="form-label">预约时段</label>
                                <div class="border rounded p-2">
                                    <label for="" class="small fw-bold">日期</label>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="col-auto">
                                            <input type="date" class="form-control" :min="formatDate().split(' ')[0]" v-model="appointDate" placeholder="请选择日期">
                                        </div>
                                    </div>
                                    <label for="" class="small fw-bold">时间</label>
                                    <div class="d-flex flex-wrap">
                                        <div v-for="item of timesliceList" :key="item" @click="isTimeAppoint(item['ParamText'])&&(appointTime=item['ParamText'])" :class="{'bg-secondary bg-opacity-25 disabled':!isTimeAppoint(item['ParamText']),'btn-primary':appointTime==item['ParamText']}" class="d-flex border rounded me-2 mb-2 p-2 btn">
                                            <div class="d-flex flex-column small justify-content-between">
                                                <span class="pb-1 d-flex">
                                                    <i class="bi bi-clock me-1"></i>
                                                    <span>{{item['ParamText']}}</span>
                                                </span>
                                                <span class="d-flex">
                                                    <i class="bi bi-people me-1"></i>
                                                    <span>{{item['ParamString']}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="form-label">预约项目</label>
                                <div class="d-flex flex-wrap border rounded px-2 pt-1 pb-2">
                                    <div class="d-flex flex-wrap small" v-if="bookableServiceList.length>0">
                                        <div v-for="service of bookableServiceList" :key="service">
                                            <!-- 有两项及以上的价格标准 -->
                                            <div v-if="getPriceListByService(service).length>1" class="dropdown">
                                                <div class="border rounded px-2 me-1 mt-1 dropdown-toggle dropdown " :class="{'btn-primary':appointServiceList.find(v=>v.ProductID==service.ProductID)}" role="button" data-bs-auto-close="true" aria-expanded="false" data-bs-toggle="dropdown">
                                                    <span class="me-1">{{ service.ProductName }}</span>
                                                    <span class="text-light text-opacity-50" v-if="appointPriceList.find(v=>v.ChildIDs==service.ProductID)">{{ "（"+appointPriceList.find(v=>v.ChildIDs==service.ProductID).Price+"元/"+appointPriceList.find(v=>v.ChildIDs==service.ProductID).UnitName+"）" }}</span>
                                                </div>
                                                <form class="dropdown-menu px-2 py-1">
                                                    <div class="form-check py-0 form-control-sm" v-for="item of getPriceListByService(service)" :key="item">
                                                        <input class="form-check-input" type="radio" :name="'service'+service.ProductID" :checked="appointPriceList.find(v=>v.ProductID==item.ProductID)" @change="serviceClick(service,item)" :id="'price'+item.ProductID" :value="item.ProductID">
                                                        <label class="form-check-label" :for="'price'+item.ProductID">{{item.Price}}元/{{ item.UnitName }}</label>
                                                    </div>
                                                    <div v-show="appointPriceList.find(v=>v.ChildIDs==service.ProductID)" class="text-center">
                                                        <span class="btn btn-sm btn-danger mx-auto py-0" @click="serviceClick(service,appointPriceList.find(v=>v.ChildIDs==service.ProductID))">移除</span>
                                                    </div>
                                                </form>
                                            </div>
                                            <!-- 只有一项及一项以下的价格标准 -->
                                            <div v-else :class="{'btn-primary':appointServiceList.find(v=>v.ProductID==service.ProductID)}" class="border rounded px-2 me-1 mt-1" @click="serviceClick(service,getPriceListByService(service)[0])" role="button">
                                                <span>{{ service.ProductName }}</span>
                                                <span :class="[appointServiceList.find(v=>v.ProductID==service.ProductID)?'text-light text-opacity-50':'text-secondary']" v-if="getPriceListByService(service).length==1">{{ "（"+getPriceListByService(service)[0].Price+"元/"+getPriceListByService(service)[0].UnitName+"）" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="small mt-1">未设置</div>
                                </div>
                            </div>

                        </div>
                        <div id="diaoUserList" class="modal">
                            <div class="modal-dialog modal-lg h-75">
                                <div class="modal-content h-100">
                                    <div class="modal-body">
                                        <userlist :user="user" :pagesize="20" :isChoose=true @set-user="setUser" id="userlist"></userlist>
                                    </div>
                                    <div class="modal-footer d-none" >
                                        <button type="button" id="modalClose" class="btn btn-danger" data-bs-dismiss="modal">关闭</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                
            </div>
            <div class="card-footer text-center">
                <button type="button" class="btn btn-success me-3" @click="save">
                    <i class="bi bi-save"></i> 保存
                </button>
                <button type="button" class="btn btn-outline-secondary" @click="$router.back">
                    <i class="bi bi-box-arrow-in-left"></i> 返回
                </button>
            </div>
        </div>
    </section>
</template>
<script>
import { DATA_TYPE,PARAMS_KEY,PRODUCT_TYPE } from "@/assets/js/config";
import {formatDate} from "@/assets/js/common.js";
import userlist from "@/components/page/userList.vue";
export default {
    props:{
        user:{
            type:Object,
            default:{
                name:"",
                guid:"",
            }
        },
        pagesize:0,//每页显示数据条数
    },
    components:{
        userlist,
    },
    data(){
        return {
            bookableServiceList: [],//可预约服务列表
            timesliceList:[],//时间片段列表
            priceList:[],//价格标准列表
            appointUser:"",//选择的预约用户
            petList:[],//宠物列表
            pet:null,//选中的宠物对象
            appointListByDate:[],//根据时间段筛选的预约列表
            appointDate:formatDate().split(' ')[0],//预约日期
            appointTime:null,//预约时间
            appointServiceList:[],//预约的服务列表
            appointPriceList:[],//预约的项目价格标准
            appoinitId:"",
            
        }
    },
    created(){
        let appoinitid=this.$route.params.appoinitId;
        if(appoinitid){
            this.appoinitId=appoinitid;
            this.getReserveById(appoinitid);
        }
    },
    async mounted() {
        this.getBookableServiceList();
        this.getPriceList();
        await this.getAppoint(formatDate().split(' ')[0],formatDate().split(' ')[0]);
        this.getTimeslice();
    },
    methods: {
        formatDate,
        setUser(checkedUser){
            this.appointUser=checkedUser;
            //获取该主人的宠物信息
            this.getPetsByUser();
            this.getUserByUser();
            document.getElementById("modalClose").click();
        },
        /**根据预约ID获取该预约订单信息 */
        getReserveById(appoinitId){
            this.$root.requestData(DATA_TYPE.getreservebyid,{
                reserveid:appoinitId
            },(data)=>{
                console.dir(data.list[0])
                this.appointUser={
                    UserID:data.list[0].UserID
                };
                this.petList=[{PetID:data.list[0].PetID,PetName:data.list[0].PetName}]
                this.pet={PetID:data.list[0].PetID,PetName:data.list[0].PetName}
                console.dir(this.petList)
                this.appointDate=data.list[0].ReserveDate;
                //this.appointTime={ParamText:data.list[0].ReserveTime};
                this.appointTime=data.list[0].ReserveTime;
                // this.productName=data.list[0].ProductName;
                // this.brandName=data.list[0].BrandName;
                // this.unitName=data.list[0].UnitName;
                // this.price=data.list[0].Price;
                // this.stock=data.list[0].Stock;
                // this.isenable=data.list[0].Isenable;
                // this.memo=data.list[0].Memo;
            })
        },
        /**
         * 根据选择的用户获取该用户信息
         */
        getUserByUser(){
            this.$root.requestData(DATA_TYPE.getusersbyuserid,{
                userid:this.appointUser
            },(data)=>{
                this.appointUser=data.list[0];
            })
        },
        /**
         * 根据当前查看的用户信息获取宠物列表
         */
        getPetsByUser(){
            this.$root.requestData(DATA_TYPE.getpets,{
                pageindex:1,
                pagesize:this.pagesize,
                userid:this.appointUser
            },(data)=>{
                this.petList=data.list;
            })
        }, 
        /**
         * 获取可预约服务列表
         */
        getBookableServiceList(){
            // 获取服务列表
            this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                //pagesize:999,
                pagesize:this.pagesize,
                serviceid:PRODUCT_TYPE.service.id,
                isreserve:1,//可预约的
            },(data)=>{
                this.bookableServiceList=data.list;
                console.log(this.bookableServiceList)
            })
        },
        /**
         * 获取所有价格标准
         */
        getPriceList(){
            return this.$root.requestData(DATA_TYPE.getproduct,{
                pageindex:1,
                //pagesize:99999,
                pagesize:this.pagesize,
                serviceid:PRODUCT_TYPE.price.id
            },(data)=>{
                this.priceList=data.list;
            })
        },
        /**
         * 根据服务获取对应的价格标准
         */
        getPriceListByService(service){
            return this.priceList.filter(v=>v.ChildIDs==service.ProductID);
        },
        /**
         * 获取预约时段设置
         */
        getTimeslice(){
            this.$root.requestData(DATA_TYPE.getparams,{
                pageindex:1,
                //pagesize:24,
                pagesize:this.pagesize,
                paramkey:PARAMS_KEY.timeslice
            },(data)=>{
                this.timesliceList=data.list;
            })
        },
        /**
         * 保存
         */
        save(){
            if(!this.appointUser){
                this.showLayer({str:"请选择用户！"});
                return;
            }
            if(!this.pet){
                this.showLayer({str:"请选择宠物！"});
                return;
            }
            if(!this.appointTime){
                this.showLayer({str:"请选择预约时间！"});
                return;
            }
            if(this.appointServiceList.length==0){
                this.showLayer({str:"请选择预约项目！"});
                return;
            }
            if(!this.isTimeAppoint(this.appointTime)){
                this.showLayer({str:"当前时间段已不可预约，请刷新重试！"});
                return;
            }
            let reservecode=new Date().getTime();//生成预约号
            let serviceId=0, productIdList=[], productNameList=[], unitPriceList=[];

            for (let i = 0; i < this.appointServiceList.length; i++) {
                let price=this.appointPriceList.find(v=>v.ChildIDs==this.appointServiceList[i].ProductID);
                serviceId=this.appointServiceList[i].ServiceID,
                productIdList.push(this.appointServiceList[i].ProductID),
                productNameList.push(this.appointServiceList[i].ProductName),
                unitPriceList.push(price?price.Price:0)
            }
            this.$root.requestData(DATA_TYPE.addreserve,{
                reservecode:reservecode,
                userid:this.appointUser.UserID, 
                phone:this.appointUser.Phone, 
                address:this.appointUser.Address, 	
                email:this.appointUser.Email, 
                petid:this.pet.PetID, 
                petname:this.pet.PetName, 
                hospitalid:0, 
                departid:0, 
                serviceid:0, 
                servicename:"", 
                productid:0, 	
                productname:String(productNameList), 
                unitprice:0,
                reservedate:this.appointDate, 
                reservetime:this.appointTime,
                memo:""
            },(data)=>{
                for (let i = 0; i < this.appointServiceList.length; i++) {
                    let price=this.appointPriceList.find(v=>v.ChildIDs==this.appointServiceList[i].ProductID);
                    console.log({
                        reservecode:reservecode,
                        productid:this.appointServiceList[i].ProductID,
                        productname:this.appointServiceList[i].ProductName,
                        unitprice:price?price.Price:0,
                        totalprice:price?price.Price:0,
                        memo:""
                    })
                    this.$root.requestData(DATA_TYPE.addorders,{
                        reservecode:reservecode,
                        productid:this.appointServiceList[i].ProductID,
                        productname:this.appointServiceList[i].ProductName,
                        unitprice:price?price.Price:0,
                        quantity:1,
                        totalprice:price?price.Price:0,
                        memo:""
                    })
                }
            })
        },
        /**
         * 根据时间段获取预约列表
         */
        getAppoint(startDate,endDate) {
            // 获取预约列表
            this.$root.requestData(DATA_TYPE.getreserve,{
                pageindex:1,
                pagesize:this.pagesize,
                starttime:startDate,
                endtime:endDate
            },(data)=>{
                this.appointListByDate=data.list;
            })
        },
        /**
         * 时间段是否允许预约
         */
        isTimeAppoint(timeStr){
            let dateStr=this.appointDate+" "+timeStr.split('-')[1];//日期
            if(timeStr.split('-')[1]=="00:00"){//结束时间段为0点时需要将日期加1
                let date=new Date(dateStr);
                dateStr=formatDate(date.setDate(date.getDate()+1));
            }
            if(new Date(dateStr)>new Date()){//日期时间大于当前日期时间
                let arr=this.appointListByDate.filter(v=>v.ReserveDate==this.appointDate&&v.ReserveTime==timeStr);//获取指定时间段的预约数
                if(arr.length<this.timesliceList.find(v=>v.ParamText==timeStr).ParamString){//预约数小于设定的预约数
                    return true;//才能允许被预约
                }
            }
            return false;
        },
        /**
         * 点击预约项目
         */
        serviceClick(service,price){
            let index=this.appointServiceList.findIndex(v=>v.ProductID==service.ProductID);
            if(index==-1){//预约项目未选中
                this.appointServiceList.push(service);//加入预约项目
                price&&this.appointPriceList.push(price);//有价格标准就加入价格标准
            }else{
                let p=this.appointPriceList.find(v=>v.ChildIDs==service.ProductID);//已有的价格标准
                if(price&&p&&price.ProductID!=p.ProductID){//有选中的价格标准且有已有的价格标准且两者不一致
                    this.appointPriceList.splice(this.appointPriceList.findIndex(v=>v.ChildIDs==service.ProductID),1);//移除已有的价格标准
                    this.appointPriceList.push(price);//加入选中的价格标准
                }else{
                    this.appointServiceList.splice(index,1);//移除预约项目
                    p&&this.appointPriceList.splice(this.appointPriceList.findIndex(v=>v.ChildIDs==service.ProductID),1);//有已有的价格标准则移除已有的价格标准
                }
            }
            console.dir(this.appointServiceList)
        }
    },
}
</script>