<!-- 结账缴费页 -->
<style scoped>
    
</style>
<template>
    <section class="h-100 shadow rounded-10 overflow-hidden">
        <div class="card border-0 h-100">
            <div class="card-header">结账缴费</div>
            <div class="card-body">
                <div class="d-flex flex-column h-100">
                    <form>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="reserveCode" class="form-label">预约号</label>
                                <input class="form-control" id="reserveCode" v-model="searchReserveCode" placeholder="预约号">
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
                                <label for="ordersStatus" class="form-label">费用状态</label>
                                <select id="ordersStatus" class="form-select"  v-model="searchOrdersStatus">
                                    <option value="">所有状态</option>
                                    <option :value="orders_state.unpaid">待付款</option>
                                    <option :value="orders_state.paid">已付款</option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="flex-grow-1 h-0 overflow-scroll">
                        <table class="table table-striped table-hover table-borderless table-row-fixed mb-0 align-middle">
                            <thead>
                                <tr class="text-light">
                                    <th class="bg-primary bg-gradient">预约号</th>
                                    <th class="bg-primary bg-gradient">产品名</th>
                                    <th class="bg-primary bg-gradient">价格</th>
                                    <th class="bg-primary bg-gradient">数量</th>
                                    <th class="bg-primary bg-gradient">总费用</th>
                                    <th class="bg-primary bg-gradient">状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border-bottom" v-for="item in ordersList" :key="item">
                                    <td>{{item.ReserveCode}}</td>
                                    <td>{{item.ProductName}}</td>
                                    <td>{{item.UnitPrice}}</td>
                                    <td>{{item.Quantity}}</td>
                                    <td>{{item.TotalPrice}}</td>
                                    <td>
                                        <select class="form-select form-select-sm" :name="'ordersState'+item.OrderID" :value="item.Status" @change="updState($event.target.value,item.OrderID)">
                                            <option :value="orders_state.unpaid">未付款</option>
                                            <option :value="orders_state.paid">已付款</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <pagination></pagination>
            </div>
        </div>
    </section>
</template>
<script>
    import pagination from "@/components/public/pagination.vue";
    import { DATA_TYPE,LAYER_TYPE,STATE_TYPE,IS_WORKER,STATE_NAME,ORDERS_STATE } from "@/assets/js/config";
    export default {
        props:{
            user:{
                type:Object,
                default:{
                    name:"",
                    guid:"",
                }
            },
            pagesize:0,//每页显示数据条数
        },
        data(){
            return{
                //配置获取
                stateType:STATE_TYPE,//状态类型配置
                state_name:STATE_NAME,//状态类型名称配置
                orders_state:ORDERS_STATE,//预约状态
                searchReserveCode:"",//预约号
                searchOrdersStatus:"",//费用状态
                ordersList:[],//订单列表
                isSearching:false,//是否正在搜索
                quantity:0,//订单数量
                totalPrice:0,//订单总价
                memo:"",
            }
        },
        components:{
            pagination
        },
        mounted(){
            if(this.$route.params.result){
                this.showLayer({str:"保存成功！"});
            }
            this.getOrdersList();
        },
        methods:{
            //获取订单列表
            getOrdersList(){
                if(!this.isSearching){
                    this.isSearching=true;
                    let params={//参数
                        guid:this.user.guid,
                        // encrystr:"dd",
                        pageindex:"1",
                        pagesize:this.pagesize,
                        status:ORDERS_STATE.unpaid,
                    }
                    this.searchReserveCode!=""&&(params.reservecode=this.searchReserveCode);
                    String(this.searchOrdersStatus)!=""&&(params.status=this.searchOrdersStatus);
                    
                    this.$root.requestData(DATA_TYPE.getorders,params,(data)=>{
                        this.ordersList=data.list;
                        console.log(this.ordersList)
                        this.isSearching=false;
                    })
                }
            },
            updState(stateval,code){
                this.showLayer({
                    type:LAYER_TYPE.confirm,
                    str:"是否确认修改缴费状态",
                    btn:["确认","取消"],
                    yesFn:(i)=>{
                        layer.close(i);
                        this.$root.requestData(DATA_TYPE.getordersbyid,{
                            guid:this.user.guid,
                            orderid:code,
                        },(data)=>{
                            this.quantity=data.list[0].Quantity,
                            this.totalPrice=data.list[0].TotalPrice,
                            this.memo=data.list[0].Memo,
                            this.$root.requestData(DATA_TYPE.updorders,{
                                guid:this.user.guid,
                                orderid:code,
                                quantity:this.quantity,
                                totalprice:this.totalPrice,
                                status:stateval,
                                memo:this.memo,
                            },(data)=>{
                                this.showLayer({str:"保存成功！"});
                                this.getOrdersList();
                            })
                        })
                        
                    }
                })
            },
        },
        watch: {
            /**
             * 监听搜索条件改变
             */
             searchReserveCode() {
                this.getOrdersList();
            },
            searchOrdersStatus() {
                this.getOrdersList();
            }
        },
    }
</script>